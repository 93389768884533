import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export default function ChartjsRaceEthnicity1({ enroll, lang }) {
    // label and options setting below --------------------------------
    // optionsEnrollment --------------------------------
    const labelsEnrollment = [
        lang === 'en' ? 'African American' : 'Afroamericano',
        lang === 'en' ? 'Hispanic' : 'Hispánico',
        lang === 'en' ? 'White' : 'Blanco',
        lang === 'en' ? 'American Indian' : 'Indio Americano',
        lang === 'en' ? 'Asian' : 'Asiático',
        lang === 'en' ? 'Pacific Islander' : 'Isleño del Pacífico',
        lang === 'en' ? 'Two or More Races' : 'Dos o mas Razas',
    ];

    const optionsEnrollment = {
        layout: {
            padding: {
                right: 40,
            },
        },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        // aspectRatio: 1,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text:
                    lang === 'en'
                        ? 'Student Enrollment by Race/Ethnicity'
                        : 'Inscripción de Estudiantes por Raza/Etnia',
                position: 'top',
                align: 'start',
                font: {
                    size: 20,
                    weight: 'bold',
                },
                color: 'black',
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (val) => `${val}%`,
                labels: {
                    value: {
                        color: '#236FAD',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.parsed.x}%`;
                    },
                },
                enabled: true,
                displayColors: false,
                titleColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white',
            },
        },
    };

    // data for different charts
    // data setting below --------------------------------
    // dataEnrollment  --------------------------------
    const dataEnrollment = {
        labels: labelsEnrollment,
        datasets: [
            {
                data: enroll.length === 0 ? [0, 0, 0, 0, 0, 0, 0] : enroll[0].Enrollment,
                borderColor: 'black',
                backgroundColor: ['#548235', '#56B7E6', '#F16038', '#E7E3DB', '#92C740', '#704280', '#ECAF33'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // data setting above --------------------------------
    // single point data setting--------------------------------
    const dataTotal = enroll.length === 0 ? 0 : enroll[0].Total;
    const dataEco_Dis = enroll.length === 0 ? 0 : enroll[0].Eco_Dis;
    const dataSpec_Ed = enroll.length === 0 ? 0 : enroll[0].Spec_Ed;
    const dataEng_Lrn = enroll.length === 0 ? 0 : enroll[0].Eng_Lrn;

    return (
        <Grid container>
            <Grid container style={{ marginLeft: '10px', padding: '0px 0px 0px 0px' }}>
                {dataTotal !== 0 ? (
                    <Grid item xs={12} sm={12} md={7}>
                        <br />
                        <b style={{ fontSize: '1.3rem' }}>
                            {' '}
                            {lang === 'en' ? 'Total Student Enrollment' : 'Matrícula Total de Estudiantes'}{' '}
                        </b>
                        <br />{' '}
                        <b style={{ fontSize: '2.5rem', color: 'blue', marginLeft: '4rem' }}>
                            {Intl.NumberFormat().format(dataTotal)}
                        </b>
                        <br />
                        <br />
                        <Bar options={optionsEnrollment} data={dataEnrollment} />
                    </Grid>
                ) : (
                    <p style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '600' }}>
                        {lang === 'en' ? 'No Information Available' : 'No hay Información Disponible'}
                    </p>
                )}
                {dataTotal !== 0 ? <Grid item xs={12} sm={12} md={1}></Grid> : null}
                {dataTotal !== 0 ? (
                    <Grid item xs={12} sm={12} md={4}>
                        <br />
                        <b style={{ fontSize: '1.3rem' }}>
                            {' '}
                            {lang === 'en' ? 'Student Enrollment by Type' : 'Inscripción de Estudiantes por Tipo'}
                        </b>
                        <br />
                        <br />

                        <b style={{ fontSize: '1rem' }}>
                            {lang === 'en' ? 'Economically Disadvantaged' : 'Económicamente en Desventaja'}
                        </b>
                        <br />
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '10rem',
                                margin: 'auto',
                            }}
                        >
                            <b
                                style={{
                                    fontSize: '1.9rem',
                                    color: 'black',
                                    padding: '40px 0px 0px 28px',
                                    position: 'absolute',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                            >
                                {dataEco_Dis.toFixed(1)}%
                            </b>
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    color: '#D5DBDB',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                                variant='determinate'
                                value={100}
                                size={130}
                            />
                            <CircularProgress
                                style={{ color: '#51555A' }}
                                variant='determinate'
                                value={dataEco_Dis}
                                size={130}
                            />
                        </div>

                        <b style={{ fontSize: '1rem' }}>
                            {' '}
                            {lang === 'en' ? 'Special Education' : 'Educación Especial'}
                        </b>
                        <br />
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '10rem',
                                margin: 'auto',
                            }}
                        >
                            <b
                                style={{
                                    fontSize: '1.9rem',
                                    color: 'black',
                                    padding: '40px 0px 0px 28px',
                                    position: 'absolute',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                            >
                                {dataSpec_Ed.toFixed(1)}%
                            </b>
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    color: '#D5DBDB',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                                variant='determinate'
                                value={100}
                                size={130}
                            />
                            <CircularProgress
                                style={{ color: '#51555A' }}
                                variant='determinate'
                                value={dataSpec_Ed}
                                size={130}
                            />
                        </div>

                        <b style={{ fontSize: '1rem' }}>
                            {lang === 'en'
                                ? 'Emergent Bilingual/English Learners'
                                : 'Aprendices de Inglés/Bilingües Emergentes'}{' '}
                        </b>
                        <br />
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '10rem',
                                margin: 'auto',
                            }}
                        >
                            <b
                                style={{
                                    fontSize: '1.9rem',
                                    color: 'black',
                                    padding: '40px 0px 0px 30px',
                                    position: 'absolute',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                            >
                                {dataEng_Lrn.toFixed(1)}%
                            </b>
                            <CircularProgress
                                style={{
                                    position: 'absolute',
                                    color: '#D5DBDB',
                                    marginbottom: '1.25vw',
                                    marginleft: '-6.25vh',
                                }}
                                variant='determinate'
                                value={100}
                                size={130}
                            />
                            <CircularProgress
                                style={{ color: '#51555A' }}
                                variant='determinate'
                                value={dataEng_Lrn}
                                size={130}
                            />
                        </div>
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
}

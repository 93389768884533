import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
export default function DtProgressAcademic({ labels, datacol1, datacol2,headerlabel}) {
    const dataDT = labels.map(function (value, index) {
        return {
            id: index,
            component: value,
            score: datacol1[index],
            pct_of_grade: datacol2[index],
        };
    });
    const columnsDT = [
        {
            name: headerlabel[0],
            cell: (row) => row.component,
        },
        {
            name: headerlabel[1],
            selector: (row) => row.score,
        },
        {
            name:  <div style={{ textOverflow: 'unset' }}>{headerlabel[2]}</div>,
            selector: (row) => row.pct_of_grade,
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.component === 'Total',
            style: {
                backgroundColor: '#E7E3DB',
                fontWeight: 'bold',
            },
        },
    ];
    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'center',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
                justifyContent: 'center',
            },
        },
    };
    return (
        <div style={{ margin: '0% 10% 0%' }}>
            <DataTable
                columns={columnsDT}
                data={dataDT}
                conditionalRowStyles={conditionalRowStyles}
                customStyles={customStyles}
                highlightOnHover
            />
        </div>
    );
}

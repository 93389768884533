import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Typography, Button, Link } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '100%',
        height: 'auto',
        backgroundColor: '#0d6cb9',
        flexShrink: 1,
        paddingBottom: '20px',
        paddingTop: '20px',
        color: '#ffffff',
        fontSize: '0.8em',
        marginTop: '1rem',
    },
    content: {
        fontSize: '14px',
        color: '#ffffff',
    },
    seal: {
        marginLeft: '20px',
        marginRight: '20px',
        paddingTop: '8px',
    },
    section: {
        height: 'auto',
        color: '#ffffff',
        backgroundColor: '#0d6cb9',
        justifyContent: 'flex-start',
    },
}));

function Footer({ lng }) {
    const classes = useStyles();

    const [value, setValue] = useState(0);
    function Copyright() {
        return (
            <Typography className={classes.content} variant='body2' align='center'>
                {'Copyright © '}
                2007-{new Date().getFullYear()}{' '}
                {lng === 'en'
                    ? 'Texas Education Agency (TEA). All Rights Reserved.'
                    : 'Agencia de Educación de Texas (TEA). Todos los derechos reservados.'}{' '}
         </Typography>
        );
    }

 function CDATA() {
    const sz = document.createElement('script'); sz.type = 'text/javascript'; sz.async = true;
    sz.src = '//siteimproveanalytics.com/js/siteanalyze_77443.js';
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sz, s);
 }

    return (
        <div className={classes.root}>
            <Grid style={{ padding: 0, maxWidth: '1600px', margin: 'auto' }} container direction='row'>
                <Grid className={classes.seal} item xs={12} md={2}>
                    <img src='./images/tea-seal.png' alt='Texas Education Agency Logo' />
                </Grid>
                <Grid item xs={12} md={9} container direction='row'>
                    <Grid item xs={6} md={3}>
                        <Typography id='first' component='div' className={classes.content}>
                            <List dense={true} color='tertiary' aria-label='block-footermenu2-menu'>
                                <ListItem>
                                    {lng === 'en' ? 'Texas Education Agency' : 'Agencia de educación de Texas'}
                                </ListItem>
                                <ListItem>
                                    {lng === 'en' ? '1701 N. Congress Avenue' : '1701 N. Avenida del Congreso'}
                                </ListItem>
                                <ListItem>Austin, Texas, 78701</ListItem>
                                <ListItem>(512) 463-9734</ListItem>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://www.google.com/maps/place/Texas+Education+Agency/@30.2791716,-97.740167,17z/data=!3m1!4b1!4m5!3m4!1s0x8644b59f00e74c6f:0x888e03ba197a06c3!8m2!3d30.279167!4d-97.7379783'
                                        rel='nofollow'
                                    >
                                        {lng === 'en' ? 'Map' : 'Mapa'}
                                    </Link>
                                    &nbsp;|&nbsp;
                                    <Link color='inherit' href='https://tea.texas.gov/interiorpage.aspx?id=25769818374'>
                                        {lng === 'en' ? 'Site Policies' : 'Políticas del sitio'}
                                    </Link>
                                    &nbsp;|&nbsp;
                                    <Link color='inherit' href='https://tea.texas.gov/sitemap'>
                                        {lng === 'en' ? 'Sitemap' : 'Mapa del sitio'}
                                    </Link>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography className={classes.content} component='div'>
                            <List dense={true} color='tertiary' aria-label='block-footermenu2-menu'>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/WorkArea/linkit.aspx?LinkIdentifier=ID&amp;ItemID=8060'
                                    >
                                        {lng === 'en' ? 'Compact with Texans' : 'Compacto con tejanos'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://tea.texas.gov/About_TEA/Contact_Us/Complaints'>
                                        {lng === 'en' ? 'Complaints' : 'Quejas'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/About_TEA/Other_Services/Education_Service_Centers'
                                    >
                                        ESC's
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/About_TEA/Contact_Us/General_Inquiry/Equal_Educational_Opportunity/'
                                    >
                                        {lng === 'en'
                                            ? 'Equal Educational Opportunity'
                                            : 'Igualdad de oportunidades educativas'}
                                    </Link>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography className={classes.content} component='div'>
                            <List dense={true} color='tertiary' aria-label='block-footermenu2-menu'>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/About_TEA/Contact_Us/Reporting_Fraud,_Waste_or_Abuse_in_Texas'
                                    >
                                        {lng === 'en' ? 'Fraud Hotline' : 'Línea directa de fraude'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/About_TEA/Agencywide_Frequently_Asked_Questions/'
                                    >
                                        {lng === 'en' ? 'Frequently Asked Questions' : 'Preguntas frecuentes'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://gov.texas.gov/organization/disabilities'>
                                        {lng === 'en'
                                            ? "Governor's Committee On People With Disabilities"
                                            : 'Comité del gobernador sobre personas con discapacidad'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://gov.texas.gov/organization/hsgd'>
                                        {lng === 'en' ? 'Homeland Security' : 'Seguridad nacional'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://tea.texas.gov/mil/'>
                                        {lng === 'en' ? 'Military Families' : 'Familias militares'}
                                    </Link>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <Typography className={classes.content} component='div'>
                            <List dense={true} color='tertiary' aria-label='block-footermenu2-menu'>
                                <ListItem>
                                    <Link
                                        color='inherit'
                                        href='https://tea.texas.gov/About_TEA/Contact_Us/Public_Information_Requests/'
                                    >
                                        {lng === 'en'
                                            ? 'Public Information Requests'
                                            : 'Solicitudes de información pública'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://texas.gov/'>
                                        {lng === 'en' ? 'State of Texas' : 'Estado de texas'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://capitol.texas.gov/'>
                                        {lng === 'en' ? 'Texas Legislature' : 'Legislatura de Texas'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://veterans.portal.texas.gov/'>
                                        {lng === 'en' ? 'Texas Veterans Portal' : 'Portal de veteranos de Texas'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://www.tsl.texas.gov/trail/index.html'>
                                        {lng === 'en' ? 'Trail' : 'SENDERO'}
                                    </Link>
                                </ListItem>
                                <ListItem>
                                    <Link color='inherit' href='https://comptroller.texas.gov/transparency/'>
                                        {lng === 'en' ? 'Where Our Money Goes' : 'A dónde va nuestro dinero'}
                                    </Link>
                                </ListItem>
                            </List>
                        </Typography>
                    </Grid>{' '}
                </Grid>

                <Grid item xs={12}>
                    <hr />
                </Grid>
            </Grid>
            <Copyright />
        </div>
    );
}

export default Footer;

import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import LocationOn from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: '2px 2px, -1px -1px',
        },
    },
    topCard: {
        // minHeight: 275,
    },

    title: {
        fontWeight: 600,
        lineHeight: 1,
    },
    subtitle: {
        lineHeight: 1,
    },
}));

const DistrictCard = ({ district, years, lng, onZoomMap }) => {
    const classes = useStyles();

    const [year, setYear] = useState(district.year);

    const handleYearChange = (event) => {
        setYear(event.target.value);
    };

    const zoomMap = () => {
        onZoomMap(district.id, 'district');
    };
    return (
        <Card className={classes.root}>
            <CardContent className={classes.topCard}>
                <a
                    href={process.env.REACT_APP_BASEPATH + '?view=district&id=' + district.id + '&lng=' + lng}
                    target='_blank'
                >
                    <Typography className={classes.title} gutterBottom>
                        {lng === 'en' ? 'District' : 'Distrito'}: {district.name}
                    </Typography>
                </a>
                <Typography className={classes.subtitle} gutterBottom>
                    {district.address}
                    {district.address === '' ? null : (
                        <IconButton onClick={zoomMap}>
                            <LocationOn />
                        </IconButton>
                    )}
                    <br />
                    <a href={'http://' + district.website} target='_blank'>
                        {district.website}
                    </a>
                </Typography>
            </CardContent>
        </Card>
    );
};

export default DistrictCard;

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createElement } from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';

console.info('REACT_APP_SMOKETEST=' + process.env.REACT_APP_SMOKETEST);

window.ccyy = "2022"

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
    </ThemeProvider>,
    document.getElementById('root')
);

import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';

import PairedSchool from '../../components/PairedSchool';
import utils from '../../components/utils';
import { Modal } from 'react-bootstrap';
import '../../bootstrap_m.css';
import DtProgressAcademic from '../../components/dtProgressAcademic';
import ModalEntity from '../../components/modalEntity';
import ChartjsSTAAR from '../../components/chartjsSTAAR';

const useStyles = makeStyles((theme) => ({
    relativeMessage: {
        color: '#012169',
        textAlign: 'center',
        fontSize: '16pt',
        fontWeight: 'bold',
    },
    relativeTitle: {
        padding: '0px 20px 0px 20px',
        color: '#704280',
        fontSize: '18pt',
        fontWeight: 'bold',
    },
    relativeFooter: {
        fontSize: '14pt',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    header: {
        fontSize: '1.5rem',
        width: '100%',
    },
    subHeader: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    subHeaderalt: {
        color: theme.palette.secondary.main,
        fontSize: '1rem',
    },
    subHeadernr: {
        fontSize: '3rem',
    },

    frmsz: {
        width: '100%',
        height: '100%',
        border: '0',
    },

    frmsze: {
        width: '100%',
        height: '72%',
        border: '0',
        minHeight: '17rem',
    },

    frmszenr: {
        width: '100%',
        height: '66%',
        border: '0',
        minHeight: '13rem',
    },

    frmszeb: {
        width: '100%',
        height: '81%',
        border: '0',
        minHeight: '23rem',
    },
    frmszebb: {
        width: '100%',
        height: '51%',
        border: '0',
        minHeight: '23rem',
    },

    gridItem: {
        padding: '.5rem',
        borderRight: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        // borderRadius: '.25rem',
        textAlign: 'center',
    },
    gridItem2: {
        padding: '.5rem',
        borderRight: '.1px solid ' + theme.palette.background.tertiary,
        borderLeft: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        //  borderRadius: '.25rem',
        textAlign: 'center',
        //  height: '25rem',
    },
    gradea: {
        color: '#278143',
        fontSize: '1.5rem',
    },
    gradeb: {
        color: '#39398C',
        fontSize: '1.5rem',
    },
    gradec: {
        color: '#9C118B',
        fontSize: '1.5rem',
    },
    graded: {
        fontSize: '1.5rem',
        color: '#E0803D',
    },
    gradef: {
        fontSize: '1.5rem',
        color: '#CA313C',
    },
    Gradeheadera: {
        fontSize: '6rem',
        color: '#278143',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '6rem',
        color: '#39398C',
        padding: '14px 0px 0px 51px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '6rem',
        color: '#9C118B',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '6rem',
        color: '#E0803D',
        padding: '14px 0px 0px 53px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '6rem',
        color: '#CA313C',
        padding: '14px 0px 0px 57px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.2rem',
        color: 'black',
        padding: '28px 0px 0px 32px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    gradenr: {
        color: '#51555A',
        fontSize: '1.5rem',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },
    perfHeader: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.primary.main,
        minHeight: '3rem',
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.7rem',
        padding: '1px 1px 7px 1px',
    },
    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        minHeight: '3.5rem',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    grayHeader2: {
        backgroundColor: '#51555A',
        color: 'white',
        textAlign: 'left',
        minHeight: '3.5rem',
        fontSize: '1.5rem',
        fontWeight: 'bold',
    },
    grayHeader3: {
        backgroundColor: '#51555A',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.3rem',
        fontWeight: 'bold',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
}));

export default ({ school, paired, onNav }) => {
    // set lang first;
    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
    }
    const entity = school;
    const entity_name = lang === 'en' ? 'school' : 'escuela';
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use entity id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === entity.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mId_school_progress_rating = getContentId(dynModMatchedList, 'school_progress_rating');
    const mId_school_progress_weighting = getContentId(dynModMatchedList, 'school_progress_weighting');

    // progress
    const [progress, setProgress] = useState([]);
    useEffect(() => {
        fetch('./data/school_progress_tab_alt.json')
            .then((resp) => resp.json())
            .then((json) => {
                setProgress(json);
            });
    }, []);
    const progressMatchedList = progress.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = progressMatchedList.length === 0;
    const labelsProgress_en = checkMatchedList ? ['', '', ''] : ['Academic Growth', 'Relative Performance', 'Total'];
    const labelsProgress_es = checkMatchedList
        ? ['', '', '']
        : ['Crecimiento Académico', 'Desempeño Relativo', 'Total'];
    const labelsProgress = lang === 'en' ? labelsProgress_en : labelsProgress_es;
    const labelsProgressHeader_en = ['Component', 'Score', '% of Grade'];
    const labelsProgressHeader_es = ['Componente', 'Puntaje', '% de Grado'];
    const labelsProgressHeader = lang === 'en' ? labelsProgressHeader_en : labelsProgressHeader_es;
    const datascoreListEntity = checkMatchedList ? ['', '', ''] : progressMatchedList[0].score;
    const datapct_of_gradeListEntity = checkMatchedList ? ['', '', ''] : progressMatchedList[0].pct_of_grade;
    const labelsAcademic_en = checkMatchedList ? ['', '', '', ''] : ['0 Points', '1/2 Point', '1 Point', 'Total'];
    const labelsAcademic_es = checkMatchedList ? ['', '', '', ''] : ['0 Puntos', '1/2 Punto', '1 Punto', 'Total'];
    const labelsAcademic = lang === 'en' ? labelsAcademic_en : labelsAcademic_es;
    const labelsAcademicHeader_en = ['', '# of Tests', 'Points Earned'];
    const labelsAcademicHeader_es = ['', '# de Puntos de', 'Prueba Ganados'];
    const labelsAcademicHeader = lang === 'en' ? labelsAcademicHeader_en : labelsAcademicHeader_es;
    const datanum_of_testListEntity = checkMatchedList ? ['', '', '', ''] : progressMatchedList[0].num_of_test;
    const datapoints_earnedListEntity = checkMatchedList ? ['', '', '', ''] : progressMatchedList[0].points_earned;
    const datamessageEntity = checkMatchedList ? '' : progressMatchedList[0].message;
    const datatitleEntity = checkMatchedList ? '' : progressMatchedList[0].title;
    const dataeco_dis_yrEntity = checkMatchedList ? '' : progressMatchedList[0].eco_dis_yr;
    const dataeco_dis_pctEntity = checkMatchedList ? '' : progressMatchedList[0].eco_dis_pct;
    const datamin_f_to_a_max_labelListEntity = checkMatchedList
        ? ['', '', '', '']
        : progressMatchedList[0].min_f_to_a_max_label;
    const datamin_f_to_a_max_scoreListEntity = checkMatchedList
        ? ['', '', '', '']
        : progressMatchedList[0].min_f_to_a_max_score;
    utils.setHomeTitle(!!school && school.name + ' | School Progress');

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
    }

    const handleClicka = () => {
        window.open(
            'https://' +
                locserver +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain2a.sas'
        );
    };
    const handleClickb = () => {
        window.open(
            'https://' +
                locserver +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain2b.sas'
        );
    };

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    const [showprr, setShowprr] = useState(false);
    const handleCloseprr = () => {
        setShowprr(!showprr);
    };
    // const handleShowprr = () => setShowprr(true);

    const [showprw, setShowprw] = useState(false);
    const handleCloseprw = () => {
        setShowprw(!showprw);
    };
    // const handleShowprw = () => setShowprw(true);

    const [showacg, setShowacg] = useState(false);
    const handleCloseacg = () => {
        setShowacg(!showacg);
    };
    // const handleShowacg = () => setShowacg(true);

    const [showrep, setShowrep] = useState(false);
    const handleCloserep = () => {
        setShowrep(!showrep);
    };
    // const handleShowrep = () => setShowrep(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const boxRelativePerformanceDetails = (msg) => {
        const msg_N =
            lang === 'en'
                ? 'This ' + entity_name + ' not rated for Relative Performance.'
                : 'Este ' + entity_name + ' no está calificado para el desempeño relativo.';
        const msg_S =
            lang === 'en'
                ? 'This district is a single campus district. <br />Please refer to the campus report.'
                : 'Este distrito es un distrito de un solo campus.<br />Consulte el informe del campus.';
        const title = 'title';
        const title_A = lang === 'en' ? 'STAAR Performance' : 'Rendimiento STAAR';
        const title_B =
            lang === 'en'
                ? 'STAAR and College, Career, or Military Readiness Average Score'
                : 'STAAR y puntaje promedio de preparación para la universidad, la carrera o el ejército';
        const titleMap = { B: title_B, A: title_A };
        const footer =
            lang === 'en'
                ? '     ' + dataeco_dis_yrEntity + ' Economically Disadvantaged: ' + dataeco_dis_pctEntity + '     '
                : '     ' + dataeco_dis_yrEntity + ' Económicamente en Desventaja: ' + dataeco_dis_pctEntity + '     ';
        switch (msg) {
            case 'N':
                {
                    return <div className={myClasses.relativeMessage}>{msg_N}</div>;
                }
                break;
            case 'S':
                {
                    return;
                    <div className={myClasses.relativeMessage}>{msg_S}</div>;
                }
                break;
            default: {
                return (
                    <>
                        <div className={myClasses.relativeTitle}>{!!datatitleEntity && titleMap[datatitleEntity]}</div>
                        <ChartjsSTAAR boxData={datamin_f_to_a_max_scoreListEntity} />
                        <div className={myClasses.relativeFooter}>{!!dataeco_dis_pctEntity && footer}</div>
                    </>
                );
            }
        }
    };
    return (
        <>
            {!!paired ? (
                <PairedSchool />
            ) : (
                <div>
                    <div className={myClasses.blueHeader}>
                        <img
                            src='./images/icon-school-progress.png'
                            alt='Progress icon'
                            style={{
                                height: '2.8rem',
                                marginRight: '.5rem',
                                marginTop: '.4rem',
                                marginBottom: '-.3rem',
                            }}
                        />{' '}
                        {lang === 'en' ? 'SCHOOL PROGRESS' : 'PROGRESO ESCOLAR'}{' '}
                    </div>

                    {school.d2g !== 'Not Rated' ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses['Gradeheader' + school.d2g.toLowerCase()]}>
                                        {school.d2g}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.d2g.toLowerCase()]}
                                        variant='determinate'
                                        value={school.d2s}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses['grade' + school.d2g.toLowerCase()]}>
                                    {' '}
                                    {school.d2s}
                                    {lang === 'en' ? ' out of' : ' de'} 100
                                </span>

                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? "School Progress shows how students perform over time and how the school's performance compares to other schools with similar economically disadvantaged student populations."
                                        : 'El progreso escolar muestra cómo se desempeñan los estudiantes a lo largo del tiempo y cómo se compara el desempeño de la escuela con otras escuelas con poblaciones estudiantiles económicamente desfavorecidas similares.'}
                                </p>
                                <ModalEntity modalId={mId_school_progress_rating} lang={lang} />
                            </Grid>

                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'School Progress Calculation' : 'Cálculo del Progreso Escolar'}
                                </b>
                                <br />
                                <br />
                                <br />
                                <div>
                                    {' '}
                                    <DtProgressAcademic
                                        labels={labelsProgress}
                                        datacol1={datascoreListEntity}
                                        datacol2={datapct_of_gradeListEntity}
                                        headerlabel={labelsProgressHeader}
                                    />{' '}
                                </div>
                                <br />
                                <br />
                                <br />
                                <ModalEntity modalId={mId_school_progress_weighting} lang={lang} />
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.d2g === 'Not Rated' && school.d2s === null ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b className={myClasses.subHeadernr}>
                                        <center>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                    </b>
                                </div>
                                <br />
                                <br />
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? "School Progress shows how students perform over time and how the school's performance compares to other schools with similar economically disadvantaged student populations."
                                        : 'El progreso escolar muestra cómo se desempeñan los estudiantes a lo largo del tiempo y cómo se compara el desempeño de la escuela con otras escuelas con poblaciones estudiantiles económicamente desfavorecidas similares.'}
                                </p>
                                <br />
                                <ModalEntity modalId={mId_school_progress_rating} lang={lang} />
                            </Grid>

                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {' '}
                                    {lang === 'en' ? 'School Progress Calculation' : 'Cálculo del Progreso Escolar'}
                                </b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b className={myClasses.subHeadernr}>
                                        <center>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                    </b>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <ModalEntity modalId={mId_school_progress_weighting} lang={lang} />
                            </Grid>
                        </Grid>
                    ) : null}

                    <br />
                    <div className={myClasses.grayHeader}>
                        <img
                            src='./images/students_profile_white.png'
                            alt='Profile icon'
                            style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.7rem', marginRight: '.7rem' }}
                        />
                        {lang === 'en' ? 'School Progress Summary' : 'Resumen del Desempeño del Progreso Escolar'}
                    </div>

                    <Grid container>
                        {school.d2ag !== 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                                <div className={myClasses.perfHeader}>
                                    {' '}
                                    {lang === 'en' ? 'Academic Growth' : 'Crecimiento Académico'}{' '}
                                </div>

                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses['Gradeheader' + school.d2ag.toLowerCase()]}>
                                        {school.d2ag}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.d2ag.toLowerCase()]}
                                        variant='determinate'
                                        value={school.d2as}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses['grade' + school.d2ag.toLowerCase()]}>
                                    {' '}
                                    {school.d2as} {lang === 'en' ? ' out of' : ' de'} 100
                                </span>
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Academic growth shows the amount of growth students make from year to year.'
                                        : 'El Crecimiento Académico muestra la cantidad de crecimiento que los estudiantes hacen de un año a otro.'}
                                </p>
                                {/* '91' academic_growth modal*/}
                                <ModalEntity modalId={'91'} lang={lang} />
                            </Grid>
                        ) : null}

                        {school.d2as !== null && school.d2ag === 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                                <div className={myClasses.perfHeader}>
                                    {lang === 'en' ? 'Academic Growth' : 'Crecimiento Académico'}{' '}
                                </div>

                                <br />
                                <br />

                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado'}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={school.d2as}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses.gradenr}>
                                    {' '}
                                    {school.d2as} {lang === 'en' ? ' out of' : ' de'} 100
                                </span>
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Academic growth shows the amount of growth students make from year to year.'
                                        : 'El Crecimiento Académico muestra la cantidad de crecimiento que los estudiantes hacen de un año a otro.'}
                                </p>
                                {/* '91' academic_growth modal*/}
                                <ModalEntity modalId={'91'} lang={lang} />
                            </Grid>
                        ) : null}

                        {school.d2as === null && school.d2ag === 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                                <div className={myClasses.perfHeader}>
                                    {' '}
                                    {lang === 'en' ? 'Academic Growth' : 'Crecimiento Académico'}
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <b className={myClasses.subHeadernr}>
                                    <center> {lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                </b>
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Academic growth shows the amount of growth students make from year to year.'
                                        : 'El Crecimiento Académico muestra la cantidad de crecimiento que los estudiantes hacen de un año a otro.'}
                                </p>
                                {/* '91' academic_growth modal*/}
                                <ModalEntity modalId={'91'} lang={lang} />
                            </Grid>
                        ) : null}

                        {school.d2bg !== 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <div className={myClasses.perfHeader}>
                                    {lang === 'en' ? 'Relative Performance' : 'Desempeño Relativo'}{' '}
                                </div>
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses['Gradeheader' + school.d2bg.toLowerCase()]}>
                                        {school.d2bg}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.d2bg.toLowerCase()]}
                                        variant='determinate'
                                        value={school.d2bs}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses['grade' + school.d2bg.toLowerCase()]}>
                                    {' '}
                                    {school.d2bs} {lang === 'en' ? ' out of' : 'de'} 100
                                </span>
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? "Relative Performance measures how a school's performance compares to other schools with similar economically disadvantaged populations."
                                        : 'El Desempeño Relativo mide cómo el desempeño de una escuela se compara con otras escuelas con poblaciones económicamente desfavorecidas similares.'}
                                </p>

                                {/* '92' relative_performance modal*/}
                                <ModalEntity modalId={'92'} lang={lang} />
                            </Grid>
                        ) : null}

                        {school.d2bs !== null && school.d2bg === 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <div className={myClasses.perfHeader}>
                                    {' '}
                                    {lang === 'en' ? 'Relative Performance' : 'Desempeño Relativo'}
                                </div>
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado'}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={school.d2bs}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses.gradenr}>
                                    {' '}
                                    {school.d2bs} {lang === 'en' ? ' out of' : ' de'} 100
                                </span>
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? "Relative Performance measures how a school's performance compares to other schools with similar economically disadvantaged populations."
                                        : 'El Desempeño Relativo mide cómo el desempeño de una escuela se compara con otras escuelas con poblaciones económicamente desfavorecidas similares.'}
                                </p>

                                {/* '92' relative_performance modal*/}
                                <ModalEntity modalId={'92'} lang={lang} />
                            </Grid>
                        ) : null}

                        {school.d2bs === null && school.d2bg === 'Not Rated' ? (
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <div className={myClasses.perfHeader}>
                                    {' '}
                                    {lang === 'en' ? 'Relative Performance' : 'Desempeño Relativo'}
                                </div>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <br />
                                    <br />{' '}
                                    <b className={myClasses.subHeadernr}>
                                        <center> {lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                    </b>
                                </div>
                                <br />
                                <br />
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? "Relative Performance measures how a school's performance compares to other schools with similar economically disadvantaged populations."
                                        : 'El Desempeño Relativo mide cómo el desempeño de una escuela se compara con otras escuelas con poblaciones económicamente desfavorecidas similares.'}
                                </p>

                                {/* '92' relative_performance modal*/}
                                <ModalEntity modalId={'92'} lang={lang} />
                            </Grid>
                        ) : null}
                    </Grid>

                    <Grid container>
                        <Grid item xs={12} md={6} className={myClasses.gridItem2}>
                            <div className={myClasses.grayHeader2}>
                                <img
                                    src='./images/academic_growth_white.png'
                                    alt='Academic growth icon'
                                    style={{
                                        height: '1.75rem',
                                        marginLeft: '1rem',
                                        marginTop: '.4rem',
                                        marginRight: '.7rem',
                                    }}
                                />
                                {lang === 'en'
                                    ? 'Academic Growth Details'
                                    : 'Detalles de Desempeño del Crecimiento Académico'}
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <DtProgressAcademic
                                labels={labelsAcademic}
                                datacol1={datanum_of_testListEntity}
                                datacol2={datapoints_earnedListEntity}
                                headerlabel={labelsAcademicHeader}
                            />
                            <br />
                            <br />
                            <Button
                                variant='contained'
                                color='primary'
                                className={myClasses.sectionButton}
                                onClick={handleClicka}
                            >
                                {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                            </Button>
                            <br />
                        </Grid>
                        <Grid item xs={12} md={6} className={myClasses.gridItem}>
                            <div className={myClasses.grayHeader2}>
                                <img
                                    src='./images/relative_performance_white.png'
                                    alt='relative performance icon'
                                    style={{
                                        height: '1.75rem',
                                        marginLeft: '1rem',
                                        marginTop: '.4rem',
                                        marginRight: '.7rem',
                                    }}
                                />
                                {lang === 'en' ? 'Relative Performance Details' : 'Detalles de Desempeño Relativo'}{' '}
                            </div>
                            <br />
			     {boxRelativePerformanceDetails(datamessageEntity)} 
                            <br />
                            <Button
                                variant='contained'
                                color='primary'
                                style={{ marginBottom: '9rem' }}
                                className={myClasses.sectionButton}
                                onClick={handleClickb}
                            >
                                {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            )}
        </>
    );
};

import geodist from 'geodist';
export default class utils {
    static setHomeTitle(subText) {
        document.title = 'Texas School Report Cards | ' + subText;
    }

    /** Filter items based on set of filters
     * @param {array} data
     * @param {array[]} filters - array of id and values pairs for filters various column id's
     */
    static advanced_filter(data, filters_in) {
        const results = data.filter((item) => {
            let match = true;
            for (let i = 0; i < filters_in.length; i++) {
                const filter = filters_in[i];
                if (typeof filter[1] === 'function') {
                    if (filter[1](item) === false) {
                        match = false;
                        break;
                    }
                } else if (filter[1].indexOf(item[filter[0]]) === -1) {
                    match = false;
                    break;
                }
            }
            return match;
        });
        return results;
    }
    static listValues(data, column) {
        /**
         * @name listValues (function)
         * @property data - data array
         * @property column - column name to enumerate values
         * @example totValues(data, 'column1')
         */
        const list = {};
        data.map((item) => {
            list[item[column]] = item[column];
        });
        return Object.keys(list).map((item) => list[item]);
    }
    static translateCode2Label(code, list) {
        let answer = '';
        for (let i = 0; i < list.length; i++) {
            if (list[i].value === code) {
                answer = list[i].label;
                break;
            }
        }
        return answer;
    }

    static formatPhone(val) {
        if (!!val) {
            const cleaned = ('' + val).replace(/\D/g, '');
            const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (!!match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
        } else {
            return '';
        }
    }

    /**
     * Calculate the distance from the location to the school in miles
     * and add "distance" property to school
     * @param schools - json array for the schools
     * @param location - location such as home address
     */
    static addDistance2Schools(schools, location) {
        const result = schools.map((school) => {
            school.distance = geodist(
                { lat: school.latitude, lon: school.longitude },
                { lat: location.location.y, lon: location.location.x }
            );
            return school;
        });
        return result;
    }

    static sortIt(data, _sort_order) {
        /**
         * @name sortIt (function)
         * @property data - data to be sorted
         * @property sortorder - array of sort keys in order
         * @property sortorder.key - column to be sorted
         * @property sortorder.direction - 'ASC'/ 'DSC'
         * @property sortorder.order - (optional) array of values for custom order
         * @example sortIt(data, [{key: 'column1', direction: 'ASC'},
         *            {key: 'column2', direction: 'ASC',
         *            order: ['item1','item2','item4','item5']})
         */

        if (typeof Object.assign !== 'function') {
            Object.assign = function (target) {
                'use strict';
                if (target === null) {
                    throw new TypeError('Cannot convert undefined or null to object');
                }

                target = Object(target);
                for (let index = 1; index < arguments.length; index++) {
                    const source = arguments[index];
                    if (source !== null) {
                        for (const key in source) {
                            if (Object.prototype.hasOwnProperty.call(source, key)) {
                                target[key] = source[key];
                            }
                        }
                    }
                }
                return target;
            };
        }
        const dataIn = Object.assign([], data);
        const sorteddata = dataIn.sort((item1, item2) => {
            for (let i = 0; i < _sort_order.length; i++) {
                if (_sort_order[i].order === undefined) {
                    // No custom order

                    if (
                        (item1[_sort_order[i].key] > item2[_sort_order[i].key] && _sort_order[i].direction === 'ASC') ||
                        (item1[_sort_order[i].key] < item2[_sort_order[i].key] && _sort_order[i].direction === 'DSC')
                    ) {
                        return 1;
                    } else if (
                        (item1[_sort_order[i].key] < item2[_sort_order[i].key] && _sort_order[i].direction === 'ASC') ||
                        (item1[_sort_order[i].key] > item2[_sort_order[i].key] && _sort_order[i].direction === 'DSC')
                    ) {
                        return -1;
                    }
                } else {
                    // custom order
                    const pos1 = _sort_order[i].order.indexOf(item1[_sort_order[i].key]);
                    const pos2 = _sort_order[i].order.indexOf(item2[_sort_order[i].key]);
                    if (
                        (pos1 > pos2 && _sort_order[i].direction === 'ASC') ||
                        (pos1 < pos2 && _sort_order[i].direction === 'DSC')
                    ) {
                        return 1;
                    } else if (
                        (pos1 < pos2 && _sort_order[i].direction === 'ASC') ||
                        (pos1 > pos2 && _sort_order[i].direction === 'DSC')
                    ) {
                        return -1;
                    }
                }
            }
            return 0;
        });
        return sorteddata;
    }

    static transposeGrid(objArr, maxGridCols, isMD, isSM) {
        const maxCols = Math.min(maxGridCols, objArr.length);
        const mdColSpan = 12 / maxCols; // this will be 12, 6, 4, or 3 (assuming MAX_PROGRAMS_COLS is 1-4, since these divide 12)
        const smColSpan = Math.max(mdColSpan, 6); // this will either be 12 (for 1 program) or 6 (for 2+ programs)
        const xsColSpan = 12;
        const colSpan = isMD ? mdColSpan : isSM ? smColSpan : xsColSpan;
        const numCols = 12 / colSpan;
        const numRows = Math.ceil(objArr.length / numCols);

        // now re-order the programs so we can layout the Grid in a array#map function
        const minObjPerCol = Math.floor(objArr.length / numCols); // each column will have at least this many rows
        const colsWithExtra = objArr.length % numCols; // these columns will have exactly 1 extra row

        const maxInCol = (col) => minObjPerCol + (col % numCols < colsWithExtra ? 1 : 0);

        const structuredRows = [];
        let currRow = 0;
        let currCol = 0;
        objArr.forEach((program, idx) => {
            if (idx < numRows) {
                // the first time through we need to initialize our nested arrays
                structuredRows[idx] = [];
            }

            structuredRows[currRow].push(program);
            currRow++;

            if (currRow === maxInCol(currCol)) {
                currCol++;
                currRow = 0;
            }
        });

        let sortedObjs = [];
        structuredRows.forEach((row) => {
            sortedObjs = sortedObjs.concat(row);
        });

        return [sortedObjs, colSpan];
    }
}






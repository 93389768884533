import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';

import utils from '../../components/utils';
import '../../bootstrap_m.css';
import DtAccountability from '../../components/dtAccountability';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
    },
    subHeader: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    subHeadernr: {
        fontSize: '3.2rem',
    },
    gridItem: {
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        borderRadius: '.25rem',
        textAlign: 'center',
    },

    perfGridItem: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfBox: {
        margin: '.5rem',
        border: '.1px solid ' + theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfWrapper: {},
    perfHeader: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.primary.main,
        minHeight: '4rem',
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.7rem',
    },
    perfIcon: {
        height: '3.5rem',
        float: 'left',
        padding: '.5rem',
    },
    perfText: {
        textAlign: 'left',
        padding: '.5rem',
        [theme.breakpoints.up('md')]: {
            minHeight: '5rem',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '5rem',
        },
    },
    perfButton: {
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '13rem',
    },
    frmszeb: {
        minHeight: '17rem',
        height: '65%',
        width: '100%',
        margin: 'auto',
        border: '0',
    },
    frmszea: {
        height: '100%',
        width: '100%',
        margin: '0',
        border: 'none',
    },
    section: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.tertiary,
    },
    sectionIcons: {
        margin: '1rem',
        textAlign: 'center',
    },
    sectionf: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },

    sectionIcon: {
        width: '190px',
        height: '180px',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    Gradeheadera: {
        fontSize: '6rem',
        color: '#278143',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '6rem',
        color: '#39398C',
        padding: '14px 0px 0px 53px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '6rem',
        color: '#9C118B',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '6rem',
        color: '#E0803D',
        padding: '14px 0px 0px 53px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '6rem',
        color: '#CA313C',
        padding: '14px 0px 0px 57px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    gradenr: {
        color: '#51555A',
    },
    Gradeheadernr: {
        fontSize: '2.6rem',
        color: 'black',
        padding: '30px 0px 0px 34px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrs: {
        fontSize: '2.4rem',
        color: 'black',
        padding: '27px 0px 0px 31px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrssp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 21px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },
    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },
}));

export default ({ district, onNav }) => {
    utils.setHomeTitle(!!district && district.name + ' | Accountability Overview');

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
    }

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    let lngtype2 = 'Gradeheadernrs';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
        lngtype2 = 'Gradeheadernrssp';
    }
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use district id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === district.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdOverPerfOrder2 = getContentId(dynModMatchedList, 'overall_performance');

    // map rating_cd to modal ID
    const getModalId = (rating_cd) => {
        const modalMap = { A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' };
        return modalMap[rating_cd];
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [showov, setShowov] = useState(false);
    const handleCloseov = () => setShowov(false);
    const handleShowov = () => setShowov(true);

    const [showdis, setShowdis] = useState(false);
    const handleClosedis = () => setShowdis(false);
    const handleShowdis = () => setShowdis(true);

    const perfBoxa = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {district.d1g === 'Not Rated' && !district.d1s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : district.d1g === 'Not Rated' && district.d1s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses[lngtype2]}>
                            {lang === 'en' ? 'Not' : 'No'}
                            <br />
                            {lang === 'en' ? 'Rated' : 'Clasificado'}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses.gradenr}
                            variant='determinate'
                            value={parseFloat(district.d1s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}>
                            {district.d1s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + district.d1g.toLowerCase()]}>
                            {district.d1g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + district.d1g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(district.d1s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + district.d1g.toLowerCase()]}>
                            {' '}
                            {district.d1s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBoxb = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {district.d2g === 'Not Rated' && !district.d2s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}> {lang === 'en' ? 'Not Rated' : 'No Clasificado'} </b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : district.d2g === 'Not Rated' && district.d2s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses[lngtype2]}>
                            {lang === 'en' ? 'Not' : 'No'}
                            <br />
                            {lang === 'en' ? 'Rated' : 'Clasificado'}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses.gradenr}
                            variant='determinate'
                            value={parseFloat(district.d2s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}>
                            {district.d2s}
                            {lang === 'en' ? 'out of' : 'de'} 100{' '}
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + district.d2g.toLowerCase()]}>
                            {district.d2g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + district.d2g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(district.d2s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + district.d2g.toLowerCase()]}>
                            {' '}
                            {district.d2s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBoxc = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {district.d3g === 'Not Rated' && !district.d3s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}> {lang === 'en' ? 'Not Rated' : 'No Clasificado'} </b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : district.d3g === 'Not Rated' && district.d3s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses[lngtype2]}>
                            {lang === 'en' ? 'Not' : 'No'}
                            <br />
                            {lang === 'en' ? 'Rated' : 'Clasificado'}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses.gradenr}
                            variant='determinate'
                            value={parseFloat(district.d3s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}>
                            {' '}
                            {district.d3s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Performance Icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + district.d3g.toLowerCase()]}>
                            {district.d3g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + district.d3g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(district.d3s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + district.d3g.toLowerCase()]}>
                            {' '}
                            {district.d3s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    return (
        <div>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/overview2_white.png'
                    alt='Performance Icon'
                    style={{ height: '2.6rem', marginRight: '.5rem', marginTop: '.7rem', marginBottom: '-.3rem' }}
                />{' '}
                {lang === 'en' ? 'ACCOUNTABILITY OVERVIEW' : 'VISIÓN GENERAL DE DESEMPEÑO'}{' '}
            </div>
            <Grid container>
                <Grid item xs={12} md={6} className={myClasses.gridItem}>
                    <b className={myClasses.subHeader}>{lang === 'en' ? 'Overall Rating' : 'Calificación General'}</b>
                    <br />
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        {district.rating !== 'Not Rated' ? (
                            <b id='gradea' className={myClasses['Gradeheader' + district.rating.toLowerCase()]}>
                                {district.rating}
                            </b>
                        ) : district.rating === 'Not Rated' && district.score !== null ? (
                            <b id='gradea' className={myClasses[lngtype]}>
                                {lang === 'en' ? 'Not' : 'No'}
                                <br />
                                {lang === 'en' ? 'Rated' : 'Clasificado'}
                            </b>
                        ) : (
                            <b className={myClasses.subHeadernr}> {lang === 'en' ? 'Not Rated' : 'No Clasificado'} </b>
                        )}
                        {district.rating !== 'Not Rated' ? (
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                        ) : district.rating === 'Not Rated' && district.score !== null ? (
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={185}
                            />
                        ) : (
                            <br />
                        )}
                        {district.rating !== 'Not Rated' ? (
                            <CircularProgress
                                className={myClasses['grade' + district.rating.toLowerCase()]}
                                variant='determinate'
                                value={parseFloat(district.score, 10)}
                                size={165}
                            />
                        ) : district.rating === 'Not Rated' && district.score !== null ? (
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(district.score, 10)}
                                size={185}
                            />
                        ) : (
                            <br />
                        )}
                    </div>
                    {district.rating !== 'Not Rated' ? (
                        <b className={myClasses.subHeader}>
                            <span className={myClasses['grade' + district.rating.toLowerCase()]}>
                                {' '}
                                {district.score} {lang === 'en' ? 'out of' : 'de'} 100
                            </span>
                        </b>
                    ) : district.rating === 'Not Rated' && district.score !== null ? (
                        <b className={myClasses.subHeader}>
                            <span className={myClasses.gradenr}>
                                {' '}
                                {district.score} {lang === 'en' ? 'out of' : 'de'} 100
                            </span>
                        </b>
                    ) : (
                        <br />
                    )}

                    {district.alt_standards === 'Yes' ? (
                        <p style={{ fontSize: '1.2rem' }}>
                            <img
                                src='./images/aea_icon.png'
                                alt='AEA school icon'
                                style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-.5rem' }}
                            />{' '}
                            {lang === 'en'
                                ? 'Alternative standards were used to rate this district.'
                                : 'Se utilizaron estándares alternativos para calificar esta distrito.'}
                        </p>
                    ) : null}

                    {district.rating_cd === 'U' ? (
                        <p style={{ fontSize: '1.2rem' }}>
                            <img
                                src='./images/info_icon_navy.png'
                                alt='not rated school icon'
                                style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                            />
                            &nbsp;
                            {lang === 'en'
                                ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                            <br />
                        </p>
                    ) : null}
                        {district.rating_cd === 'Q' ? (
                           <p style={{ fontSize: '1.2rem' }}>
                            <img
                                src='./images/exclamation_point.jpg'
                                alt='not rated school icon'
                                style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                            />
                            &nbsp;
                            {lang === 'en'
                                ? 'Not Rated: Data Under Review. Rating is pending while data is reviewed by TEA. Learn more: '
                                : 'Este distrito no Está Clasificado: Datos Bajo Revisión. '}
                               <a href='https://tea.texas.gov/about-tea/contact-us/self-reported-data-compliance-review' target="_blank" alt="Data compliance review link"> Data Compliance Review</a>
                            <br />
                        </p>
                       ) : null}
                            {district.alls_chgf === 'Y' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                 <img
                                  src='./images/exclamation_point.jpg'
                                  alt='attention school icon'
                                  style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                 />
                               &nbsp;
                               {lang === 'en'
                                ? 'Overall score or rating updated as a result of a Data Compliance Review. Learn more: '
                                : 'La calificación total o puntaje se actualizó como resultado de una Revisión de Cumplimiento de Datos.'}
                               <a href='https://tea.texas.gov/about-tea/contact-us/self-reported-data-compliance-review' target="_blank" alt="Data compliance review link"> Data Compliance Review</a>
                              <br />
                             </p>
                            ) : null}

                    <p style={{ fontSize: '1rem' }}>
                        {lang === 'en'
                            ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                            : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                    </p>
                    <br />
                    {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                    {/* modal=overall_performance */}
                    <ModalEntity modalId={getModalId(district.rating_cd)} modalId2={mIdOverPerfOrder2} lang={lang} />
                </Grid>

                <Grid item xs={12} md={6} className={myClasses.gridItem}>
                    <b className={myClasses.subHeader}>
                        {lang === 'en' ? 'Change Over Time' : 'Cambio a lo largo del Tiempo'}
                    </b>
                    <br />
                    <br />

                    <div>
                        {' '}
                        <DtAccountability entity={district} />{' '}
                    </div>

                    {/* <iframe className={myClasses.frmszeb}
                            src={
                                'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' + [window.ccyy] + '&id=' + district.id + '&prgopt=txs/acct_calc.sas' 
                                } /> */}

                    <p style={{ fontSize: '.7rem', margin: '0% 10% 0%' }}>
                        {lang === 'en'
                            ? '* Given the impact of COVID-19, all districts and schools received a label of Not Rated: Declared State of Disaster for their 2019-20 and 2020-21 accountability ratings.'
                            : '* Dado el impacto de COVID-19, todos los distritos y escuelas recibieron una etiqueta de No Calificado: Declarado Estado de Desastre para sus calificaciones de responsabilidad 2019-20 y 2020-21.'}
                    </p>
                    <p style={{ fontSize: '1rem' }}>
                        {lang === 'en'
                            ? 'This shows how overall performance at the school has changed over time.'
                            : 'Esto muestra cómo el desempeño general en la escuela ha cambiado con el tiempo. '}
                    </p>
                </Grid>
            </Grid>
            <div className={myClasses.grayHeader}>
                <img
                    src='./images/students_profile_white.png'
                    alt='student profile Icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem', marginRight: '.7rem' }}
                />
                {lang === 'en' ? 'Overall Performance Details' : 'Detalles Generales del Desempeño'}
            </div>

            <Grid container>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxa(
                        'icon-school-achievement_white.png',
                        lang === 'en' ? 'Student Achievement' : 'Logro Estudiantil',
                        lang === 'en'
                            ? 'Student Achievement measures whether students met expectations on the STAAR test. It also measures graduation rates and how prepared students are for success after high school.'
                            : 'El Logro Estudiantil mide si los estudiantes cumplieron con las expectativas en el examen STAAR. También mide el porcentaje de graduados y qué tan preparados están los estudiantes para el éxito después de la escuela secundaria.',
                        'achievement'
                    )}
                </Grid>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxb(
                        'icon-school-progress.png',
                        lang === 'en' ? 'School Progress' : 'Progreso Escolar',
                        lang === 'en'
                            ? "School Progress shows how students perform over time and how the district's performance compares to other districts with similar economically disadvantaged student populations."
                            : 'El progreso escolar muestra cómo se desempeñan los estudiantes a lo largo del tiempo y cómo se compara el desempeño del distrito con otros distritos con poblaciones estudiantiles económicamente desfavorecidas similares.',
                        'progress'
                    )}
                </Grid>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxc(
                        'icon-closing-gap.png',
                        lang === 'en' ? 'Closing the Gaps' : 'Cierre de Diferencias',
                        lang === 'en'
                            ? 'Closing the Gaps tells us how well a district is ensuring that all student groups are successful.'
                            : 'Cierre de Diferencias nos dice qué tan bien una escuela se está asegurando de que todos los grupos de estudiantes tengan éxito.',
                        'gaps'
                    )}
                </Grid>
            </Grid>

            {!!district.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/distinction_white.png'
                        alt='distinctions Icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Distinction Designations' : 'Designaciones de Distinción'}
                </div>
            ) : null}

            {!!district.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.sectionf}>
                    <div className={myClasses.sectionIcons}>
                        {district.distinctions[5] !== 'X' ? (
                            <img
                                src={
                                    './images/postsec-' +
                                    (district.distinctions[5] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Postsecondary distinction icon'
                            />
                        ) : null}
                        <br />
                        <br />
                        {/* '142' distinction_designations modal*/}
                        <ModalEntity modalId={'142'} lang={lang} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { navigate } from '@reach/router';
import qs from 'querystringify';

import Container from '@material-ui/core/Container';

import Header from './components/header';
import Footer from './components/footer';
import Home from './pages/index';
import School from './pages/school';
import District from './pages/district';
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 275,
        height: '100%',
    },
    details: {
        fontSize: '1.1em',
        fontWeight: 500,
        lineHeight: 1,
    },
    appContainer: {
        display: 'flex',
        flex: '1 0 auto',
        flexShrink: 0,
        flexDirection: 'column',
        height: '100%',
    },
    appTop: {
        flexGrow: 1,
        flexShrink: 0,
        maxWidth: '1600px',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}));

export default function App(props) {
    const classes = useStyles();

    const [schools, setSchools] = useState([]);
    const [las, setLas] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [srclists_en, setSrclists_en] = useState({});
    const [srclists_es, setSrclists_es] = useState({});
    const [schoolPrograms_en, setSchoolPrograms_en] = useState([]);
    const [schoolPrograms_es, setSchoolPrograms_es] = useState([]);
    const [schoolAp_en, setSchoolAp_en] = useState([]);
    const [schoolAp_es, setSchoolAp_es] = useState([]);
    const [schoolUil_en, setSchoolUil_en] = useState([]);
    const [schoolUil_es, setSchoolUil_es] = useState([]);

    const [srclists, setSrcLists] = useState([]);
    const [schoolPrograms, setSchoolPrograms] = useState([]);
    const [schoolAp, setSchoolAp] = useState([]);
    const [schoolUil, setSchoolUil] = useState([]);

    const params = qs.parse(window.location.search);
    const [lng, setLng] = useState(params.lng || 'en');
    const [viewType, setViewType] = useState(params.view);
    const [viewId, setViewId] = useState(params.id);
    const [viewTab, setViewTab] = useState(params.tab || 'overview'); 

    

    useEffect(() => {
        if (!!lng && !!srclists_en && !!srclists_es) {
            setSrcLists(lng.toLowerCase() === 'en' ? srclists_en : srclists_es);
        }
    }, [lng, srclists_en, srclists_es]);

    useEffect(() => {
        if (!!lng && !!schoolPrograms_en && !!schoolPrograms_es) {
            setSchoolPrograms(lng.toLowerCase() === 'en' ? schoolPrograms_en : schoolPrograms_es);
        }
    }, [lng, schoolPrograms_en, schoolPrograms_es]);

    useEffect(() => {
        if (!!lng && !!schoolAp_en && !!schoolAp_es) {
            setSchoolAp(lng.toLowerCase() === 'en' ? schoolAp_en : schoolAp_es);
        }
    }, [lng, schoolAp_en, schoolAp_es]);

    useEffect(() => {
        if (!!lng && !!schoolUil_en && !!schoolUil_es) {
            setSchoolUil(lng.toLowerCase() === 'en' ? schoolUil_en : schoolUil_es);
        }
    }, [lng, schoolUil_en, schoolUil_es]);

    useEffect(() => {
        let url = process.env.REACT_APP_BASEPATH + '?';

        if (!!viewType) url += 'view=' + viewType + '&';
        if (viewType === 'school' || viewType === 'district') {
            url += 'id=' + viewId + '&';
            url += 'tab=' + viewTab + '&';
        }
        url += 'lng=' + (lng || 'en');

        navigate(url);
    }, [viewType, viewId, viewTab, lng]);

    useEffect(() => {
        fetch('./data/districts.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setDistricts(json);
            });
        fetch('./data/schools.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setSchools(json);
            });
        fetch('./data/srclists.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setSrclists_en(json);
            });
        fetch('./data/srclists_es.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setSrclists_es(json);
            });
        fetch('./data/school_programs.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolPrograms_en(json);
            });
        fetch('./data/school_programs_es.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolPrograms_es(json);
            });
        fetch('./data/school_ap.json?v=.0003')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolAp_en(json);
            });
        fetch('./data/school_ap_es.json?v=.0003')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolAp_es(json);
            });
        fetch('./data/school_uil.json?v=.0003')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolUil_en(json);
            });
        fetch('./data/school_uil_es.json?v=.0003')
            .then((resp) => resp.json())
            .then((json) => {
                setSchoolUil_es(json);
            });
        fetch('./data/las.json?v=.0004')
            .then((resp) => resp.json())
            .then((json) => {
                setLas(json);
            });
    }, []);

    const handleOnChangeLng = (newlng) => {
        setLng(newlng);
        setSrcLists(newlng.toLowerCase() === 'en' ? srclists_en : srclists_es);
        setSchoolPrograms(lng.toLowerCase() === 'en' ? schoolPrograms_en : schoolPrograms_es);
        setSchoolAp(lng.toLowerCase() === 'en' ? schoolAp_en : schoolAp_es);
        setSchoolUil(lng.toLowerCase() === 'en' ? schoolUil_en : schoolUil_es);
    };

    const resetPage = () => {
        setViewType(null);
        setViewId(null);
        setViewTab('overview');
    };

    const getPage = (viewType) => {
        switch (viewType) {
            case 'school':
                return (
                    <School
                        lng={lng}
                        school={schools.find((school) => school.id === viewId)}
                        schools={schools}
                        las={las}
                        tabId={viewTab}
                        programs={schoolPrograms.filter((program) => program.id === viewId)}
                        school_ap={schoolAp.filter((ap_course) => ap_course.id === viewId)}
                        school_uil={schoolUil.filter((uil_activity) => uil_activity.id === viewId)}
                        onChangeView={setViewType}
                        onChangeId={(id) => {
                            setViewId(id);
                            setViewTab('overview');
                        }}
                        onChangeTab={setViewTab}
                        onReset={resetPage}
                    />
                );
            case 'district':
                return (
                    <District
                        lng={lng}
                        district={districts.find((district) => district.id === viewId)}
                        tabId={viewTab}
                        schools={schools.filter((school) => school.district_id === viewId)}
                        srclists={srclists}
                        onChangeTab={setViewTab}
                        onReset={resetPage}
                    />
                );
            default:
                return (
                    <Home
                        viewType={viewType}
                        lng={lng}
                        schools={schools}
                        districts={districts}
                        srclists={srclists}
                        schoolPrograms={schoolPrograms}
                        schoolAp={schoolAp}
                        schoolUil={schoolUil}
                        onChangeView={setViewType}
                        onChangeId={setViewId}
                    />
                );
        }
    };

    return (
        <Container id='app_container' maxWidth={false} className={classes.appContainer} disableGutters={true}>
            <Container id='app_top' maxWidth={false} direction='column' className={classes.appTop}>
                <Header
                    lng={lng}
                    schools={schools}
                    districts={districts}
                    onChangeLng={handleOnChangeLng}
                    onChangeView={setViewType}
                    onChangeId={setViewId}
                    onReset={resetPage}
                    showSearch={viewType === 'school' || viewType === 'district'}
                />

                {getPage(viewType)}
            </Container>
            <Footer lng={lng} />
        </Container>
    );
}

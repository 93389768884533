import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    root: {},
}));

const CardFilterCheckBoxes = (props) => {
    const { title, onChange, filterNamez } = props;
    const [options, setOptions] = useState(props.options);
    const [selected, setSelected] = useState(props.selected);
    const classes = useStyles();

    useEffect(() => {
        const newoptions = Object.assign([], props.options);
        // set option "selected" true or false based on whether in "selected"
        for (let i = 0; i < newoptions.length; i++) {
            let match = false;
            for (let j = 0; j < props.selected.length; j++) {
                if (newoptions[i].label === props.selected[j].label) {
                    match = true;
                    break;
                }
            }
            if (match === true) {
                newoptions[i].selected = true;
            } else {
                delete newoptions[i].selected;
            }
        }
        setOptions(newoptions);
        setSelected(props.selected);
    }, [props.selected, props.options]);

    const handleChange = (event) => {
        let found = -1;
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].label === event.target.name) {
                found = i;
                break;
            }
        }
        if (found === -1) {
            // Insert if not found
            found = -1;
            for (let j = 0; j < options.length; j++) {
                if (options[j].label === event.target.name) {
                    found = j;
                    break;
                }
            }
            // Presume it matches
            const newoptions = options;
            newoptions[found].selected = true;
            setOptions(newoptions);
            const newvalues = selected.concat(options[found]);
            setSelected(newvalues);
            onChange({ selection: newvalues, filterName: props.filterName });
        } else {
            let found2 = -1;
            for (let j = 0; j < options.length; j++) {
                if (options[j].label === event.target.name) {
                    found2 = j;
                    break;
                }
            }
            const newoptions = Object.assign([], options);
            delete newoptions[found2].selected;
            setOptions(newoptions);
            const newvalues = selected;
            newvalues.splice(found, 1);
            setSelected(newvalues);
            onChange({ selection: newvalues, filterName: props.filterName });
        }
    };

    return (
        <FormControl className={classes.root}>
            <FormLabel style={{color: '#0D6CB9'}}>{title}</FormLabel>
            <FormGroup className={classes.root} row>
                {options.map((option, i) => {
                    return (
                        <FormControlLabel
                            key={'checkbox' + Math.random().toString()}
                            control={
                                <Checkbox
                                    checked={option.selected}
                                    color='primary'
                                    onChange={handleChange}
                                    name={option.label}
                                />
                            }
                            label={option.label}
                        />
                    );
                })}
            </FormGroup>
        </FormControl>
    );
};

export default CardFilterCheckBoxes;

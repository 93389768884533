import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { CircularProgress } from '@material-ui/core';
import PairedSchool from '../../components/PairedSchool';
import utils from '../../components/utils';
import '../../bootstrap_m.css';
import ChartjsAchievement from '../../components/chartjsAchievement';
import ChartjsAchievementccmr from '../../components/chartjsAchievementccmr';
import ChartjsAchievementgrad from '../../components/chartjsAchievementgrad';
import ChartjsAchievementcalc from '../../components/chartjsAchievementcalc';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
    },
    subHeader: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },

    subHeadera: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        backgroundColor: '#FFFFFF',
    },
    subHeadernr: {
        fontSize: '3rem',
    },

    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },

    frmszesm: {
        width: '100%',
        height: '10vh',
        border: '0',
    },

    frmszeb: {
        width: '100%',
        height: '70%',
        margin: 'auto',
        border: '0',
        minHeight: '16rem',
    },
    frmszebnr: {
        width: '100%',
        height: '58%',
        margin: 'auto',
        border: '0',
        minHeight: '14rem',
    },
    frmszec: {
        width: '100%',
        height: '85%',
        margin: 'auto',
        border: '0',
        minHeight: '19rem',
    },
    frmszed: {
        width: '100%',
        height: '85%',
        margin: 'auto',
        border: '0',
        minHeight: '19rem',
    },
    frmszea: {
        width: '100%',
        margin: '0',
        border: '0',
        height: '100%',
    },
    frmszeaa: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '85vh',
    },

    sectionbbb: {
        position: 'relative',
        height: '100vh',
    },

    gridItem: {
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        borderRadius: '.25rem',
        textAlign: 'center',
    },

    section: {
        color: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        margin: '1rem 0',
    },
    sectionTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.tertiary,
        fontSize: '1.5rem',
        padding: '.1rem',
    },
    sectionContent: {
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        fontSize: '1.25rem',
        padding: '.1rem',
    },
    sectionConpart: {
        margin: 'auto',
        backgroundColor: theme.palette.background.default,
        fontSize: '1rem',
    },
    sectionSub: {
        margin: 'auto',
        //   width: '95%',
        width: '250px',
        height: '80px',
        backgroundColor: theme.palette.background.default,
        fontSize: '1.25rem',
        padding: '1rem',
    },
    sectionButton: {
        marginTop: '1rem',
        width: '12rem',
    },

    perfGridItem: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        marginTop: '5px',
    },
    perfBox: {
        height: '100%',
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
    },
    perfWrapper: {},
    perfIcon: {
        height: '3.5rem',
        float: 'left',
        padding: '.5rem',
    },
    perfHeader: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.primary.main,
        minHeight: '4rem',
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.3rem',
    },
    perfSubHeader: {
        fontSize: '1.5rem',
        height: '3rem',
    },
    perfText: {
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
            minHeight: '5rem',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '5rem',
        },
    },
    perfButton: {
        marginBottom: '1rem',
        width: '8rem',
    },
    grade: {
        color: '#414a4c',
    },
    gradenr: {
        color: '#51555A',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    Gradeheader: {
        fontSize: '3rem',
        color: '#414a4c',
        padding: '40px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheader1: {
        fontSize: '3rem',
        color: '#414a4c',
        padding: '40px 0px 0px 36px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadera: {
        fontSize: '6rem',
        color: '#278143',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '6rem',
        color: '#39398C',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '6rem',
        color: '#9C118B',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '6rem',
        color: '#E0803D',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '6rem',
        color: '#CA313C',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.2rem',
        color: 'black',
        padding: '28px 0px 0px 32px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },
    grayHeader2: {
        backgroundColor: '#51555A',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.3rem',
        fontWeight: 'bold',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },

    mcontent: {
        height: '24rem',
    },
}));

export default ({ school, paired, onNav }) => {
    utils.setHomeTitle(!!school && school.name + ' | Student Achievement');
    const myClasses = useStyles();

    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
    }

    const [achieve, setAchieve] = useState([]);
    useEffect(() => {
        const jsonFile =
            lang === 'en' ? '../data/student_achievement_tab.json' : '../data/student_achievement_tab_es.json';
        fetch(jsonFile)
            .then((resp) => resp.json())
            .then((json) => {
                setAchieve(json);
            });
    }, [lang]);
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use school id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === school.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdstudent_achievement_rating = getContentId(dynModMatchedList, 'student_achievement_rating');

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    const handleClicka = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain1a.sas'
        );
    };
    const handleClickb = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain1b.sas'
        );
    };
    const handleClickc = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain1c.sas'
        );
    };

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [showach, setShowach] = useState(false);
    const handleCloseach = () => {
        setShowach(!showach);
    };
    // const handleShowach = () => setShowach(true);

    const [showwgt, setShowwgt] = useState(false);
    const handleClosewgt = () => {
        setShowwgt(!showwgt);
    };
    // const handleShowwgt = () => setShowwgt(true);

    const [showper, setShowper] = useState(false);
    const handleCloseper = () => {
        setShowper(!showper);
    };
    // const handleShowper = () => setShowper(true);

    const [showccm, setShowccm] = useState(false);
    const handleCloseccm = () => {
        setShowccm(!showccm);
    };
    // const handleShowccm = () => setShowccm(true);

    const [showgra, setShowgra] = useState(false);
    const handleClosegra = () => {
        setShowgra(!showgra);
    };
    // const handleShowgra = () => setShowgra(true);

    const perfBoxa = (imgSrc, title, subTitle, text, sectionId) => (
        <Box className={myClasses.perfBox}>
            {school.d1as !== null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        {school.d1as !== 100 ? (
                            <b id='grade' className={myClasses.Gradeheader}>
                                {school.d1as}
                            </b>
                        ) : (
                            <b id='grade' className={myClasses.Gradeheader1}>
                                {school.d1as}
                            </b>
                        )}
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={145}
                        />
                        <CircularProgress
                            className={myClasses.grade}
                            variant='determinate'
                            value={school.d1as}
                            size={145}
                        />
                    </div>
                    <span className={myClasses.subHeadera}>
                        <span className={myClasses.grade}>{lang === 'en' ? ' out of' : ' de'} 100</span>
                    </span>

                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}
            {school.d1ag === 'Not Rated' && school.d1as === null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        <b className={myClasses.subHeadernr}>
                            <center>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                        </b>
                    </div>
                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}
            {/* '53' staar_performance modal*/}
            <ModalEntity modalId={'53'} lang={lang} />
        </Box>
    );

    const perfBoxb = (imgSrc, title, subTitle, text, sectionbId) => (
        <Box className={myClasses.perfBox}>
            {school.d1bs !== null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        {school.d1bs !== 100 ? (
                            <b id='grade' className={myClasses.Gradeheader}>
                                {school.d1bs}
                            </b>
                        ) : (
                            <b id='grade' className={myClasses.Gradeheader1}>
                                {school.d1bs}
                            </b>
                        )}
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={145}
                        />
                        <CircularProgress
                            className={myClasses.grade}
                            variant='determinate'
                            value={school.d1bs}
                            size={145}
                        />
                    </div>
                    <span className={myClasses.subHeadera}>
                        <span className={myClasses.grade}>{lang === 'en' ? ' out of' : ' de'} 100</span>
                    </span>

                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}
            {school.d1bg === 'Not Rated' && school.d1bs === null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        <b className={myClasses.subHeadernr}>
                            <center> {lang === 'en' ? 'Not Rated' : 'No Clasificado'} </center>
                        </b>
                    </div>
                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}
            {/* '54' ccm_readiness modal*/}
            <ModalEntity modalId={'54'} lang={lang} />
        </Box>
    );

    const perfBoxc = (imgSrc, title, subTitle, text, sectioncId) => (
        <Box className={myClasses.perfBox}>
            {school.d1cs !== null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        {school.d1cs !== 100 ? (
                            <b id='grade' className={myClasses.Gradeheader}>
                                {school.d1cs}
                            </b>
                        ) : (
                            <b id='grade' className={myClasses.Gradeheader1}>
                                {school.d1cs}
                            </b>
                        )}

                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={145}
                        />
                        <CircularProgress
                            className={myClasses.grade}
                            variant='determinate'
                            value={school.d1cs}
                            size={145}
                        />
                    </div>
                    <span className={myClasses.subHeadera}>
                        <span className={myClasses.grade}>{lang === 'en' ? ' out of' : ' de'} 100</span>
                    </span>

                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}
            {school.d1cg === 'Not Rated' && school.d1cs === null ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                        <b className={myClasses.subHeadernr}>
                            <center> {lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                        </b>
                    </div>
                    <p className={myClasses.perfText}>{text}</p>
                </div>
            ) : null}

            {/* '55' graduation_rate modal*/}
            <ModalEntity modalId={'55'} lang={lang} />
        </Box>
    );

    const sectiona = (title, height, id) => (
        <Grid item>
            <div className={myClasses.section}>
                <div className={myClasses.grayHeader2}>
                    <img
                        src='./images/icon-clipboard_white.png'
                        alt='Browse school icon'
                        style={{ float: 'left', padding: '.5rem', height: '3rem' }}
                    />
                    <p style={{ padding: '.5rem' }}>{title}</p>
                </div>
                <div className={myClasses.sectionContent}>
                    <ChartjsAchievement entity={school} achieve={achieve} />

                    <Button
                        variant='contained'
                        color='primary'
                        className={myClasses.sectionButton}
                        onClick={handleClicka}
                    >
                        {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                    </Button>
                </div>
                <br />
            </div>
        </Grid>
    );

    const sectionb = (title, height, id) => (
        <Grid item>
            <div className={myClasses.section}>
                <div className={myClasses.grayHeader2}>
                    <img
                        src='./images/icon-browse-texas-school.png'
                        alt='Browse school icon'
                        style={{ float: 'left', padding: '.5rem', height: '3rem' }}
                    />
                    <p style={{ padding: '.5rem' }}>{title}</p>
                </div>
                <div className={myClasses.sectionContent}>
                    <ChartjsAchievementccmr entity={school} achieve={achieve} />
                    {/*   <iframe className={myClasses.frmszec}
                            src={
                                'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' + [window.ccyy] + '&id=' + school.id + '&prgopt=txs/stu_ccmr.sas' 
                                }
                        />
                      */}

                    <Button
                        variant='contained'
                        color='primary'
                        className={myClasses.sectionButton}
                        onClick={handleClickb}
                    >
                        {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                    </Button>
                </div>
                <br />
            </div>
        </Grid>
    );
    const sectionc = (title, height, id) => (
        <div className={myClasses.section}>
            <div className={myClasses.grayHeader2}>
                <img
                    src='./images/icon-diploma_white.png'
                    alt='Diploma icon'
                    style={{ float: 'left', padding: '.5rem', height: '3rem' }}
                />
                <p style={{ padding: '.5rem' }}>{title}</p>
            </div>
            <div className={myClasses.sectionContent}>
                <ChartjsAchievementgrad entity={school} achieve={achieve} />
                {/*                         <iframe className={myClasses.frmszed}
                            src={
                                'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' + [window.ccyy] + '&id=' + school.id + '&prgopt=txs/stu_grad_rate.sas' 
                                }
                        />
                      */}
                <Button variant='contained' color='primary' className={myClasses.sectionButton} onClick={handleClickc}>
                    {lang === 'en' ? 'Dig Into The Data' : 'Profundice En Los Datos'}
                </Button>
            </div>
            <br />
        </div>
    );

    return (
        <>
            {!!paired ? (
                <PairedSchool />
            ) : (
                <div>
                    <div className={myClasses.blueHeader}>
                        <img
                            src='./images/icon-school-achievement_white.png'
                            alt='Achievement icon'
                            style={{
                                height: '2.5rem',
                                marginRight: '.5rem',
                                marginTop: '.7rem',
                                marginBottom: '-.3rem',
                            }}
                        />{' '}
                        {lang === 'en' ? 'STUDENT ACHIEVEMENT' : 'LOGRO ESTUDIANTIL'}{' '}
                    </div>
                    {school.d1s !== null && school.d1g !== 'Not Rated' ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                                    <b id='gradea' className={myClasses['Gradeheader' + school.d1g.toLowerCase()]}>
                                        {school.d1g}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.d1g.toLowerCase()]}
                                        variant='determinate'
                                        value={school.d1s}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses.subHeadera}>
                                    <span className={myClasses['grade' + school.d1g.toLowerCase()]}>
                                        {' '}
                                        {school.d1s}
                                        {lang === 'en' ? '  out of' : ' de'} 100
                                    </span>
                                </span>
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Student Achievement measures whether students met expectations on the STAAR test. It also measures graduation rate and how prepared students are for success after high school.'
                                        : 'El Logro Estudiantil mide si los estudiantes cumplieron con las expectativas en el examen STAAR. También mide el porcentaje de graduados y qué tan preparados están los estudiantes para el éxito después de la escuela secundaria.'}
                                </p>
                                <ModalEntity modalId={mIdstudent_achievement_rating} lang={lang} />
                            </Grid>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en'
                                        ? 'Student Achievement Calculation'
                                        : 'Cálculo del Logro Estudiantil'}
                                </b>
                                <br />
                                <br />
                                <ChartjsAchievementcalc entity={school} achieve={achieve} />

                                <br />
                                {/* '52' student_achievement_weighting modal*/}
                                <ModalEntity modalId={'52'} lang={lang} />
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.d1s !== null && school.d1g === 'Not Rated' ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />

                                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado'}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={parseFloat(school.d1s, 10)}
                                        size={165}
                                    />
                                </div>
                                <b className={myClasses.subHeadera}>
                                    <span className={myClasses.gradenr}>
                                        {' '}
                                        {school.d1s}
                                        {lang === 'en' ? ' out of' : ' de'} 100
                                    </span>
                                </b>

                                <p style={{ fontSize: '1.2rem' }}>
                                    <img
                                        src='./images/info_icon_navy.png'
                                        alt='not rated school icon'
                                        style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                    />
                                    &nbsp;{' '}
                                    {lang === 'en'
                                        ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                        : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                                    <br />
                                </p>
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Student Achievement measures whether students met expectations on the STAAR test. It also measures graduation rate and how prepared students are for success after high school.'
                                        : 'El Logro Estudiantil mide si los estudiantes cumplieron con las expectativas en el examen STAAR. También mide el porcentaje de graduados y qué tan preparados están los estudiantes para el éxito después de la escuela secundaria.'}
                                </p>
                                <ModalEntity modalId={mIdstudent_achievement_rating} lang={lang} />
                            </Grid>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en'
                                        ? 'Student Achievement Calculation'
                                        : 'Cálculo del Logro Estudiantil'}
                                </b>
                                <br />
                                <br />
                                <ChartjsAchievementcalc entity={school} achieve={achieve} />
                                {/*                             <iframe className={myClasses.frmszeb}
                            src={
                                'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' + [window.ccyy] + '&id=' + school.id + '&prgopt=txs/stu_achive_calc.sas' 
                                }
                        /> */}

                                <br />
            {/* '52' student_achievement_weighting modal*/}
            <ModalEntity modalId={'52'} lang={lang} />
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.d1g === 'Not Rated' && school.d1s === null ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <br />
                                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                                    <b className={myClasses.subHeadernr}>
                                        {' '}
                                        <center>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                    </b>{' '}
                                </div>
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Student Achievement measures whether students met expectations on the STAAR test. It also measures graduation rate and how prepared students are for success after high school.'
                                        : 'El Logro Estudiantil mide si los estudiantes cumplieron con las expectativas en el examen STAAR. También mide el porcentaje de graduados y qué tan preparados están los estudiantes para el éxito después de la escuela secundaria.'}
                                </p>
                                <br />
                                <br />
                                <ModalEntity modalId={mIdstudent_achievement_rating} lang={lang} />
                            </Grid>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en'
                                        ? 'Student Achievement Calculation'
                                        : 'Cálculo del Logro Estudiantil'}
                                </b>
                                <br />
                                <br />
                                <ChartjsAchievementcalc entity={school} achieve={achieve} />

                                <br />
                                {/* '52' student_achievement_weighting modal*/}
                                <ModalEntity modalId={'52'} lang={lang} />
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.student_achievement[0] === 'Y' ||
                    school.student_achievement[1] === 'Y' ||
                    school.student_achievement[2] === 'Y' ||
                    school.d1as !== null ||
                    school.d1bs !== null ||
                    school.d1cs !== null ? (
                        <div className={myClasses.grayHeader}>
                            <img
                                src='./images/students_profile_white.png'
                                alt='Student profile icon'
                                style={{
                                    height: '1.75rem',
                                    marginLeft: '1rem',
                                    marginTop: '.4rem',
                                    marginRight: '.7rem',
                                }}
                            />
                            {lang === 'en'
                                ? 'Student Achievement Summary'
                                : 'Resumen del Desempeño del Logro Estudiantil'}
                        </div>
                    ) : null}

                    <Grid container spacing={1}>
                        {school.student_achievement[0] === 'Y' || school.d1as !== null ? (
                            <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                                {perfBoxa(
                                    ' ',
                                    lang === 'en' ? 'STAAR Performance' : 'Desempeño STAAR',
                                    ' ',
                                    lang === 'en'
                                        ? 'STAAR Performance measures achievement at Approaches Grade Level or above, Meets Grade Level or above, and Masters Grade Level on the STAAR test.'
                                        : 'El desempeño de STAAR mide el logro de Enfoques De Nivel de Grado o superior, Cumple con el Nivel de Grado o superior, y Nivel de Grado de Maestría en el examen STAAR.',
                                    'school_achievement_perf1'
                                )}
                            </Grid>
                        ) : null}
                        {school.student_achievement[1] === 'Y' || school.d1bs !== null ? (
                            <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                                {perfBoxb(
                                    'icon-browse-texas-school.png',
                                    lang === 'en'
                                        ? 'College, Career, and Military Readiness'
                                        : 'Preparación para la Universidad, la Carrera y las Fuerzas Armadas',
                                    ' ',
                                    lang === 'en'
                                        ? "College, Career, and Military Readiness measures graduates' readiness for college, the workforce, or the military."
                                        : 'La preparación para la universidad, la carrera y el ejército mide la preparación de los graduados para la universidad, la fuerza laboral o el ejército.',
                                    'school_achievement_ccmr_table'
                                )}
                            </Grid>
                        ) : null}
                        {school.student_achievement[2] === 'Y' || school.d1cs !== null ? (
                            <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                                {perfBoxc(
                                    'icon-diploma_white.png',
                                    lang === 'en' ? 'Graduation Rate' : 'Porcentaje de Graduados',
                                    ' ',
                                    lang === 'en'
                                        ? 'Graduation Rate measures the percentage of students who graduate in 4, 5, or 6 years.'
                                        : 'El Porcentaje de Graduados miden el porcentaje de estudiantes que se gradúan en 4, 5 o 6 años.',
                                    'school_achievement_grad_table'
                                )}
                            </Grid>
                        ) : null}
                    </Grid>

                    <Grid container>
                        {school.student_achievement[0] === 'Y' || school.d1as !== null ? (
                            <Grid item xs={12}>
                                {sectiona(
                                    lang === 'en' ? 'STAAR Performance Details' : 'Detalles de Desempeño de STAAR',
                                    '31rem',
                                    'Student Achievement table'
                                )}
                            </Grid>
                        ) : null}
                    </Grid>

                    <Grid container spacing={1}>
                        {school.student_achievement[1] === 'Y' || school.d1bs !== null ? (
                            <Grid item xs={12} md={8}>
                                {' '}
                                <span id='sctb'>
                                    {sectionb(
                                        lang === 'en'
                                            ? 'College, Career, and Military Readiness Details'
                                            : 'Detalles de la Preparación para la Universidad, la Carrera y las Fuerzas Armadas',
                                        '23rem',
                                        'school_achievement_ccmr_table'
                                    )}
                                </span>
                            </Grid>
                        ) : null}

                        {school.student_achievement[2] === 'Y' || school.d1cs !== null ? (
                            <Grid item xs={12} md={4}>
                                {' '}
                                <span id='sctc'>
                                    {sectionc(
                                        lang === 'en'
                                            ? 'Graduation Rate Details'
                                            : 'Detalles de Porcentaje de Graduados',
                                        '13rem',
                                        'school_achievement_grad_table'
                                    )}
                                </span>
                            </Grid>
                        ) : null}
                    </Grid>
                </div>
            )}
        </>
    );
};

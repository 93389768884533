import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import utils from '../../components/utils';
import { Modal } from 'react-bootstrap';
import '../../bootstrap_m.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ModalEntity from '../../components/modalEntity';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, ChartDataLabels, Legend);
const useStyles = makeStyles((theme) => ({
    blueFunds: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        padding: '25px 5px ',
    },
    blueChartHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        margin: '5px 5%',
        padding: '2px 2px',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },
    grayHeader: {
        backgroundColor: '#5a6267',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        marginBottom: '.7rem',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    perfBoxr: {
        textAlign: 'center',
        borderRight: '.1px solid ' + theme.palette.primary.main,
    },
    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },
    frmszea: {
        marginTop: '3rem',
        width: '100%',
        height: '40%',
        border: '0',
    },
    frmszeaa: {
        width: '100%',
        height: '87%',
        border: '0',
        minHeight: '57vh',
    },
    secbutton: {
        marginBottom: '1rem',
        marginLeft: '8rem',
    },
    secbuttona: {
        marginTop: '1rem',
        marginLeft: '6rem',
    },
    textAlign: {
        textAlign: 'center',
    },
}));
export default ({ school, paired }) => {
    utils.setHomeTitle(!!school && school.name + ' | Finance');
    const myClasses = useStyles();
    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }
    const _Level = lang === 'en' ? 'Level' : 'Nivel';
    const _School = lang === 'en' ? 'School' : 'Escuela';
    const _District = lang === 'en' ? 'District' : 'Distrito';
    const _State = lang === 'en' ? 'State' : 'Estado';
    const [finance, setFinance] = useState([]);
    useEffect(() => {
        fetch('../data/finance_school.json')
            .then((resp) => resp.json())
            .then((json) => {
                setFinance(json);
            });
    }, []);
    // use school id to filter out data.
    const financeMatchedList = finance.filter((item) => {
        return item.id === school.id;
    });
    const checkMatchedList = financeMatchedList.length === 0;
    const dataexpenditures_allListSchool = checkMatchedList ? null : financeMatchedList[0].expenditures_all;
    const labelsYear = checkMatchedList ? [null, null, null, null] : financeMatchedList[0].year;
    const dataexpenditure_schoolListSchool = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_school;
    const dataexpenditure_districtListSchool = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_district;
    const dataexpenditure_stateListSchool = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_state;
    const data = {
        labels: labelsYear,
        datasets: [
            {
                label: _School,
                data: dataexpenditure_schoolListSchool,
                color: '#2470AD',
                borderColor: '#2470AD',
                backgroundColor: '#2470AD',
                datalabels: {
                    labels: {
                        value: {
                            color: '#2470AD',
                        },
                    },
                },
            },
            {
                label: _District,
                data: dataexpenditure_districtListSchool,
                borderColor: '#ECAF33',
                backgroundColor: '#ECAF33',
                datalabels: {
                    labels: {
                        value: {
                            color: '#ECAF33',
                        },
                    },
                },
            },
            {
                label: _State,
                data: dataexpenditure_stateListSchool,
                borderColor: '#548235',
                backgroundColor: '#548235',
                datalabels: {
                    labels: {
                        value: {
                            color: '#548235',
                        },
                    },
                },
            },
        ],
    };
    // line chart setup
    const options = {
        radius: 5,
        pointStyle: 'circle',
        responsive: true,
        layout: {
            padding: {
                top: 20,
                left: 100,
                right: 100,
            },
        },
        scales: {
            x: {
                backgroundColor: 'white',
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                suggestedMin: 2000,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let labelLevel = 'Level:   ';
                        let labelExpenditure = 'Per Student:   ';
                        if (context.dataset.label !== null) {
                            labelLevel = 'Level:   ' + context.dataset.label;
                        }
                        if (context.parsed.y !== null) {
                            labelExpenditure =
                                labelExpenditure + '$' + new Intl.NumberFormat().format(context.parsed.y);
                        }
                        return [labelLevel, 'Financial Type: Expenditure', labelExpenditure];
                    },
                },
                displayColors: false,
                titleColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white',
            },
            legend: {
                title: {
                    display: true,
                    text: _Level,
                },
                labels: {
                    usePointStyle: true,
                    PointStyle: 'line',
                },
                position: 'bottom',
                onClick: function (e, legendItem) {
                    const index = legendItem.datasetIndex;
                    const ci = this.chart;
                    ci.data.datasets.forEach(function (e, i) {
                        const meta = ci.getDatasetMeta(i);
                        if (i !== index) {
                            ci.data.datasets[i].radius = 5;
                        } else if (i === index) {
                            ci.data.datasets[i].radius = 10;
                        }
                    });
                    ci.update();
                },
            },
            datalabels: {
                display: 'auto',
                clamp: true,
                anchor: 'center',
                align: 'top',
                formatter: (val) => `\$${Intl.NumberFormat().format(val)}`,
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
        },
    };
    //  other setup ------------------------
    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
    }
    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }
    const packurl3 = process.env.REACT_APP_BASEPATH + '/reports/finance';
    const handleClicka = () => {
        window.open(
            'https://rptsvr1.tea.texas.gov/cgi/sas/broker?_service=marykay&_program=sfadhoc.new_Campus_actual21.sas&_service=appserv&_debug=0&which_camp=' +
                school.id
        );
    };
    const [showret, setShowret] = useState(false);
    const handleCloseret = () => {
        setShowret(!showret);
    };
    const [showres, setShowres] = useState(false);
    const handleCloseres = () => {
        setShowres(!showres);
    };
    // {lang === 'en' ? '' : ''}
    return (
        <Paper style={{ padding: '.1rem' }}>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/finance_white.png'
                    alt='Finance icon'
                    style={{ height: '2.5rem', marginRight: '.5rem', marginTop: '.2rem' }}
                />{' '}
                {lang === 'en' ? 'FINANCE ' : 'FINANZAS'}
            </div>
            <Grid container alignItems='center' spacing={1}>
                <Grid item xs={12} md={4} className={myClasses.perfBoxr}>
                    <b style={{ fontSize: '1.3rem' }}>
                     {lang === 'en' ? 'Expenditures - All Funds' : 'Gastos – Todos los fondos'}
                    </b>
                    <div className={myClasses.blueFunds}>
                        ${Intl.NumberFormat().format(dataexpenditures_allListSchool)}
                    </div>
                    <ModalEntity entity={school} modalId='136' lang={lang} />
                </Grid>
                <Grid item xs={12} md={8}>
                    <span className={myClasses.textAlign}>
                        <h3>{lang === 'en' ? 'Per Student Averages' : 'Promedios por Estudiante '}</h3>
                    </span>
                    <br />
                    <div className={myClasses.blueChartHeader}> {lang === 'en' ? 'Expenditure' : 'Gasto'}</div>
                    <br />
                    <Line options={options} data={data} />
                    <br />
                    {/* <span className={myClasses.secbutton}> */}
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ModalEntity entity={school} modalId='134' lang={lang} />
                        <span style={{ marginLeft: '8px' }}>
                            {' '}
                            <Button
                                variant='contained'
                                color='primary'
                                className={myClasses.sectionButton}
                                onClick={handleClicka}
                            >
                                {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                            </Button>
                        </span>
                    </div>
                </Grid>
            </Grid>
        </Paper>
    );
};

import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    LineController,
    BarController,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    PointElement,
    Tooltip,
    LineController,
    BarController,
    Legend,
    annotationPlugin
);

const options = (boxData) => {
    const dataMin = 0;
    const dataMax = 100;
    const dataC = boxData[0];
    const dataB = boxData[1];
    const dataA = boxData[2];
    const dataScore = boxData[3];
    const barColors = ['#4F5A65', '#9C118B', '#39398C', '#278143'];
    const barFontSize = 18;
    const textFontSize = 14;
    return {
        responsive: true,
        layout: {
            padding: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 10,
            },
        },
        indexAxis: 'y',
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
                max: 105,
                beginAtZero: true,
                min: -5,
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            title: {
                display: false,
                text: '',
            },
            annotation: {
                annotations: {
                    textMin: {
                        type: 'label',
                        content: [dataMin],
                        align: 'center',
                        xValue: dataMin,
                        yValue: 3.5,
                        font: {
                            size: textFontSize,
                        },
                    },
                    textMax: {
                        type: 'label',
                        content: [dataMax],
                        align: 'center',
                        xValue: dataMax,
                        yValue: 3.5,
                        font: {
                            size: textFontSize,
                        },
                    },
                    textC: {
                        type: 'label',
                        content: [dataC],
                        align: 'center',
                        xValue: dataC,
                        yValue: 3.5,
                        font: {
                            size: textFontSize,
                        },
                    },
                    textB: {
                        type: 'label',
                        content: [dataB],
                        align: 'center',
                        xValue: dataB,
                        yValue: 3.5,
                        font: {
                            size: textFontSize,
                        },
                    },
                    textA: {
                        type: 'label',
                        content: [dataA],
                        align: 'center',
                        xValue: dataA,
                        yValue: 3.5,
                        font: {
                            size: textFontSize,
                        },
                    },
                    boxNR: {
                        type: 'box',
                        xMin: 0,
                        xMax: dataC,
                        yMin: 1.25,
                        yMax: 2.75,
                        borderColor: 'black',
                        backgroundColor: barColors[0],
                        label: {
                            display: true,
                            color: 'white',
                            content: 'Not Rated',
                            font: {
                                size: barFontSize,
                            },
                            position: {
                                x: 'center',
                                y: 'center',
                            },
                        },
                    },
                    boxC: {
                        type: 'box',
                        xMin: dataC,
                        xMax: dataB,
                        yMin: 1.25,
                        yMax: 2.75,
                        borderColor: 'black',
                        backgroundColor: barColors[1],
                        label: {
                            display: true,
                            color: 'white',
                            content: 'C',
                            font: {
                                size: barFontSize,
                            },
                            position: {
                                x: 'center',
                                y: 'center',
                            },
                        },
                    },
                    boxB: {
                        type: 'box',
                        xMin: dataB,
                        xMax: dataA,
                        yMin: 1.25,
                        yMax: 2.75,
                        borderColor: 'black',
                        backgroundColor: barColors[2],
                        label: {
                            display: true,
                            color: 'white',
                            content: 'B',
                            font: {
                                size: barFontSize,
                            },
                            position: {
                                x: 'center',
                                y: 'center',
                            },
                        },
                    },
                    boxA: {
                        type: 'box',
                        xMin: dataA,
                        xMax: dataMax,
                        yMin: 1.25,
                        yMax: 2.75,
                        borderColor: 'black',
                        backgroundColor: barColors[3],
                        label: {
                            display: true,
                            color: 'white',
                            content: 'A',
                            font: {
                                size: barFontSize,
                            },
                            position: {
                                x: 'center',
                                y: 'center',
                            },
                        },
                    },
                    textScore: {
                        type: 'label',
                        content: [dataScore],
                        xValue: dataScore,
                        yValue: 0.5,
                        font: {
                            size: 24,
                            weight: 'bold',
                        },
                        color: barColors[0 + (dataScore >= dataC) + (dataScore >= dataB) + (dataScore >= dataA)],
                    },
                    lineScore: {
                        type: 'line',
                        xMin: dataScore,
                        xMax: dataScore,
                        yMin: 0.9,
                        yMax: 3.1,
                        borderColor: 'black',
                        borderWidth: 2,
                    },
                    lineBelow: {
                        type: 'line',
                        xMin: 20,
                        xMax: 80,
                        yMin: 5.7,
                        yMax: 5.7,
                        borderColor: '#704280',
                        borderWidth: 4,
                    },
                },
            },
        },
    };
};
const labelsData = ['', '', '', '', '', '', ''];
const data = {
    labels: labelsData,
    datasets: [],
};
export default function ChartjsSTAAR({ boxData }) {
    const optionsData = options(boxData);
    return <Bar options={optionsData} data={data} />;
}

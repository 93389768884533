import React, { createElement, useState, useEffect, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Grid, Typography, Button, Hidden, Card } from '@material-ui/core';
import Footer from '../components/footer';
import Header from '../components/header';
import CardFilterAutosuggest from '../components/cardFilterAutosuggest';
import CardAddressFilter from '../components/cardAddressFilter';
import CardsFilters from '../components/cardsFilters';
import SASStateMap from '../components/sasStateMap';
import CardsList from '../components/cardsList';
import utils from '../components/utils';
import TexasAssessment from '../components/texasAssessment';
import geodist from 'geodist';
import { isFirefox, browserVersion } from 'react-device-detect';
import TxsMap1 from '../components/TxsMap1';

const useStyles = makeStyles((theme) => ({
    introRoot: {
        margin: '0 1rem',
    },
    searchSection: {
        backgroundImage: "url('./images/home_purple_bar.png')",
        color: 'white',
        padding: '1rem',
        borderRadius: '.25rem',
    },
    intro1: {
        fontSize: '2em',
        marginBottom: '1rem',
    },
    intro2: {
        fontSize: '1.2em',
    },
    resourceButton: {
        backgroundColor: 'white',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: '2px 2px 2px',
        },
        borderWidth: '2px',
        padding: '.5rem 0 1rem 1rem',
        borderRadius: '.25rem',

        height: '100%',
        width: '100%',
        color: theme.palette.primary.main,
    },
    resourceButton2: {
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:hover': {
            borderColor: theme.palette.primary.main,
            //   border: '1px solid',
        },
        height: '100%',
        width: '100%',
        color: theme.palette.primary.main,
    },
    resourceTitle: {
        textAlign: 'center',
        marginBottom: '.5rem',
        fontSize: '1.35rem',
    },
    resourceText: {
        textAlign: 'left',
        color: theme.palette.text.primary,
    },
    overlay: {
        position: 'absolute',
        objectFit: 'cover',
        width: '37.5%',
        marginLeft: '45rem',
    },
}));

const MAX_SCHOOLS_IN_MAP = 1000;
const DEFAULT_MILES_SEARCH = 10;

const Home = ({
    viewType,
    schools,
    districts,
    srclists,
    schoolPrograms,
    schoolAp,
    schoolUil,
    lng,
    onChangeView,
    onChangeId,
}) => {
    utils.setHomeTitle('Texas Education Agency');

    const myClasses = useStyles();

    const [initialLoad, setInitialLoad] = useState(!viewType);

    const [allEntities, setAllEntities] = useState([]);
    const [entities, setEntities] = useState([]);

    const [selectedSchool, setSelectedSchool] = useState(null);
    const [milesSearch, setMilesSearch] = useState(DEFAULT_MILES_SEARCH);
    const [filters, setFilters] = useState({});
    const [homeAddress, setHomeAddress] = useState(null);
    const [postFiltered, setPostFiltered] = useState([]);

    useEffect(() => {
        setInitialLoad(!viewType);

        if (!!viewType && !!schools && !!districts) {
            setAllEntities(viewType === 'schools' ? schools : districts);
            setEntities(viewType === 'schools' ? schools : districts);
        }

        setFilters({});

        if (viewType !== 'schools') {
            setSelectedSchool(null);
            setHomeAddress(null);
            setMilesSearch(DEFAULT_MILES_SEARCH);
        }
    }, [
        viewType,

        schools,
        districts,
        setEntities,
        setSelectedSchool,
        setFilters,
        setHomeAddress,
        setMilesSearch,
        setInitialLoad,
    ]);

    const getProximateSchools = useCallback(
        (homeItem) => {
            const _lat = homeItem.latitude || homeItem.location.y;
            const _long = homeItem.longitude || homeItem.location.x;

            const closeSchools = schools.filter((school) => {
                return (
                    geodist({ lat: school.latitude, lon: school.longitude }, { lat: _lat, lon: _long }) <= milesSearch
                );
            });

            return closeSchools;
        },
        [milesSearch, schools]
    );

    useEffect(() => {
        // First clean out empty filters
        Object.keys(filters).map((key) => {
            if (filters[key].length === 0) {
                delete filters[key];
            }
        });

        // Need to translate column to filter and discard empty filters TBD
        const filterValues = [];
        Object.keys(filters).map((item) => {
            switch (item) {
                case 'grades':
                    // Complex function to first translate the grades to numbers and then see if any grade matches the range
                    filterValues.push([
                        'grades',
                        (data_item) => {
                            const grades = filters[item].map((item2) => item2.value);
                            const grade2num = {
                                EE: -2,
                                PK: -1,
                                KG: 0,
                                '01': 1,
                                '02': 2,
                                '03': 3,
                                '04': 4,
                                '05': 5,
                                '06': 6,
                                '07': 7,
                                '08': 8,
                                '09': 9,
                                10: 10,
                                11: 11,
                                12: 12,
                            };
                            const first = grade2num[data_item.low_grade_cd];
                            const last = grade2num[data_item.high_grade_cd];
                            let match = false;
                            for (let i = 0; i < grades.length; i++) {
                                const thisgrade = grade2num[grades[i]];
                                if (thisgrade <= last && thisgrade >= first) {
                                    match = true;
                                    break;
                                }
                            }
                            return match;
                        },
                    ]);
                    break;
                case 'school':
                    filterValues.push(['id', filters[item].map((item2) => item2.value)]);
                    break;
                case 'virtual':
                    filterValues.push(['online_school', [!!filters[item] ? 'Yes' : '']]);
                    break;
                case 'alternative':
                    filterValues.push(['alt_standards', [!!filters[item] ? 'Yes' : '']]);
                    break;
                case 'region_id':
                    filterValues.push(['region_id', filters[item].map((item2) => item2.value)]);
                    break;
                case 'districts':
                    filterValues.push(['district_id', filters[item].map((item2) => item2.value)]);
                    break;
                case 'entity_type':
                    filterValues.push(['entity_type_cd', filters[item].map((item2) => item2.value)]);
                    break;
                case 'designations':
                    filterValues.push([
                        'designations',
                        (data_item) => {
                            const designations = filters[item].map((item2) => item2.value);
                            const this_school = data_item.designation_cd.split(',');
                            let match = false;
                            for (let i = 0; i < this_school.length; i++) {
                                if (designations.indexOf(this_school[i]) > -1) {
                                    match = true;
                                    break;
                                }
                            }
                            return match;
                        },
                    ]);
                    break;
                case 'type_cats':
                    filterValues.push([
                        'type_cats',
                        (data_item) => {
                            const type_cats = filters[item].map((item2) => item2.value);
                            const this_school = data_item.type_cat_cd.split(',');
                            let match = false;
                            for (let i = 0; i < this_school.length; i++) {
                                if (type_cats.indexOf(this_school[i]) > -1) {
                                    match = true;
                                    break;
                                }
                            }
                            return match;
                        },
                    ]);
                    break;
                case 'school_programs':
                    const list = viewType === 'schools' ? schools : districts;
                    const selected_schoolPrograms = filters[item].map((item2) => item2.value);
                    let selected_IDs = {};
                    schoolPrograms.forEach((item) => {
                        if (selected_schoolPrograms.indexOf(item.program_cd) > -1) {
                            selected_IDs[item.id] = 1;
                        }
                    });
                    selected_IDs = Object.keys(selected_IDs);
                    filterValues.push(['id', selected_IDs]);
                    break;

                case 'school_ap':
                    const listap = viewType === 'schools' ? schools : districts;
                    const selected_schoolAp = filters[item].map((item2) => item2.value);
                    let selected_apIDs = {};
                    schoolAp.forEach((item) => {
                        if (selected_schoolAp.indexOf(item.ap_code) > -1) {
                            selected_apIDs[item.id] = 1;
                        }
                    });
                    selected_apIDs = Object.keys(selected_apIDs);
                    filterValues.push(['id', selected_apIDs]);
                    break;

                case 'school_uil':
                    const listuil = viewType === 'schools' ? schools : districts;
                    const selected_schoolUil = filters[item].map((item2) => item2.value);
                    let selected_uilIDs = {};
                    schoolUil.forEach((item) => {
                        if (selected_schoolUil.indexOf(item.uil_code) > -1) {
                            selected_uilIDs[item.id] = 1;
                        }
                    });
                    selected_uilIDs = Object.keys(selected_uilIDs);
                    filterValues.push(['id', selected_uilIDs]);
                    break;

                case 'sat_act':
                    filterValues.push([
                        'sat_act',
                        (data_item) => {
                            const sat_act = filters[item].map((item2) => item2.value);
                            const this_school = data_item.sat_act_cd.split(',');
                            let match = false;
                            for (let i = 0; i < this_school.length; i++) {
                                if (sat_act.indexOf(this_school[i]) > -1) {
                                    match = true;
                                    break;
                                }
                            }
                            return match;
                        },
                    ]);
                    break;

                default:
                    filterValues.push([item, filters[item].map((item2) => item2.value)]);
                    break;
            }
        });

        let toFilter = districts;
        if (viewType === 'schools') {
            /* if a pin was clicked, or the address is input,  then prefilter the list by proximity */
            toFilter = !!homeAddress ? getProximateSchools(homeAddress) : schools;
        }
        const filteredEntities = filterValues.length === 0 ? toFilter : utils.advanced_filter(toFilter, filterValues);

        setEntities(filteredEntities);
    }, [
        filters,
        milesSearch,
        homeAddress,
        initialLoad,
        districts,
        viewType,
        schools,
        schoolPrograms,
        schoolAp,
        schoolUil,
        getProximateSchools,
    ]);

    const handleAddressChange = (newAddr) => {
        if (!viewType) onChangeView('schools');
        setSelectedSchool(null);
        setHomeAddress(newAddr);
    };
    const handleFiltersChange = (newFilters) => {
        setSelectedSchool(null);
        setFilters(newFilters);
    };

    const zoomMap = (id, type) => {
        const homeItem = (type === 'school' ? schools : districts).find((obj) => obj.id === id);
        setSelectedSchool(homeItem);
    };

    const handleSelectEntity = (entityObj) => {
        onChangeView(entityObj.filterName);
        onChangeId(entityObj.selection.id);
    };

    const searchButton = (btnAction, bgImage, text) => {
        const btnProps =
            typeof btnAction === 'string'
                ? {
                      onClick: () => {
                          window.open(btnAction, '_blank');
                      },
                  }
                : { onClick: btnAction };

        return (
            <div style={{ textAlign: 'center' }}>
                <Link component='button' {...btnProps}>
                    <div
                        style={{
                            margin: 'auto',
                            background: "url('./images/" + bgImage + "') 0 0",
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '35px 35px',
                            height: '35px',
                            width: '35px',
                        }}
                    />
                    <br />
                    <span style={{ marginTop: '8px', fontWeight: '500', color: 'white' }} color='textPrimary'>
                        {text}
                    </span>
                </Link>
            </div>
        );
    };

    const searchButton2 = (btnAction, text) => {
        const btnProps =
            typeof btnAction === 'string'
                ? {
                      onClick: () => {
                          window.open(btnAction, '_blank');
                      },
                  }
                : { onClick: btnAction };

        return (
            <Link {...btnProps} className={myClasses.resourceButton2}>
                {text}
            </Link>
        );
    };

    const searchButton3 = (text) => {
        const btnProps = {
            onClick: () => {
                window.open('https://tea4svtxsdev.tea.state.tx.us/perfreport/account/va/school-compare.html', '_blank');
            },
        };
        return (
            <Link {...btnProps} className={myClasses.resourceButton2}>
                {text}
            </Link>
        );
    };

    const searchButton4 = (text) => {
        const btnProps = {
            onClick: () => {
                window.open('https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html', '_blank');
            },
        };
        return (
            <Link {...btnProps} className={myClasses.resourceButton2}>
                {text}
            </Link>
        );
    };

    const resourceButton = (href, title, text, img) => (
        <Link
            component='button'
            onClick={() => {
                window.open(href, '_blank');
            }}
            className={myClasses.resourceButton}
        >
            <Typography className={myClasses.resourceTitle}>{title}</Typography>
            <img src={'./images/' + img} width='190px' height='151px' alt='resource button' />
            <Typography className={myClasses.resourceText}>{text}</Typography>
        </Link>
    );

    const resourceButtonS = (title, text, img) => (
        <Link
            component='button'
            onClick={() => {
                onChangeView('schools');
            }}
            className={myClasses.resourceButton}
        >
            <Typography className={myClasses.resourceTitle}>{title}</Typography>
            <img src={'./images/' + img} width='200px' alt='resource title' />
            <Typography className={myClasses.resourceText}>{text}</Typography>
        </Link>
    );

    const ccyy = '2022';

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://vyuprodva.ondemand.sas.com';
    }
    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools.gov')) {
        vaserver = 'https://viya.txschools.gov';
    }
    if (window.location.href.includes('txschools.org')) {
        vaserver = 'https://viya.txschools.gov';
    }

    return (
        <>
            {!!isFirefox && browserVersion < 97 ? (
                <p
                    style={{
                        fontSize: '1.5rem',
                        fontStyle: 'bold',
                        margin: 0,
                        borderColor: 'red',
                        borderWidth: '5px',
                        borderStyle: 'solid',
                        textAlign: 'center',
                    }}
                >
                    This website requires the latest version of Firefox to function properly. Please update or switch to
                    Chrome.
                </p>
            ) : null}
            {!!srclists.grades && !!schools.length && !!districts.length ? (
                !!initialLoad ? (
                    <div className={myClasses.introRoot}>
                        <Hidden mdDown>
                            {' '}
                            <img className={myClasses.overlay} src='./images/home_texasmap_pins4.png' />
                        </Hidden>
                        <Grid container>
                            <Grid item md={1}></Grid>
                            <Hidden smDown>
                                <Grid item xs={12} md={5}>
                                    <div style={{ fontWeight: 'bold', color: '#704280' }}>
                                        <span style={{ fontSize: '3rem' }}>
                                            {lng === 'en' ? 'Learn more about your' : 'Aprende mas acerca de su'}
                                        </span>
                                        <span style={{ lineHeight: '1rem' }}>
                                            {' '}
                                            <br />
                                        </span>
                                        <span style={{ fontSize: '3rem' }}>
                                            {lng === 'en' ? 'school or district' : 'escuela o distrito'}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '1.3rem', color: 'black', fontWeight: 'normal' }}>
                                            {lng === 'en'
                                                ? 'Discover how your school and district are preparing'
                                                : 'Descubra cómo su escuela y su distrito están preparando'}
                                        </span>
                                        <br />
                                        <span style={{ fontSize: '1.3rem', color: 'black', fontWeight: 'normal' }}>
                                            {lng === 'en' ? 'your child for the future.' : 'a su hijo para el futuro.'}
                                        </span>
                                    </div>
                                </Grid>
                            </Hidden>
                            <Grid item xs={12} md={6} style={{ margin: 'auto' }}></Grid>
                        </Grid>

                        <br />

                        <Grid container style={{ margin: '1rem 0 .5rem 0' }}>
                            <Grid item xs={12} style={{ margin: 'auto' }} className={myClasses.searchSection}>
                                <Grid item xs={12} md={5}>
                                    {!!schools.length && !!districts.length ? (
                                        <CardFilterAutosuggest
                                            placeholder={
                                                lng === 'en'
                                                    ? 'Search by School or District Name'
                                                    : 'Búsqueda por Nombre de la Escuela o por Nombre del Distrito'
                                            }
                                            onChange={handleSelectEntity}
                                            lng={lng}
                                            suggestions={schools
                                                .concat(districts)
                                                .sort((a, b) => a.name.localeCompare(b.name))}
                                        />
                                    ) : null}
                                </Grid>
                                <Grid item xs={12} md={7}></Grid>
                                <br />{' '}
                                <span style={{ marginLeft: '2rem', fontSize: '1.3rem' }}>
                                    {' '}
                                    {lng === 'en' ? 'or' : 'o'}{' '}
                                </span>{' '}
                                <br />
                                <br />
                                <Grid item xs={12} md={5}>
                                    <CardAddressFilter
                                        placeholder={
                                            lng === 'en'
                                                ? 'Search by Address, City, or Zip Code'
                                                : 'Búsqueda por Dirección, Ciudad o Código Postal'
                                        }
                                        onChange={handleAddressChange}
                                        initialVal={!!homeAddress ? homeAddress.address : ''}
                                    />
                                </Grid>
                                <Grid item xs={12} md={7}></Grid>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} style={{ borderRadius: '.25rem', margin: '2rem 0 .5rem 0' }}>
                            <Grid item xs={12} md={3}>
                                {resourceButton(
                                    'https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html',
                                    lng === 'en' ? 'Parent Resources' : 'Recursos para Padres',
                                    ' ',
                                    'home_parent_resources.png'
                                )}
                            </Grid>
                            <Grid item xs={12} md={3}>
                                {resourceButton(
                                    ' https://tea.texas.gov/texas-schools/accountability/academic-accountability/performance-reporting/performance-reporting-resources',
                                    lng === 'en' ? 'Technical Resources' : 'Recursos técnicos',
                                    ' ',
                                    'home_afresources.png'
                                )}
                            </Grid>
                            <Grid item xs={12} md={6}></Grid>
                        </Grid>
                    </div>
                ) : (
                    <>
                        {!!srclists.distances && viewType !== 'districts' ? (
                            <div className={myClasses.searchSection}>
                                <Grid container spacing={2}>
                                    <Hidden smDown>
                                        <Grid item md={4}>
                                            <div style={{ fontWeight: 'bold', marginLeft: '1rem' }}>
                                                <p style={{ fontSize: '2.5rem', margin: 0 }}>
                                                    {lng === 'en' ? 'Find a school' : 'Encuentre una escuela'}
                                                </p>
                                                <p style={{ fontSize: '2.5rem', margin: 0 }}>
                                                    {lng === 'en' ? 'for your child' : 'para su hijo'}
                                                </p>
                                            </div>
                                        </Grid>
                                    </Hidden>
                                    <Grid item sm={12} md={5} style={{ margin: 'auto' }}>
                                        <p style={{ textAlign: 'center', Color: 'blue', marginTop: '2rem' }}>
                                            {lng === 'en' ? ' ' : ' '}
                                        </p>
                                        <CardAddressFilter
                                            placeholder={
                                                lng === 'en'
                                                    ? 'Enter Your Address, City, or Zip Code'
                                                    : 'Introduzca Su Dirección, , ciudad o código postal'
                                            }
                                            onChange={handleAddressChange}
                                            initialVal={!!homeAddress ? homeAddress.address : ''}
                                        />
                                    </Grid>
                                    <Grid item sm={12} md={3} style={{ margin: 'auto' }}>
                                        <p style={{ marginLeft: '.5rem' }}>{lng === 'en' ? 'Within' : 'dentro de'}</p>
                                        <Select
                                            value={milesSearch}
                                            onChange={(evt) => {
                                                setMilesSearch(evt.target.value);
                                            }}
                                            style={{ backgroundColor: 'white', paddingLeft: '.5rem' }}
                                        >
                                            {srclists.distances.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                </Grid>
                            </div>
                        ) : null}

                        <Grid container style={{ margin: '1rem 0' }}>
                            <Grid item xs={12} lg={viewType === 'schools' ? 4 : 12}>
                                <CardsFilters
                                    filters={filters}
                                    srclists={srclists}
                                    lng={lng}
                                    homeAddress={homeAddress}
                                    viewType={viewType}
                                    schools={schools}
                                    districts={districts}
                                    initialLoad={initialLoad}
                                    onChangeView={onChangeView}
                                    onFilterChange={handleFiltersChange}
                                    onZoomMap={zoomMap}
                                    onHomeAddressChange={handleAddressChange}
                                />
                            </Grid>
                            {viewType === 'schools' ? (
                                <Grid item container xs={12} lg={8}>
                                    {/* to show the map we need either a selected school, or some filter applied that
                                    shows results */
                                    /* we don't show the map when the filters exclude all schools or if
                                    no filters at all are applied */}
                                    {!!selectedSchool ||
                                    (postFiltered.length > 0 &&
                                        postFiltered.length <= MAX_SCHOOLS_IN_MAP &&
                                        (!!homeAddress ||
                                            Object.keys(filters).length > 0 ||
                                            postFiltered.length !== entities.length)) ? (
                                        <Grid item xs={12}>
                                            <Hidden smDown>
                                                {' '}
                                                <span>
                                                    {' '}
                                                   {lng === 'en' ? 
                                                     <img title='Map Legend'
                                                        src='./images/map_legend.png'
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '-.8rem',
                                                            marginBottom: '-1.75rem',
                                                        }}
                                                    /> : null }
                                                   {lng === 'es' ? 
                                                     <img title='Map Legend'
                                                        src='./images/map_legend_spanish.png'
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '-.8rem',
                                                            marginBottom: '-1.75rem',
                                                        }}
                                                    /> : null } 

                                                {' '}
                                                </span>
                                            </Hidden>

                                            {/*    <SASStateMap 
                                                id='sasMapID'
                                                selectedSchool={selectedSchool}
                                                entities={
                                                    !!homeAddress ||
                                                    Object.keys(filters).length > 0 ||
                                                    postFiltered.length !== entities.length
                                                        ? postFiltered
                                                        : [selectedSchool]
                                                }
                                            /> */}

                                            <TxsMap1
                                                entities={
                                                    !!homeAddress ||
                                                    Object.keys(filters).length > 0 ||
                                                    postFiltered.length !== entities.length
                                                        ? postFiltered
                                                        : [selectedSchool]
                                                }
                                                txsMapStyle={{ height: '42rem' }}
                                            />
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12}>
                                            {' '}
                                            <span
                                                style={{
                                                    fontSize: '1.5rem',
                                                    position: 'relative',
                                                    objectFit: 'cover',
                                                    marginTop: '3rem',
                                                }}
                                            >
                                                <b
                                                    hidden={
                                                        allEntities.length === postFiltered.length ||
                                                        postFiltered.length <= MAX_SCHOOLS_IN_MAP
                                                    }
                                                >
                                                    Too many schools to show in map. Please refine your filters to see
                                                    locations.
                                                </b>
                                                <b
                                                    hidden={
                                                        allEntities.length === postFiltered.length ||
                                                        postFiltered.length > 0
                                                    }
                                                >
                                                    {lng === 'en'
                                                        ? 'No schools found matching the criteria you selected. Please change your selections.'
                                                        : 'No se encontró ninguna escuela que coincida con los criterios que seleccionó. Por favor, cambie sus selecciones.'}
                                                </b>{' '}
                                            </span>
                                            <Hidden smDown>
                                                {' '}
                                                <span>
                                                    {' '}
                                           {lng === 'en' ? 
                                                        <img
                                                        title='Map Legend'
                                                        src='./images/map_legend.png'
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '-.8rem',
                                                            marginBottom: '-1.75rem',
                                                        }}
                                                    /> : null }
                                           {lng === 'es' ? 
                                                        <img
                                                        title='Map Legend'
                                                        src='./images/map_legend_spanish.png'
                                                        style={{
                                                            width: '100%',
                                                            marginTop: '-.8rem',
                                                            marginBottom: '-1.4rem',
                                                        }}
                                                    /> : null }
                                                {' '}
                                                </span>
                                            </Hidden>
                                            {/* <sas-report-object 
                                             id='va-report-element'
                                             authenticationType='guest'
                                             url={vaserver}
                                             reportUri='/reports/reports/4de86b44-386a-49bf-bac0-3dffde5d63d6'
                                             objectName='ve16'
                                             ></sas-report-object> */}
                                            <TxsMap1
                                                entities={[{ longitude: -98.5211384, latitude: 29.369057 }]}
                                                txsMapStyle={{ height: '42rem' }}
                                                zoomLevel={0}
                                            />
                                        </Grid>
                                    )}
                                    <CardsList
                                        lng={lng}
                                        entities={entities}
                                        viewType={viewType}
                                        homeAddress={homeAddress}
                                        schoolPrograms={schoolPrograms}
                                        schoolAp={schoolAp}
                                        schoolUil={schoolUil}
                                        srclists={srclists}
                                        schools={schools}
                                        districts={districts}
                                        onZoomMap={zoomMap}
                                        onFilter={setPostFiltered}
                                    />
                                </Grid>
                            ) : (
                                <CardsList
                                    lng={lng}
                                    entities={entities}
                                    viewType={viewType}
                                    homeAddress={homeAddress}
                                    schoolPrograms={schoolPrograms}
                                    schoolAp={schoolAp}
                                    schoolUil={schoolUil}
                                    srclists={srclists}
                                    schools={schools}
                                    districts={districts}
                                    onZoomMap={zoomMap}
                                    onFilter={setPostFiltered}
                                />
                            )}
                        </Grid>

                        <hr />
                    </>
                )
            ) : null}
        </>
    );
};
export default Home;

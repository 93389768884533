import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Hidden from '@material-ui/core/Hidden';

import utils from '../components/utils';
import TexasAssessment from '../components/texasAssessment';
import Overview from './school/Overview';
import Accountability from './school/Accountability';
import Achievement from './school/Achievement';
import Progress from './school/Progress';
import Gaps from './school/Gaps';
import Finance from './school/Finance';
import NestedMenuItem from 'material-ui-nested-menu-item';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundImage: "url('./images/home-header.jpg')",
        //  width: '100%',
        color: theme.palette.text.tertiary,
        //  color: theme.palette.primary.main,
        padding: '.4rem',
        marginBottom: '1px',
        borderRadius: '.25rem',
    },
    headerRight: {
        [theme.breakpoints.up('md')]: {
            borderLeft: 'white 1px solid',
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: 'white 1px solid',
        },
        padding: '.5rem',
        fontSize: '1rem',
    },

    menuBar: {
        backgroundColor: theme.palette.background.default,
    },
    tabs: {
        color: theme.palette.primary.main,
        // color: '#012169',
    },
    tab: {
        height: '3rem',
        minHeight: 0,
        minWidth: '7rem',
        fontSize: '1rem',
        fontWeight: '600',
        color: theme.palette.primary.main,
    },

    iconTabWrapper: {
        flexDirection: 'row-reverse',
    },
    iconTabLabelIcon: {
        paddingTop: '6px',
    },
}));
/** Handles the /school - School school
 * @component
 * @param {*} props
 *
 * @example
 * <School
 *    id={id}
 *    school={school}
 * />
 */
School.defaultProps = { tabId: 'overview' };

export default function School({
    school,
    las,
    schools,
    tabId,
    programs,
    school_ap,
    school_uil,
    lng,
    onChangeTab,
    onChangeView,
    onChangeId,
}) {
    utils.setHomeTitle(!!school && school.name);

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    const paired = schools.find((pair) => school.paired_id === pair.id);

    const [tab, setTab] = useState(null);
    const [menu, setMenu] = useState(null);
    const [performanceMenuAnchor, setPerformanceMenuAnchor] = useState(null);
    const [reportsMenuAnchor, setReportsMenuAnchor] = useState(null);
    const [completeMenuAnchor, setCompleteMenuAnchor] = useState(null);

    const translateGrade = (grade) => {
        const mapping = {
            PK: 'PreKindergarten',
            EE: 'Early Education',
            E: 'Kindergarten',
        };
        return mapping[grade] === undefined ? 'Grade ' + grade : mapping[grade];
    };
    const gradeRange = (start, end) => {
        const startGrade = translateGrade(start);
        const endGrade = translateGrade(end);
        return startGrade === endGrade ? startGrade : startGrade + ' - ' + endGrade;
    };
    const formatPhoneNumber = (str) => {
        // Filter only numbers from the input
        const cleaned = ('' + str).replace(/\D/g, '');

        // Check if the input is of correct length
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }

        return null;
    };

    const onTabChange = (evt, theTab) => {
        if (theTab === 'performance') {
            setPerformanceMenuAnchor(evt.currentTarget);
            setReportsMenuAnchor(null);
            setCompleteMenuAnchor(null);
        } else if (theTab === 'reports') {
            setReportsMenuAnchor(evt.currentTarget);
            setPerformanceMenuAnchor(null);
            setCompleteMenuAnchor(null);
        } else if (theTab === 'complete') {
            setCompleteMenuAnchor(evt.currentTarget);
            setReportsMenuAnchor(null);
        } else if (theTab === 'compare') {
            window.open(
                'https://' + locserver + '/perfreport/account/va/school-compare.html?id=' + school.id,
                '_blank'
            );
        } else if (theTab === 'parent') {
            window.open('https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html', '_blank');
        } else {
            setPerformanceMenuAnchor(null);
            setReportsMenuAnchor(null);
            setCompleteMenuAnchor(null);
            onChangeTab(theTab);
        }
    };
    const onMenuChange = (menuId, theTab) => {
        setPerformanceMenuAnchor(null);
        setCompleteMenuAnchor(null);
        onChangeTab(theTab + '|' + menuId);
    };

    const onMenuChangeSm = (menuId, theTab) => {
        setCompleteMenuAnchor(null);
        onChangeTab(theTab + '|' + menuId);
    };

    const onPair = () => {
        onChangeId(paired.id);
    };

    const onPaird = () => {
        onChangeView('district');
        onChangeId(paired.id.substring(0, 6));
    };

    useEffect(() => {
        if (tabId.includes('|')) {
            const [theTab, theMenu] = tabId.split('|');
            setTab(theTab);
            setMenu(theMenu);
        } else {
            setTab(tabId);
            setMenu(null);
        }
    }, [tabId]);

    if (!!school && !!programs) {
        return (
            <>
                <AppBar position='sticky'>
                    <Grid container spacing={1} className={myClasses.header}>
                        <Grid item md={1} />
                        <Grid item xs={12} md={6}>
                            <h1 style={{ textTransform: 'uppercase', fontSize: '2.2rem' }}>{school.name}</h1>

                            <span style={{ fontSize: '1.2rem' }}>
                                <span style={{ marginRight: '1.75rem' }}>
                                    {lng === 'en' ? 'District:' : 'Distrito:'}{' '}
                                    <b>
                                        <Link
                                            onClick={() => {
                                                onChangeView('district');
                                                onChangeId(school.district_id);
                                            }}
                                            style={{ color: 'white', cursor: 'pointer' }}
                                        >
                                            {school.district_name}
                                        </Link>
                                    </b>
                                </span>
                                <Hidden smDown>
                                    {lng === 'en' ? 'Grades Served:' : 'Grados atendidos:'}{' '}
                                    <b>
                                        {school.low_grade} - {school.high_grade}
                                    </b>
                                </Hidden>
                            </span>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} md={5} className={myClasses.headerRight}>
                                <p>
                                    <b>{lng === 'en' ? 'ADDRESS:' : 'DIRECCIÓN:'}</b>
                                    <br />
                                    {school.address}
                                </p>

                                <p>
                                    <b>{lng === 'en' ? 'PHONE:' : 'TELÉFONO:'}</b>
                                    <br />
                                    {utils.formatPhone(school.phone)}
                                </p>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <AppBar position='sticky' className={myClasses.menuBar}>
                        <Hidden mdUp>
                            <Tabs value={tab} onChange={onTabChange} className={myClasses.tabs}>
                                <Tab value='overview' label='Profile' className={myClasses.tab} />
                                <Tab
                                    value='complete'
                                    label='Other Resources'
                                    icon={!!completeMenuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                            </Tabs>
                        </Hidden>
                        <Hidden smDown>
                            <Tabs value={tab} onChange={onTabChange} className={myClasses.tabs}>
                                <Tab
                                    value='overview'
                                    label={lng === 'en' ? 'Profile' : 'perfil'}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='performance'
                                    label={lng === 'en' ? 'Performance' : 'Rendimiento'}
                                    icon={!!performanceMenuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='finance'
                                    label={lng === 'en' ? 'Finance' : 'Finanzas'}
                                    className={myClasses.tab}
                                />

                                <Tab
                                    value='parent'
                                    label={lng === 'en' ? 'Parent Resources' : 'Recursos para padres'}
                                    className={myClasses.tab}
                                    style={{ marginLeft: 'auto' }}
                                />
                                <Tab
                                    value='compare'
                                    label={lng === 'en' ? 'Compare' : 'comparar'}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='reports'
                                    label={lng === 'en' ? 'PDF Reports' : 'Informes de Formato de Documento Portátil'}
                                    icon={!!reportsMenuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                            </Tabs>
                        </Hidden>
                    </AppBar>
                </AppBar>

                <Menu
                    anchorEl={performanceMenuAnchor}
                    open={!!performanceMenuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setPerformanceMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('accountability', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Accountability Overview' : 'Visión General de desempeño'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('achievement', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Student Achievement' : 'Logros Estudiantiles'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('progress', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'School Progress' : 'Progreso Escolar'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('gaps', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Closing the Gaps' : 'Cierre de Diferencias'}
                    </MenuItem>
                </Menu>
                <Menu
                    anchorEl={reportsMenuAnchor}
                    open={!!reportsMenuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setReportsMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                    school.id,
                                '_blank'
                            );
                        }}
                    >
                        2021-22
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2021&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                    school.id,
                                '_blank'
                            );
                        }}
                    >
                        2020-21
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2020&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                    school.id,
                                '_blank'
                            );
                        }}
                    >
                        2019-20
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                ' https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&cyr=2019&level=campus&search=campnum&prgopt=2019%2Fsrc%2Fsrc.sas&namenum=' +
                                    school.id,
                                '_blank'
                            );
                        }}
                    >
                        2018-19
                    </MenuItem>
                </Menu>

                <Menu
                    anchorEl={completeMenuAnchor}
                    open={!!completeMenuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setCompleteMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <NestedMenuItem label='Performance' parentMenuOpen={!!completeMenuAnchor}>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('accountability', 'complete');
                            }}
                        >
                            Accountability Overview
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('achievement', 'complete');
                            }}
                        >
                            Student Achievement
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('progress', 'complete');
                            }}
                        >
                            School Progress
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('gaps', 'complete');
                            }}
                        >
                            Closing the Gaps
                        </MenuItem>
                    </NestedMenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            onMenuChangeSm('financem', 'complete');
                        }}
                    >
                        Finance
                    </MenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            window.open(
                                'https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html',
                                '_blank'
                            );
                        }}
                    >
                        {' '}
                        Parent Resources
                    </MenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            window.open(
                                'https://' + locserver + '/perfreport/account/va/school-compare.html?id=' + school.id,
                                '_blank'
                            );
                        }}
                    >
                        {' '}
                        Compare
                    </MenuItem>

                    <NestedMenuItem label='PDF Reports' parentMenuOpen={!!completeMenuAnchor}>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                window.open(
                                    'https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                        school.id,
                                    '_blank'
                                );
                            }}
                        >
                            2021-22
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                window.open(
                                    'https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2021&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                        school.id,
                                    '_blank'
                                );
                            }}
                        >
                            2020-21
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                window.open(
                                    'https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2020&lev=C&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                        school.id,
                                    '_blank'
                                );
                            }}
                        >
                            2019-20
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                window.open(
                                    ' https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&cyr=2019&level=campus&search=campnum&prgopt=2019%2Fsrc%2Fsrc.sas&namenum=' +
                                        school.id,
                                    '_blank'
                                );
                            }}
                        >
                            2018-19
                        </MenuItem>
                    </NestedMenuItem>
                </Menu>

                <Paper>
                    {tab === 'overview' ? (
                        <Overview
                            school={school}
                            programs={programs}
                            school_ap={school_ap}
                            school_uil={school_uil}
                            paired={paired}
                            onPair={onPair}
                            onPaird={onPaird}
                            onNav={(page) => {
                                onMenuChange(page, 'performance');
                            }}
                        />
                    ) : null}
                    {menu === 'accountability' ? (
                        <Accountability
                            school={school}
                            las={las}
                            onNav={(page) => {
                                onMenuChange(page, 'performance');
                            }}
                            show={menu === 'accountability'}
                            paired={paired}
                            onPair={onPair}
                            onPaird={onPaird}
                        />
                    ) : null}
                    {menu === 'achievement' ? <Achievement school={school} paired={paired} /> : null}
                    {menu === 'progress' ? <Progress school={school} paired={paired} /> : null}
                    {menu === 'gaps' ? <Gaps school={school} paired={paired} /> : null}
                    {tab === 'finance' ? <Finance school={school} paired={paired} /> : null}
                    {menu === 'financem' ? <Finance school={school} paired={paired} /> : null}
                </Paper>

                <TexasAssessment />
            </>
        );
    } else return <></>;
}

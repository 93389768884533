import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DataTable from 'react-data-table-component';

export default function ChartjsAchievementgrad({ entity, achieve }) {
    // 1. Common Setup --------------------------------
    let condition;
    if (!!entity && entity.entity_cd === 'C') {
        condition = 1; // campus.
    } else if (!!entity && entity.entity_cd === 'D') {
        condition = 2; // district.
    } else {
        condition = 0; // error.
    }

    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }

    // readin data;
    // different column names for col 2 and col 3 in entity and district case.
    const entityCol2 =
        condition === 1
            ? lang === 'en'
                ? 'This School'
                : 'Esta Escuela'
            : lang === 'en'
            ? 'This District'
            : 'Este Distrito';
    const entityCol3 = condition === 1 ? (lang === 'en' ? 'District' : 'Distrito') : lang === 'en' ? 'State' : 'Estado';
    // use entity id to filter out data.
    const achieveMatchedList = achieve.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = achieveMatchedList.length === 0;
    // STARR Participation Rate string list: [0] for 2022, [1] for 2021:
    const dataparticipationListEntity = checkMatchedList ? ['', ''] : achieveMatchedList[0].participation;
    // Setup Ends--------------------------------

    // 5.Graduation Rate Details DT Setup -----------
    const labelsAchieveGradRate = [
        lang === 'en' ? 'Four-Year Graduation Rate' : 'Porcentaje de Graduados de Cuatro Años',
        lang === 'en' ? 'Five-Year Graduation Rate' : 'Porcentaje de Graduados de Cinco Años',
        lang === 'en' ? 'Six-Year Graduation Rate' : 'Porcentaje de Graduados de Seis Años',
        lang === 'en' ? 'Dropout Rate' : 'Porcentaje de Deserción Escolar',
    ];

    const datagrad_rate_col2ListEntity = checkMatchedList ? [''] : achieveMatchedList[0].grad_rate_col2;
    const datagrad_rate_col3ListEntity = checkMatchedList ? [''] : achieveMatchedList[0].grad_rate_col3;
    const datagr_flagListEntity = checkMatchedList ? [''] : achieveMatchedList[0].gr_flag;

    const dataDTStuGradRate = labelsAchieveGradRate.map(function (value, index) {
        return {
            id: index,
            criteria: value,
            grad_rate_col2: datagrad_rate_col2ListEntity[index],
            grad_rate_col3: datagrad_rate_col3ListEntity[index],
            gr_flag: datagr_flagListEntity[index],
        };
    });
    const columnsDTStuGradRate = [
        {
            grow: 8,
            name: '',
            cell: (row) => row.criteria,
            style: {
                justifyContent: 'left',
                textAlign: 'left',
            },
        },
        {
            grow: 1,
            name: <div style={{ textOverflow: 'unset' }}>{entityCol2}</div>,
            selector: (row) => row.grad_rate_col2,
            style: {
                justifyContent: 'center',
            },
        },
        {
            grow: 1,
            name: entityCol3,
            style: {
                justifyContent: 'center',
            },
            selector: (row) => row.grad_rate_col3,
        },
    ];

    const conditionalRowStylesStuGradRate = [
        {
            when: (row) => row.gr_flag === 'x',
            style: {
                backgroundColor: '#56b7e6',
                fontWeight: 'bold',
            },
        },
    ];
    const customStylesStuGradRate = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'left',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
            },
        },
    };
    // Setup Ends--------------------------------

    // DT datatable data setting above --------------------------------
    return (
        <div>
            {datagrad_rate_col2ListEntity === ['-', '-', '-', '-'] ? null : (
                <DataTable
                    columns={columnsDTStuGradRate}
                    data={dataDTStuGradRate}
                    customStyles={customStylesStuGradRate}
                    conditionalRowStyles={conditionalRowStylesStuGradRate}
                    highlightOnHover
                />
            )}
        </div>
    );
}

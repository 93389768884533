import { createElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { Icon, Button } from '@material-ui/core';
import { LocationOn, LocationOff, Clear } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';

function renderInput({
    InputProps,
    classes,
    ref,
    selectedItem,
    isOpen,
    clearSelection,
    openMenu,
    closeMenu,
    ...other
}) {
    return (
        <div>
            <TextField
                id='TextField' multiline minRows={1} maxRows={2}
                InputProps={{
                    inputRef: ref,
                    classes: {
                        input: classes.inputInput,
                    },
                    ...InputProps,
                }}
                style={{ backgroundColor: 'white'}}
                {...other}
            />
        </div>
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = !selectedItem ? false : (selectedItem.label || '').indexOf(suggestion.label) > -1;
    return (
        <MenuItem
            {...itemProps}
            key={'suggestion' + index}
            value={suggestion.value}
            selected={isHighlighted}
            component='div'
            style={{
                fontWeight: isSelected ? 500 : 400,
            }}
        >
            {suggestion.label}
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.object,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

const styles = (theme) => ({
    root: {},
    container: {
        flexGrow: 1,
        position: 'relative',
        // border: '2px solid #000066',
        // borderRadius: '25px'
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    inputRoot: {
        flexWrap: 'wrap',
    },
    inputInput: {
        padding: '.5rem',
        backgroundColor: 'white',
        fontWeight: '700',
        color: 'black' 
    },
    divider: {
        height: theme.spacing(2),
    },
});

/**   Card filter with autosuggest
 * @param placeholder - placeholder text to prompt input
 * @param filterName - string to identify the filter being processed.  This can be dynamic.
 * @param onChange - function process any change.  
 *    It will be passed a hash with "selection" being the selected 
 *    item and filterName equal to the input "filterName"
 * @param suggestions - JSON array with items to select. There should be properties for "label", "value" and optionally "address"
 * 
 * @example
 * <CardFilterAutosuggest   
        placeholder={"Search by "+["County","District","School"][ ["counties","districts","schools"].indexOf(viewType)]}
        onChange={handleFilterChange} 
        selectedItem={selectedItem}
        // setSelectedItem={setSelectedItem}
        filterName = {viewType}
        suggestions={
            viewType === "schools" ? srclists.schools.filter((item) => item.year = "2019")
            : srclists[viewType] }/>
 * 
 */

function CardAddressFilter({ classes, initialVal, selectedItem, placeholder, onChange }) {
    const [suggestions, setSuggestions] = useState([]);
    const onSelected = async (selection) => {
        if (selection === null) {
            setSuggestions([]);
            onChange(null);
        } else {
            // console.log("In on selected",  selection, "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine="+selection.value.text+"&magicKey="+selection.value.magicKey+"&f=json"  )
            const resp = await fetch(
                'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?SingleLine=' +
                    selection.label +
                    '&magicKey=' +
                    selection.value.magicKey +
                    '&f=json'
            );
            const result = await resp.json();
            if (result.candidates.length > 0) {
                onChange(result.candidates[0]);
            }
        }
    };

    return (
        <div className={classes.root}>
            <Downshift
                id={'downshift' + Math.random().toString()}
                initialSelectedItem={{ label: initialVal }}
                onChange={onSelected}
                itemToString={(item) => (item ? item.label : '')}
            >
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    openMenu,
                    closeMenu,
                    selectedItem,
                    clearSelection,
                }) => (
                    <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,                            
                            classes,
                            selectedItem,
                            isOpen,
                            openMenu,
                            closeMenu,
                            clearSelection,
                            InputProps: getInputProps({
                                placeholder: !placeholder ? '' : placeholder,
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <LocationOn style={{ marginLeft: '.25rem', color: '#e7e3db' }} />
                                    </InputAdornment>
                                ),
                                endAdornment:
                                    !!selectedItem && !!selectedItem.label ? (
                                        <InputAdornment position='end'>
                                            <Button
                                                style={{ float: 'right' }}
                                                onClick={clearSelection}
                                                aria-label='clear selection'
                                            >
                                                <Clear />
                                            </Button>
                                        </InputAdornment>
                                    ) : null,
                                onKeyUp: async (event) => {
                                    // Prevent Downshift's default 'Enter' behavior.
                                    event.nativeEvent.preventDownshiftDefault = true;
                                    const url =
                                        'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?text=' +
                                        event.target.value +
                                        '&countryCode=USA&region=TX&category=Address,Postal,Populated Place&outfields=*&isCollection=false&f=json&maxSuggestions=15';

                                    const resp = await fetch(url);
                                    const results = await resp.json();
                                    const filteredSuggestions = results.suggestions.filter(
                                        (suggestion) => suggestion.text.indexOf('TX') > -1
                                    );
                                    const newsuggestions = filteredSuggestions.map((result) => {
                                        return { label: result.text, value: { ...result } };
                                    });
                                    setSuggestions(newsuggestions);
                                },
                            }),
                        })}

                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square>
                                    {suggestions.map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                        })
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
        </div>
    );
}
CardAddressFilter.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardAddressFilter);

import React, { createElement, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import utils from '../../components/utils';
import { CircularProgress } from '@material-ui/core';
import PropTypes from 'prop-types';
import '../../bootstrap_m.css';
import TxsMap1 from '../../components/TxsMap1';
import ChartjsRaceEthnicity1 from '../../components/chartjsRaceEthnicity1';
import ChartjsRaceEthnicity2 from '../../components/chartjsRaceEthnicity2';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    button: {
        textTransform: 'none',
    },
    perfButton: {
        marginLeft: '.5rem',
        width: '12rem',
    },
    typography: {
        button: {
            textTransform: 'none',
        },
    },
    circle: {
        color: 'red',
        size: 15,
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    gradenr: {
        color: '#51555A',
    },
    grade: {
        backgroundColorcolor: '#FFFFFF',
    },
    header: {
        fontSize: '1.5rem',
    },
    Gradeheadera: {
        fontSize: '7rem',
        color: '#278143',
        padding: '14px 0px 0px 52px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '7rem',
        color: '#39398C',
        padding: '14px 0px 0px 54px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '7rem',
        color: '#9C118B',
        padding: '14px 0px 0px 51px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '7rem',
        color: '#E0803D',
        padding: '14px 0px 0px 56px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '7rem',
        color: '#CA313C',
        padding: '14px 0px 0px 59px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.6rem',
        color: 'black',
        padding: '30px 0px 0px 34px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.7rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    subHeader: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },

    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },

    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    subHeadernr: {
        fontSize: '3rem',
    },

    pairLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },

    gridItem: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '2rem',
        borderRadius: '.25rem',
        // borderLeft: '1px Solid',
        // borderTop: '1px Solid',
        // borderBottom: '1px Solid',
        // borderRight: '1px Solid',
    },
    schoolInfo: {
        [theme.breakpoints.up('sm')]: {
            borderLeft: '1px solid',
        },
        [theme.breakpoints.down('xs')]: {
            borderTop: '1px solid',
        },
        textAlign: 'left',
        fontSize: '1.1rem',
    },
    overviewBtn: {
        // color: theme.palette.text.tertiary,
        // backgroundColor: theme.palette.secondary.main,
        // '&:hover': { backgroundColor: theme.palette.secondary.light, },
        margin: '.1rem',
        minWidth: '11rem',
        justifyContent: 'left',
    },

    tellBtn: {
        color: theme.palette.text.tertiary,
        backgroundColor: '#a1268f',
        '&:hover': {
            backgroundColor: '#704280',
        },
        margin: '.1rem',
        // minWidth: '13rem',
        // justifyContent: 'left',
    },

    digBtn: {
        color: theme.palette.text.tertiary,
        backgroundColor: '#0d6cb9',
        '&:hover': {
            backgroundColor: '#21448f',
        },
        margin: '.1rem',
        // minWidth: '13rem',
        // justifyContent: 'left',
    },

    sectionButton: {
        marginTop: '1rem',
        width: '12rem',
    },

    infoOdd: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderTop: '1px Solid',
        overflow: 'hidden',
    },
    infoOddn: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        overflow: 'hidden',
    },
    infoOddna: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderLeft: '1px Solid',
        //	borderTop: '1px Solid',
        //	borderBottom: '1px Solid',
        borderRight: '1px Solid',
    },
    infoOddA: {
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        //	borderLeft: '1px Solid',
        borderTop: '1px Solid',
        //	borderBottom: '1px Solid',
        //	borderRight: '1px Solid',
    },
    infoEven: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
    },
    infoEvend: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoEvena: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        //	borderLeft: '1px Solid',
        borderTop: '1px Solid',
        borderBottom: '1px Solid',
        //	borderRight: '1px Solid',
    },
    infoEvenb: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderTop: '1px Solid',
    },

    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },
    frmszeaa: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '42.5rem',
        overflow: 'hidden',
    },
    frmszeab: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '41rem',
        overflow: 'hidden',
    },

    sectionIcons: {
        margin: '1rem',
        textAlign: 'center',
    },
    sectionIcon: {
        width: '190px',
        height: '180px',
    },
    sectionf: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },

    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

const BULLET_SIZE = 8;
const MAX_PROGRAMS_COLS = 4;

export default ({ school, las, programs, school_ap, school_uil, paired, onPair, onPaird, onNav }) => {
    utils.setHomeTitle(!!school && school.name + ' | Profile');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [enroll, setEnroll] = useState([]);
    // read in RaceEthnicity json
    useEffect(() => {
        fetch('../data/profile_tab.json')
            .then((resp) => resp.json())
            .then((json) => {
                setEnroll(json);
            });
    }, []);
    const entity = school;
    const enrollMatchedList = enroll.filter((item) => {
        return item.id === entity.id;
    });
    const dataSchool_Year = enrollMatchedList.length === 0 ? 0 : enrollMatchedList[0].School_Year;

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools.gov')) {
        vaserver = 'https://viya.txschools.gov';
    }
    if (window.location.href.includes('txschools.org')) {
        vaserver = 'https://viya.txschools.gov';
    }

    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
    }
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use school id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === school.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdOverPerfOrder2 = getContentId(dynModMatchedList, 'overall_performance');

    // map rating_cd to modal ID
    const getModalId = (rating_cd) => {
        const modalMap = { A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' };
        return modalMap[rating_cd];
    };

    const [showov, setShowov] = useState(false);
    const handleCloseov = () => {
        setShowov(!showov);
    };
    // const handleShowov = () => setShowov(true);

    const [showdis, setShowdis] = useState(false);
    const handleClosedis = () => {
        setShowdis(!showdis);
    };
    // const handleShowdis = () => setShowdis(true);

    const [showdes, setShowdes] = useState(false);
    const handleClosedes = () => {
        setShowdes(!showdes);
    };
    // const handleShowdes = () => setShowdes(true);

    const [showpro, setShowpro] = useState(false);
    const handleClosepro = () => {
        setShowpro(!showpro);
    };
    // const handleShowpro = () => setShowpro(true);

    const [showap, setShowap] = useState(false);
    const handleCloseap = () => {
        setShowap(!showap);
    };
    // const handleShowap = () => setShowap(true);

    const [showuil, setShowuil] = useState(false);
    const handleCloseuil = () => {
        setShowuil(!showuil);
    };
    // const handleShowuil = () => setShowuil(true);

    const [showsti, setShowsti] = useState(false);
    const handleClosesti = () => {
        setShowsti(!showsti);
    };
    // const handleShowsti = () => setShowsti(true);

    const [showtai, setShowtai] = useState(false);
    const handleClosetai = () => {
        setShowtai(!showtai);
    };
    // const handleShowtai = () => setShowtai(true);

    const handleClicka = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&lev=C&id=' +
                school.id +
                '&prgopt=reports/tapr/student.sas'
        );
    };
    const handleClickb = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&lev=C&id=' +
                school.id +
                '&prgopt=reports/tapr/staff.sas'
        );
    };

    const myClasses = useStyles();

    const theme = useTheme();
    const ProgramBullet = ({ program }) => (
        <div style={{ display: 'flex', marginLeft: '2rem' }}>
            <svg height={BULLET_SIZE * 3} width={BULLET_SIZE * 1} style={{ marginTop: BULLET_SIZE * 1 }}>
                <circle
                    cx={BULLET_SIZE / 2}
                    cy={BULLET_SIZE / 2}
                    r={BULLET_SIZE / 2}
                    fill={theme.palette.secondary.main}
                />
            </svg>
            <p style={{ fontWeight: '500', margin: '.25rem' }}>{program}</p>
        </div>
    );

    const ApBullet = ({ ap_course }) => (
        <div style={{ display: 'flex', marginLeft: '2rem' }}>
            <svg height={BULLET_SIZE * 3} width={BULLET_SIZE * 1} style={{ marginTop: BULLET_SIZE * 1 }}>
                <circle
                    cx={BULLET_SIZE / 2}
                    cy={BULLET_SIZE / 2}
                    r={BULLET_SIZE / 2}
                    fill={theme.palette.secondary.main}
                />
            </svg>
            <p style={{ fontWeight: '500', margin: '.25rem' }}>{ap_course}</p>
        </div>
    );

    const UilBullet = ({ uil_activity }) => (
        <div style={{ display: 'flex', marginLeft: '2rem' }}>
            <svg height={BULLET_SIZE * 3} width={BULLET_SIZE * 1} style={{ marginTop: BULLET_SIZE * 1 }}>
                <circle
                    cx={BULLET_SIZE / 2}
                    cy={BULLET_SIZE / 2}
                    r={BULLET_SIZE / 2}
                    fill={theme.palette.secondary.main}
                />
            </svg>
            <p style={{ fontWeight: '500', margin: '.25rem' }}>{uil_activity}</p>
        </div>
    );

    // fairly complex layout logic...orders them in their columns first,
    //  so we're having to skip over programs to layout our Grid items
    // first, we need to know how many columns there will be before laying out the academic programs Grid
    const isMD = useMediaQuery(theme.breakpoints.up('md'));
    const isSM = useMediaQuery(theme.breakpoints.up('sm'));

    // programs = programs.sort((a,b) => a.program.toUpperCase().localeCompare(b.program.toUpperCase()));
    const layoutPrograms = () => {
        const [sortedPrograms, gridColSpan] = utils.transposeGrid(programs, MAX_PROGRAMS_COLS, isMD, isSM);

        return !!sortedPrograms.length ? (
            <Grid container spacing={2}>
                {sortedPrograms.map((program, idx) => (
                    <Grid item xs={gridColSpan}>
                        <ProgramBullet program={program.program} />
                    </Grid>
                ))}
            </Grid>
        ) : null;
    };

    // school_ap = school_ap.sort((a,b) => a.school_ap.toUpperCase().localeCompare(b.school_ap.toUpperCase()));
    const layoutAp = () => {
        const [sortedSchool_ap, gridColSpan] = utils.transposeGrid(school_ap, MAX_PROGRAMS_COLS, isMD, isSM);

        return !!sortedSchool_ap.length ? (
            <Grid container spacing={2}>
                {sortedSchool_ap.map((ap_course, idx) => (
                    <Grid item xs={gridColSpan}>
                        <ApBullet ap_course={ap_course.ap_course} />
                    </Grid>
                ))}
            </Grid>
        ) : null;
    };

    // school_uil = school_uil.sort((a,b) => a.school_uil.toUpperCase().localeCompare(b.school_uil.toUpperCase()));
    const layoutUil = () => {
        const [sortedSchool_uil, gridColSpan] = utils.transposeGrid(school_uil, MAX_PROGRAMS_COLS, isMD, isSM);

        return !!sortedSchool_uil.length ? (
            <Grid container spacing={2}>
                {sortedSchool_uil.map((uil_activity, idx) => (
                    <Grid item xs={gridColSpan}>
                        <UilBullet uil_activity={uil_activity.uil_activity} />
                    </Grid>
                ))}
            </Grid>
        ) : null;
    };

    return (
        <div>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/profile_school_white.png'
                    alt='school icon'
                    style={{ height: '2.4rem', marginRight: '.5rem', marginTop: '.6rem' }}
                />{' '}
                {lang === 'en' ? 'SCHOOL PROFILE' : 'PERFIL DE LA ESCUELA'}{' '}
            </div>
            <Grid container style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} md={7} className={myClasses.gridItem}>
                    <Grid container spacing={1} style={{ textAlign: 'center' }}>
                        {/* Grid handled 3 ways: Not Rated, Not Rated scored(U), all else.  Acceptable condition should be removed for 22 ratings */}

                        {school.rating_cd === 'X' || school.rating_cd === 'Z' ? (
                            <Grid item xs={12} sm={8}>
                                <b className={myClasses.subHeader}>
                                    {' '}
                                    {lang === 'en' ? 'Overall Rating' : 'Calificación General'}{' '}
                                </b>
                                <br />
                                <br />
                                <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                    <b className={myClasses.subHeadernr}>
                                        {lang === 'en' ? 'Not Rated' : 'No Clasificado'}
                                    </b>
                                </div>
                                <br />
                                <br />
                                <br />
                                {!!paired && paired.id !== school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        &nbsp;
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}
                                        &nbsp;
                                        <Link onClick={onPair} className={myClasses.pairLink}>
                                            {paired.name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {!!paired && paired.id === school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        &nbsp;
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}
                                        &nbsp;
                                        <Link onClick={onPaird} className={myClasses.pairLink}>
                                            {paired.district_name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {school.alt_standards === 'Yes' ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/aea_icon.png'
                                            alt='AEA school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-.5rem' }}
                                        />{' '}
                                        Alternative standards were used to rate this school.&nbsp;
                                        {school.aea_bonus === 'Yes'
                                            ? 'Bonus points for an Alternate Education school have been applied.'
                                            : null}
                                    </p>
                                ) : null}
                                <p style={{ fontSize: '1rem' }}>
                                    This measures how much students are learning in each grade and whether or not they
                                    are ready for the next grade. It also shows how well a school or district prepares
                                    their students for success after high school in college, the workforce, or the
                                    military.
                                </p>
                                <br />
                                {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                                <ModalEntity
                                    modalId={getModalId(school.rating_cd)}
                                    modalId2={mIdOverPerfOrder2}
                                    lang={lang}
                                />
                                <span style={{ marginLeft: '8px' }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            onNav('accountability');
                                        }}
                                    >
                                        {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
                                    </Button>
                                </span>
                            </Grid>
                        ) : school.rating_cd === 'U' || school.rating_cd === 'Q' ? (
                            <Grid item xs={12} sm={8}>
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                                </b>
                                <br />
                                <br />
                                <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado '}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={185}
                                    />
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={parseFloat(school.score, 10)}
                                        size={185}
                                    />
                                </div>
                                <b className={myClasses.subHeader}>
                                    <span className={myClasses['grade' + school.rating.toLowerCase()]}>
                                        {' '}
                                        {school.score} {lang === 'en' ? 'out of' : 'de'} 100
                                    </span>
                                </b>
                                <br />
                                {school.rating_cd === 'U' ? (
                                 <p style={{ fontSize: '1.2rem' }}>
                                    <img
                                        src='./images/info_icon_navy.png'
                                        alt='not rated school icon'
                                        style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                    />
                                    &nbsp;{' '}
                                    {lang === 'en'
                                        ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                        : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                                    <br />
                                </p>
                                ) : null}
                               {school.rating_cd === 'Q' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                 <img
                                  src='./images/exclamation_point.jpg'
                                  alt='not rated school icon'
                                  style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                 />
                               &nbsp;
                               {lang === 'en'
                                ? 'This campus is Not Rated: Data Under Review.'
                                : 'Este campus no Está Clasificado: Datos Bajo Revisión.'}
                               <br />
                             </p>
                            ) : null}
                          {!!paired && paired.id !== school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        &nbsp;{' '}
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}{' '}
                                        &nbsp;
                                        <Link onClick={onPair} className={myClasses.pairLink}>
                                            {paired.name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {!!paired && paired.id === school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}{' '}
                                        &nbsp;
                                        <Link onClick={onPaird} className={myClasses.pairLink}>
                                            {paired.district_name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {school.alt_standards === 'Yes' ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/aea_icon.png'
                                            alt='AEA school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-.5rem' }}
                                        />{' '}
                                        Alternative standards were used to rate this school.&nbsp;
                                        {school.aea_bonus === 'Yes'
                                            ? 'Bonus points for an Alternate Education school have been applied.'
                                            : null}
                                    </p>
                                ) : null}

                                <p style={{ fontSize: '1rem' }}>
                                    This measures how much students are learning in each grade and whether or not they
                                    are ready for the next grade. It also shows how well a school or district prepares
                                    their students for success after high school in college, the workforce, or the
                                    military.
                                </p>
                                <br />
                                {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                                <ModalEntity
                                    modalId={getModalId(school.rating_cd)}
                                    modalId2={mIdOverPerfOrder2}
                                    lang={lang}
                                />
                                <span style={{ marginLeft: '8px' }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            onNav('accountability');
                                        }}
                                        className={myClasses.perfButton}
                                    >
                                        {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
                                    </Button>
                                </span>
                            </Grid>
                        ) : (
                            <Grid item xs={12} sm={8}>
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                                </b>
                                <br />
                                <br />
                                <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                    <b id='gradea' className={myClasses['Gradeheader' + school.rating.toLowerCase()]}>
                                        {school.rating}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={185}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.rating.toLowerCase()]}
                                        variant='determinate'
                                        value={parseFloat(school.score, 10)}
                                        size={185}
                                    />
                                </div>
                                <b className={myClasses.subHeader}>
                                    <span className={myClasses['grade' + school.rating.toLowerCase()]}>
                                        {' '}
                                        {school.score} {lang === 'en' ? 'out of' : 'de'} 100
                                    </span>
                                </b>
                                <br />

                                {!!paired && paired.id !== school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        &nbsp;
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}
                                        &nbsp;
                                        <Link onClick={onPair} className={myClasses.pairLink}>
                                            {paired.name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {!!paired && paired.id === school.id ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/paired_campus_icon_green.png'
                                            alt='paired school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                        />
                                        &nbsp;
                                        {lang === 'en'
                                            ? 'This campus is paired with'
                                            : 'Este campus está emparejado con'}
                                        &nbsp;
                                        <Link onClick={onPaird} className={myClasses.pairLink}>
                                            {paired.district_name}
                                        </Link>
                                        <br />
                                    </p>
                                ) : null}
                                {school.alt_standards === 'Yes' ? (
                                    <p style={{ fontSize: '1.2rem' }}>
                                        <img
                                            src='./images/aea_icon.png'
                                            alt='AEA school icon'
                                            style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-.5rem' }}
                                        />{' '}
                                        {lang === 'en'
                                            ? 'Alternative standards were used to rate this school.'
                                            : 'Se utilizaron estándares alternativos para calificar esta escuela.'}
                                        &nbsp;
                                        {school.aea_bonus === 'Yes'
                                            ? 'Bonus points for an Alternate Education school have been applied.'
                                            : null}
                                    </p>
                                ) : null}

                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                                        : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                                </p>
                                <br />
                                {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                                <ModalEntity
                                    modalId={getModalId(school.rating_cd)}
                                    modalId2={mIdOverPerfOrder2}
                                    lang={lang}
                                />
                                <span style={{ marginLeft: '8px' }}>
                                    <Button
                                        variant='contained'
                                        onClick={() => {
                                            window.scrollTo(0, 0);
                                            onNav('accountability');
                                        }}
                                        className={myClasses.perfButton}
                                    >
                                        {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
                                    </Button>
                                </span>
                            </Grid>
                        )}

                        {/* end 3 way grid conditional */}

                        <Grid item xs={12} sm={4} className={myClasses.schoolInfo}>
                            <br />
                            <br />
                            <p>
                                <b>{lang === 'en' ? 'Address' : 'Dirección'}:</b>
                                <br />
                                {school.street_address}
                                <br />
                                {school.city}, {school.state} {school.zip_5}
                            </p>
                            <p>
                                <b>{lang === 'en' ? 'Phone' : 'Teléfono'}: </b>
                                <br />
                                {utils.formatPhone(school.phone)}
                            </p>
                            <p>
                                <b>{lang === 'en' ? 'Principal Name' : 'Nombre Principal'}:</b>
                                <br />
                                {school.principal}
                            </p>
                            {/* <p>
                                <b>Total Student Enrollment:</b>
                                <br />
                                {school.enrollment.toLocaleString()}
                            </p> */}

                            {!!school.website ? (
                                <Button
                                    variant='contained'
                                    href={'http://' + school.website}
                                    target='_blank'
                                    className={myClasses.overviewBtn}
                                >
                                    <LanguageIcon />
                                    {lang === 'en' ? 'School Website' : 'Sitio web de la escuela'}
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <div id='school_overview_map'>
                        <br />
                        <TxsMap1 entity={school} txsMapStyle={{ height: '31rem' }} />
                    </div>
                </Grid>
            </Grid>

            <div className={myClasses.grayHeader}>
                <img
                    src='./images/students_profile_white.png'
                    alt='school icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                />{' '}
                {lang === 'en' ? 'Student Information' : 'Información Estudiantil'} {dataSchool_Year}
            </div>

            {/*  <ChartjsRaceEthnicity entity={school} /> */}

            <div spacing={2}>
                {enrollMatchedList !== null && enrollMatchedList.length === 1 ? (
                    <ChartjsRaceEthnicity1 enroll={enrollMatchedList} lang={lang} />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.4rem' }}>
                    {/* '148' student_information modal*/}
                    <ModalEntity modalId={'148'} lang={lang} />
                    <span style={{ marginLeft: '8px' }}>
                        <Button variant='contained' color='primary' onClick={handleClicka}>
                            {lang === 'en' ? 'Dig IntoThe Data' : 'Profundice en los Datos'}
                        </Button>
                    </span>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: '#012169',
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                    padding: '1px 1px 7px 1px',
                    marginTop: '.4rem',
                }}
            >
                <img
                    src='./images/staff_info_white.png'
                    alt='staff icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                />{' '}
                {lang === 'en' ? 'Staff Information' : 'Información del Personal'} {dataSchool_Year}
            </div>

            <div spacing={2} style={{ marginBottom: '.4rem' }}>
                {enrollMatchedList !== null && enrollMatchedList.length === 1 ? (
                    <ChartjsRaceEthnicity2 enroll={enrollMatchedList} lang={lang} />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.4rem' }}>
                    {/* '149' staff_information modal*/}
                    <ModalEntity modalId={'149'} lang={lang} />
                    <span style={{ marginLeft: '8px' }}>
                        <Button variant='contained' color='primary' onClick={handleClickb}>
                            {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                        </Button>
                    </span>
                </div>
            </div>
            {!!school.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/distinction_white.png'
                        alt='distinction icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Distinction Designations' : 'Designaciones de Distinción'}
                </div>
            ) : null}

            {!!school.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.infoOdd}>
                    <div className={myClasses.sectionIcons}>
                        {school.distinctions[0] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_ela-' +
                                    (school.distinctions[0] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='ELA distinction icon'
                            />
                        ) : null}
                        {school.distinctions[1] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_math-' +
                                    (school.distinctions[1] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='math distinction icon'
                            />
                        ) : null}
                        {school.distinctions[2] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_science-' +
                                    (school.distinctions[2] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Science distinction icon'
                            />
                        ) : null}
                        {school.distinctions[3] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_socstudies-' +
                                    (school.distinctions[3] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Social Studies distinction icon'
                            />
                        ) : null}
                        {school.distinctions[4] !== 'X' ? (
                            <img
                                src={
                                    './images/acad_grow-' +
                                    (school.distinctions[4] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Academic Growth distinction icon'
                            />
                        ) : null}
                        {school.distinctions[5] !== 'X' ? (
                            <img
                                src={
                                    './images/postsec-' +
                                    (school.distinctions[5] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Postsecondary distinction icon'
                            />
                        ) : null}
                        {school.distinctions[6] !== 'X' ? (
                            <img
                                src={
                                    './images/ctg-' +
                                    (school.distinctions[6] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Closing the Gaps distinction icon'
                            />
                        ) : null}
                        <br />
                        <br />
                        {/* '142' distinction_designations modal*/}
                        <ModalEntity modalId={'142'} lang={lang} />
                    </div>
                </div>
            ) : null}

            {school.designation_cd.length > 0 ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/distinction_white.png'
                        alt='distinctions icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'School Designations' : 'Designaciones escolares'}
                </div>
            ) : null}

            {school.designation_cd.length > 0 ? (
                <div className={myClasses.infoEvend}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {school.designation_cd.includes('1') ? (
                            <div style={{ alignItems: 'center', justifyContent: 'center', fontSize: '1.1rem' }}>
                                <b>Purple Star School</b>
                                <br />
                                <img
                                    src='./images/Purple-Star-Campus-Designation-Logo.jpg'
                                    style={{
                                        height: '5rem',
                                        marginLeft: '2.2rem',
                                        marginTop: '1rem',
                                        marginBottom: '1rem',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    alt='Purple Star icon'
                                />
                            </div>
                        ) : null}

                        {school.designation_cd.includes('2') ? (
                            <div
                                style={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: '1.1rem',
                                    marginLeft: '3.5rem',
                                }}
                            >
                                <b>Blue Ribbon School</b>
                                <br />
                                <img
                                    src='./images/Blue-Ribbon-School-Logo.jpg'
                                    style={{
                                        height: '5rem',
                                        marginLeft: '2.9rem',
                                        marginTop: '1rem',
                                        marginBottom: '1rem',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                    alt='Blue Ribbon icon'
                                />
                            </div>
                        ) : null}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        {/* '152' school_designations modal*/}
                        <ModalEntity modalId={'152'} lang={lang} />
                    </div>{' '}
                </div>
            ) : null}

            {programs.length > 0 ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/ap_programs_white.png'
                        alt='AP programs icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Academic Programs' : 'Programas Academicos'}
                </div>
            ) : null}

            {programs.length > 0 ? (
                <div className={myClasses.infoEvenb}>
                    {layoutPrograms()}
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        {/* '145' academic_programs modal*/}
                        <ModalEntity modalId={'145'} lang={lang} />
                    </div>{' '}
                </div>
            ) : null}

            {school_ap.length > 0 ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/ap_white.png'
                        alt='AP programs icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Advanced Placement (AP) Course(s)' : 'Cursos de Colocación Avanzada (AP)'}
                </div>
            ) : null}

            {school_ap.length > 0 ? (
                <div className={myClasses.infoEvenb}>
                    {layoutAp()}
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        {/* '146' advanced_placement modal*/}
                        <ModalEntity modalId={'146'} lang={lang} />
                    </div>{' '}
                </div>
            ) : null}

            {school_uil.length > 0 ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/uil_white.png'
                        alt='UIL icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'UIL Activities' : 'Actividades de la Liga Interescolar Universitaria UIL'}
                </div>
            ) : null}

            {school_uil.length > 0 ? (
                <div className={myClasses.infoEvenb}>
                    {layoutUil()}
                    <br />
                    <div style={{ textAlign: 'center' }}>
                        {/* '147' uil_activities modal*/}
                        <ModalEntity modalId={'147'} lang={lang} />
                    </div>{' '}
                </div>
            ) : null}
        </div>
    );
};

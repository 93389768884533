import React, { createElement, useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Button, Hidden, IconButton } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import InfoIcon from '@material-ui/icons/Info';
import CardFilterAutosuggest from './cardFilterAutosuggest';
import CardAddressFilter from './cardAddressFilter';
import FilterList from '@material-ui/icons/FilterList';
import CardFilterMultiselect from './cardFilterMultiselect';
import CardFilterCheckBoxes from './cardFilterCheckBoxes';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#0d6cb9',
        padding: '1rem',
        marginRight: '.5rem',
        borderRadius: '.25rem',
    },
    toggleItem: {
        color: theme.palette.primary,
        border: '1px solid ' + theme.palette.text.primary,
        fontSize: 19,
        padding: 5,
        textTransform: 'none',
        '&$selected': {
            color: '#ffffff',
            backgroundColor: theme.palette.text.primary,
        },
    },
    selected: {
        color: '#ffffff',
        border: 0,
        fontSize: 19,
        fontWeight: 'bold',
        backgroundColor: '#000000',
    },
    centered: {
        margin: 'auto',
    },
    hr: {
        borderTopWidth: '1.5px',
        borderColor: '#000066',
    },
    filterGrid: {
        padding: 0,
        marginLeft: 5,
    },
    topFilterGrid: {
        padding: 0,
        marginLeft: 8,
        marginRight: '1rem',
    },
    filterLong: {
        padding: 0,
        minWidth: '300px',
    },
    schoolBadge: {
        marginTop: '10px',
        paddingLeft: 15,
    },
    schoolBadges: {
        paddingLeft: 15,
        fontSize: '1.5em',
        verticalAlign: 'middle',
    },
    clearButton: {
        backgroundColor: '#cc0000',
        marginLeft: '1rem',
        color: 'white',
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
        },
    },
    fieldset: {
        marginBottom: '.5rem',
        borderRadius: '.25rem',
        backgroundColor: 'white',
        padding: '.2rem',
    },
    infoIcon: {
        color: '#0F2F56',
        '&:hover': {
            color: theme.palette.muted.main,
        },
        position: 'absolute',
        right: '-.75rem',
        top: '-.75rem',
    },
    selBox: {
        minWidth: '300px',
    },
}));

/**
   * Component to show the filters and popup filters using standard filter components. This component
   * changes what filters are displayed based on the viewType
   * @param filters - current filters selected
   * @param srclist - The current list of values for drop down (English or spanish)
   * @param homeAddress - The address selected to find schools around or null if not selected
   * @param schools - JSON array of the school Entities
   * @param initialLoad - Is this the initial page prior to selection (true or false)
   * @param onChangeView - function to handle view change
   * @param onFilterChange - function to handle a new version of "filters"
   * @param onHomeAddressChange - function to handle selection/ update of home address for mapping schools
   * @example
   * <CardsFilters
        filters={filters}
        srclists={srclists}
        lng={lng}
        viewType={viewType} 
        homeAddress={homeAddress} 
        schools={schools}
        initialLoad={initialLoad}
        onChangeView={HandleChangeView}
        onFilterChange={HandleChangeFilter}
        onHomeAddressChange={HandleHomeAddressSelect} 
   
   */

const CardsFilters = ({
    lng,
    viewType,
    filters,
    homeAddress,
    srclists,
    schools,
    districts,
    initialLoad,
    onChangeView,
    onFilterChange,
    onHomeAddressChange,
}) => {
    const classes = useStyles();

    const handleFilterChange = (filter) => {
        let newFilters = filter || {};

        if (!!filter) {
            newFilters = Object.assign({}, filters);

            if (filter.selection === true) {
                newFilters[filter.filterName] = filter.selection;
            } else if (!filter.selection.length) {
                delete newFilters[filter.filterName];
            } else {
                newFilters[filter.filterName] = filter.selection;
            }
        }

        onFilterChange(newFilters);
    };

    const handleCBChange = (isChecked, filterName) => {
        handleFilterChange({ selection: isChecked, filterName: filterName });
    };

    return (
        <div className={classes.root}>
            <Typography style={{ marginBottom: '1rem', marginLeft: '1rem', color: 'white', fontSize: '1.5rem' }}>
                <b>{lng === 'en' ? 'Filter Your Search' : 'Filtra tu búsqueda'}</b>
            </Typography>
            {!!srclists.filters && viewType === 'schools' ? (
                <div className={classes.topFilterGrid}>
                    <CardFilterMultiselect
                        suggestions={srclists.grades}
                        label={srclists.filters.grades.title}
                        filterName='grades'
                        placeholder={srclists.filters.grades.placeholder}
                        value={filters.grades || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem', minWidth: '100px' }}
                    />
                    <CardFilterMultiselect
                        suggestions={srclists.districts}
                        label={srclists.filters.districts.title}
                        placeholder={srclists.filters.districts.placeholder}
                        filterName='districts'
                        value={filters.districts || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem', minWidth: '100px' }}
                    />
                    <Typography style={{ marginLeft: '1rem', color: 'white', fontSize: '1rem' }}>
                        <b>{lng === 'en' ? 'School Rating' : 'Calificaciones de la Escuela'}</b>
                    </Typography>
                    <fieldset className={classes.fieldset} style={{ position: 'relative' }}>
                        <CardFilterCheckBoxes
                            title=''
                            options={[
                                { label: 'A', value: 'A' },
                                { label: 'B', value: 'B' },
                                { label: 'C', value: 'C' },
                                { label: lng === 'en' ? 'Not Rated' : 'No Clasificado', value: 'Not Rated' },
                            ]}
                            filterName='rating'
                            selected={filters.rating || []}
                            labelMinWidth={'100px'}
                            onChange={handleFilterChange}
                        />
                    </fieldset>
                    <Typography style={{ marginLeft: '1rem', color: 'white', fontSize: '1rem' }}>
                        <b>{lng === 'en' ? 'School Offerings' : 'Ofertas escolares'}</b>
                    </Typography>

                    <fieldset className={classes.fieldset} style={{ position: 'relative' }}>
                        <CardFilterMultiselect
                            style={{ minWidth: '17rem' }}
                            suggestions={srclists.school_programs}
                            label={srclists.filters.school_programs.title}
                            filterName='school_programs'
                            placeholder={srclists.filters.school_programs.placeholder}
                            value={filters.school_programs || []}
                            onChange={handleFilterChange}
                        />
                        <CardFilterMultiselect
                            style={{ minWidth: '17rem' }}
                            suggestions={srclists.school_ap}
                            label={srclists.filters.school_ap.title}
                            filterName='school_ap'
                            placeholder={srclists.filters.school_ap.placeholder}
                            value={filters.school_ap || []}
                            onChange={handleFilterChange}
                        />
                        <CardFilterMultiselect
                            style={{ minWidth: '17rem' }}
                            suggestions={srclists.school_uil}
                            label={srclists.filters.school_uil.title}
                            filterName='school_uil'
                            placeholder={srclists.filters.school_uil.placeholder}
                            value={filters.school_uil || []}
                            onChange={handleFilterChange}
                        />
                        {/* <CardFilterCheckBoxes
                                title={srclists.filters.sat_act.title}
                                options={srclists.sat_act}
                                filterName='sat_act'
                                selected={filters.sat_act || []}
                                labelMinWidth={'125px'}
                                onChange={handleFilterChange}
                            /> */}
                        <IconButton
                            alt='More Information'
                            className={classes.infoIcon}
                            onClick={(evt) => {
                                window.open(
                                    'https://tea4avcastro.tea.state.tx.us/A-F/academic-prog-uil-ap-courses-school-day-testing.pdf',
                                    '_blank'
                                );
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </fieldset>

                    <fieldset className={classes.fieldset} style={{ position: 'relative' }}>
                        <CardFilterCheckBoxes
                            title={srclists.filters.entity_types.title}
                            options={srclists.entity_types}
                            filterName='entity_type'
                            selected={filters.entity_type || []}
                            labelMinWidth={'100px'}
                            onChange={handleFilterChange}
                        />
                        <CardFilterCheckBoxes
                            title={srclists.filters.type_cats.title}
                            options={srclists.type_cats}
                            filterName='type_cats'
                            selected={filters.type_cats || []}
                            labelMinWidth={'100px'}
                            onChange={handleFilterChange}
                        />
                        <CardFilterMultiselect
                            style={{ minWidth: '17rem' }}
                            suggestions={srclists.enrollment_types}
                            label={srclists.filters.enrollment_type.title}
                            filterName='enrollment_type_cd'
                            placeholder={srclists.filters.enrollment_type.placeholder}
                            value={filters.enrollment_type_cd || []}
                            onChange={handleFilterChange}
                        />

                        <IconButton
                            alt='More Information'
                            className={classes.infoIcon}
                            onClick={(evt) => {
                                window.open(
                                    'https://tea4avcastro.tea.state.tx.us/A-F/school-type-other-school-type-enrollment-type.pdf',
                                    '_blank'
                                );
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </fieldset>

                    <fieldset className={classes.fieldset} style={{ position: 'relative' }}>
                        <CardFilterCheckBoxes
                            title={srclists.filters.designations.title}
                            options={srclists.designations}
                            filterName='designations'
                            selected={filters.designations || []}
                            labelMinWidth={'100px'}
                            onChange={handleFilterChange}
                        />
                        <IconButton
                            alt='More Information'
                            className={classes.infoIcon}
                            onClick={(evt) => {
                                window.open(
                                    'https://tea4avcastro.tea.state.tx.us/A-F/school-rating-school-designations.pdf',
                                    '_blank'
                                );
                            }}
                        >
                            <InfoIcon />
                        </IconButton>
                    </fieldset>
                </div>
            ) : (
                <div className={classes.topFilterGrid} style={{ maxWidth: '30rem' }}>
                    <fieldset className={classes.fieldset}>
                        {/*     <CardFilterCheckBoxes
                            title='District Ratings'
                            options={[
                                { label: 'A', value: 'A' },
                                { label: 'B', value: 'B' },
                                { label: 'C', value: 'C' },
                                { label: 'D', value: 'D' },
                                { label: 'F', value: 'F' },
                            ]}
                            filterName='rating'
                            selected={filters.rating || []}
                            labelMinWidth={'125px'}
                            onChange={handleFilterChange}
                        /> */}
                        <CardFilterCheckBoxes
                            title={lng === 'en' ? 'District Type' : 'Tipo de Distrito'}
                            options={srclists.entity_types}
                            filterName='entity_type'
                            selected={filters.entity_type || []}
                            labelMinWidth={'125px'}
                            onChange={handleFilterChange}
                        />
                    </fieldset>
                    <CardFilterMultiselect
                        suggestions={srclists.regions}
                        label={srclists.filters.region_id.title}
                        filterName='region_id'
                        placeholder={srclists.filters.region_id.placeholder}
                        value={filters.region_id || []}
                        onChange={handleFilterChange}
                        style={{ marginBottom: '1rem' }}
                    />
                </div>
            )}

            <Button
                onClick={() => {
                    handleFilterChange();
                }}
                className={classes.clearButton}
            >
                {lng === 'en' ? 'Clear All Selections' : 'Borrar Todas las Selecciones'}
            </Button>
        </div>
    );
};
export default CardsFilters;

import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import CardFilterAutosuggest from './cardFilterAutosuggest';
import qs from 'querystringify';

const useStyles = makeStyles((theme) => ({
    header: {
        height: '4rem',
        backgroundColor: 'white',
        width: '100%',
        marginBottom: '1rem',
        '&:focus': {
            outline: 'green solid 3px',
        },
    },
    logo: { marginRight: '1rem' },
    barCenter: {
        width: '50%',
    },
    barBtn: {
        textAlign: 'center',
        fontSize: '1.5rem',
    },
    buttonNew: {
        fontSize: '1.1rem',
        fontWeight: 'Bold',
        color: theme.palette.primary.main,
    },
    lktxt: {
        fontSize: '1.1rem',
        fontWeight: 'Bold',
        color: theme.palette.primary.main,
    },
    barRight: {
        display: 'inherit',
        marginLeft: 'auto',
    },
    resourceText: {
        backgroundColor: 'white',
        color: theme.palette.text.primary,
    },
    lngBtn: {
        color: 'orange',
        backgroundColor: 'white',
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
        },
    },
    lngBtn2: {
        color: 'white',
        backgroundColor: theme.palette.tertiary.main,
        '&:hover': {
            backgroundColor: theme.palette.tertiary.dark,
        },
    },
}));

function Header({ lng, schools, districts, onChangeLng, onChangeView, onChangeId, onReset, showSearch }) {
    const myClasses = useStyles();

    const [menuAnchor, setMenuAnchor] = useState(null);

    const handleOnChangeLng = (event) => {
        const newlng = lng.toLowerCase() === 'en' ? 'es' : 'en';
        onChangeLng(newlng);
    };

    const handleSelectEntity = (entityObj) => {
        onReset();
        onChangeView(entityObj.filterName);
        onChangeId(entityObj.selection.id);
    };

    return (
        <AppBar id='header' position='static' className={myClasses.header}>
            <Toolbar>
                {/* <Hidden smDown>
                  <Link href='https://tea.texas.gov/' target='_blank'>
                    <img
                        className={myClasses.logo}
                        alt='Home'
                        title='Home'
                        src='./images/tea-logo-header-2_0.png'
                        width='50px'
                    />
                </Link>
                <span style={{ borderLeft: 'gray 2px solid', height: '25px', marginRight: '.5rem' }} />
               </Hidden>  */}
                <Button onClick={onReset} style={{ padding: 0, marginTop: '-.2rem', marginLeft: '1.5rem' }}>
                    <img
                        className={myClasses.logo}
                        alt='TX Schools'
                        title='TX Schools'
                        src='./images/txschools.gov.png'
                        width='165px'
                    />
                </Button>

                <Grid container className={myClasses.barCenter}>
                    <Hidden mdDown>
                        <Grid item xs={4} className={myClasses.barBtn}>
                            <Button
                                className={myClasses.buttonNew}
                                onClick={() => {
                                    onChangeView('schools');
                                }}
                            >
                                {lng === 'en' ? 'FIND SCHOOLS' : 'BUSCAR ESCUELAS'}
                            </Button>
                        </Grid>
                        <Grid item xs={4} className={myClasses.barBtn}>
                            <Button
                                className={myClasses.buttonNew}
                                onClick={() => {
                                    onChangeView('districts');
                                }}
                            >
                                {lng === 'en' ? 'FIND DISTRICTS' : 'BUSCAR DISTRITOS'}
                            </Button>
                        </Grid>
                        <Grid item xs={4} className={myClasses.barBtn}>
                            <Button
                                className={myClasses.buttonNew}
                                onClick={() => {
                                    window.open(
                                        'https://rptsvr1.tea.texas.gov/perfreport/account/va/va_reports_page.html',
                                        '_blank'
                                    );
                                }}
                            >
                                {lng === 'en' ? 'ANALYTIC TOOLS' : 'HERRAMIENTAS ANALÍTICAS'}
                            </Button>
                        </Grid>
                    </Hidden>
                    <Hidden lgUp>
                        <Grid item xs={12} className={myClasses.barBtn}>
                            <IconButton
                                onClick={(evt) => {
                                    setMenuAnchor(evt.currentTarget);
                                }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                anchorEl={menuAnchor}
                                keepMounted
                                open={!!menuAnchor}
                                onClose={() => {
                                    setMenuAnchor(null);
                                }}
                            >
                                <MenuItem
                                    onClick={() => {
                                        onChangeView('schools');
                                        setMenuAnchor(null);
                                    }}
                                >
                                    {lng === 'en' ? 'FIND SCHOOLS' : 'BUSCAR ESCUELAS'}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        onChangeView('districts');
                                        setMenuAnchor(null);
                                    }}
                                >
                                    {' '}
                                    {lng === 'en' ? 'FIND DISTRICTS' : 'BUSCAR DISTRITOS'}
                                </MenuItem>
                                <MenuItem
                                    onClick={() => {
                                        window.open(
                                            'https://rptsvr1.tea.texas.gov/perfreport/account/va/va_reports_page.html',
                                            '_blank'
                                        );
                                        setMenuAnchor(null);
                                    }}
                                >
                                    {lng === 'en' ? 'ANALYTIC TOOLS' : 'ANHERRAMIENTAS ANALÍTICAS'}
                                </MenuItem>
                                <MenuItem
                                    onClick={handleOnChangeLng} className={myClasses.lngBtn2}>
                                    {lng.toLowerCase() === 'en' ? 'Español' : 'English'}
                                </MenuItem> 
                            </Menu>
                        </Grid>
                    </Hidden>
                </Grid>

                <Hidden mdDown>
                    <span style={{ zIndex: '1200' }} className={myClasses.barRight}>
                        {!!showSearch && !!schools.length && !!districts.length ? (
                            <CardFilterAutosuggest
                                placeholder={lng === 'en' ? 'Search by Name' : 'Ingrese un Nombre'}
                                onChange={handleSelectEntity}
                                lng={lng}
                                suggestions={schools.concat(districts).sort((a, b) => a.name.localeCompare(b.name))}
                                inHeader={true}
                            />
                        ) : null}
                    </span>
                </Hidden>

                {/*   <Hidden lgUp>
                  <span style={{width: '48%', zIndex: '1200'}} className={myClasses.barRight}>
                     {!!showSearch && !!schools.length && !!districts.length ? (
                        <CardFilterAutosuggest
                            placeholder={lng === 'en' ? 'Search' : 'Ingrese un Nombre'}
                            onChange={handleSelectEntity}
                            lng={lng}
                            suggestions={schools.concat(districts).sort((a, b) => a.name.localeCompare(b.name))}
                            inHeader={true}
                        />
                    ) : null}  
                </span>
                </Hidden>  */}

                <Hidden mdDown>
                    <Link href='https://texasassessment.gov/' target='_blank' className={myClasses.lktxt}>
                        TexasAssessment.gov
                    </Link>
                    <span
                        style={{
                            borderLeft: 'gray 2px solid',
                            height: '25px',
                            marginRight: '.5rem',
                            marginLeft: '.5rem',
                        }}
                    />
                    <Button onClick={handleOnChangeLng} className={myClasses.lngBtn}>
                        {lng.toLowerCase() === 'en' ? 'En Español' : 'English'}
                    </Button> 
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

export default Header;

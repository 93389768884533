import { useEffect, useRef } from 'react';
import esriConfig from '@arcgis/core/config';
import Map from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Extent from '@arcgis/core/geometry/Extent';
import PictureMarkerSymbol from '@arcgis/core/symbols/PictureMarkerSymbol';
import pins from '../components/pins';
esriConfig.apiKey =
    'AAPK12fec448bbfe4c1c9ccd27e36902cd26cJ-_5p8LAfZ0g5H8m124js7O2OKeSGvEqdXLJdN-VJ3FPEY1-WJKNbbP35lGoy-N';

export default function TxsMap1({ entity, entities, txsMapStyle }) {
    const entitiesId = [];
    let index;
    const entityDefault = { longitude: -99.314038, latitude: 31.122445 };
    let condition = 0;
    let entityName;
    if (!!entity) {
        entitiesId.push(entity.id);
        entityName = entity;
        condition = 1; // single entity, entity overview.
    } else if (!!entities[0].id && entities.length === 1) {
        entityName = entities[0];
        entitiesId.push(entities[0].id);
        condition = 1; // single entity, after filter.
    } else if (!!entities && entities.length > 1) {
        entityName = entities[0];
        for (index in entities) {
            entitiesId.push(entities[index].id);
        }
        condition = 2; // multiple entities.
    } else {
        entityName = entityDefault;
        condition = 0; // no entity after filter, or too many entity, all fall into default.
    }
    // arcgis template --------------
    const entityTemplate = {
        lastEditInfoEnabled: false,
        content: [
            {
                type: 'fields',
                fieldInfos: [
                    {
                        fieldName: 'rating',
                        label: 'Overall Rating:',
                    },
                    {
                        fieldName: 'name',
                        label: 'Name:',
                    },
                    {
                        fieldName: 'district_name',
                        label: 'District Name:',
                    },
                    {
                        fieldName: 'expression/enrollment-type-expr',
                        label: 'Enrollment Type:',
                    },
                    {
                        fieldName: 'address',
                        label: 'Address:',
                    },
                    {
                        fieldName: 'expression/grades-served-expr',
                        label: 'Grades Served:',
                    },
                ],
            },
            {
                type: 'text',
                text:
                    '<b>{expression/has-website}</b> <a href=https://{expression/website-expr}>{expression/website-expr}</a>',
            },
        ],
        expressionInfos: [
            {
                name: 'grades-served-expr',
                title: 'Grades Served:',
                expression: '$feature.low_grade + "-" + $feature.high_grade',
            },
            {
                name: 'enrollment-type-expr',
                title: 'Enrollment Type:',
                expression: 'IIF(!IsEmpty($feature.campus_type), $feature.campus_type, "District")',
            },
            {
                name: 'website-expr',
                title: 'Website:',
                expression: 'IIF(!IsEmpty($feature.website), $feature.website, null)',
            },
            {
                name: 'has-website',
                expression: 'IIf(!IsEmpty($feature.website), "Website: ", "No website found.")',
            },
            {
                name: 'has-website',
                expression: 'IIf(!IsEmpty($feature.website), "Website: ", "No website found.")',
            },
        ],
    };

    // uvrRenderer ------------
    const uvrRenderer = {
        type: 'unique-value', // autocasts as new UniqueValueRenderer()
        field: 'rating_cd',

        defaultSymbol: pins(0),

        uniqueValueInfos: [
            {
                value: 'A',
                symbol: pins(1),
            },
            {
                value: 'B',
                symbol: pins(2),
            },
            {
                value: 'C',
                symbol: pins(3),
            },
        ],
    };
    // setting up arcgis map --------------
    //  D for district layer, C for school layer;
    const entityLayerUrl =
        entityName.entity_cd === 'D'
            ? 'https://services2.arcgis.com/5MVN2jsqIrNZD4tP/arcgis/rest/services/TXschools_Entity_D_view/FeatureServer'
            : 'https://services2.arcgis.com/5MVN2jsqIrNZD4tP/arcgis/rest/services/TXschools_Entity_C_view/FeatureServer';
    const map = new Map({ basemap: 'osm' });
    const entityLayer = new FeatureLayer({
        popupTemplate: entityTemplate,
        url: entityLayerUrl,
        renderer: uvrRenderer,
        labelingInfo: [],
    });
    map.add(entityLayer);
    const mapDiv = useRef(null);
    const field = 'id';
    useEffect(() => {
        const view = new MapView({
            map: map,
            container: mapDiv.current,
        });
        // switch based on different conditions -------------------
        switch (condition) {
            case 0:
                {
                    view.center = [entityName.longitude, entityName.latitude];
                    view.zoom = 6;
                    view.whenLayerView(entityLayer).then((entityLayerView) => {
                        entityLayerView.filter = {
                            where: `False`,
                        };
                    });
                }
                break;
            case 1:
                {
                    view.center = [entityName.longitude, entityName.latitude];
                    view.zoom = 17;
                    const entity_id = entityName.id;
                    const filterExpression = `${field} = '${entity_id}'`;
                    view.whenLayerView(entityLayer).then((entityLayerView) => {
                        entityLayerView.filter = {
                            where: filterExpression,
                        };
                    });
                }
                break;
            case 2: {
                const ids_x = entities.map((item) => {
                    return item.longitude;
                });
                const ids_y = entities.map((item) => {
                    return item.latitude;
                });
                const myExtent = new Extent();
                const xmin = Math.min(...ids_x);
                const ymin = Math.min(...ids_y);
                const xmax = Math.max(...ids_x);
                const ymax = Math.max(...ids_y);
                const delta = 0.2;
                myExtent.xmin = xmin - (xmax - xmin) * delta;
                myExtent.xmax = xmax + (xmax - xmin) * delta;
                myExtent.ymin = ymin - (ymax - ymin) * delta;
                myExtent.ymax = ymax + (ymax - ymin) * delta;
                view.goTo(myExtent);
                const entitiesIdJoined = entitiesId.join("', '");
                const filterExpression = `${field} IN ('${entitiesIdJoined}')`;
                view.whenLayerView(entityLayer).then((entityLayerView) => {
                    entityLayerView.filter = {
                        where: filterExpression,
                    };
                });
            }
        }
    });
    return <div id='viewDiv' ref={mapDiv}  style={txsMapStyle}></div>;
}

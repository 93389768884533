import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import utils from '../components/utils';
import { Button, Grid, Typography, Hidden, Link, IconButton, Input, InputAdornment } from '@material-ui/core';
import { Clear } from '@material-ui/icons';
import SchoolCard from './schoolCard';
import DistrictCard from './districtCard';
import ResponsiveTable from './responsiveTable';
import LocationOn from '@material-ui/icons/LocationOn';
import geodist from 'geodist';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.primary,
    },
    pin: {
        color: theme.palette.muted.main,
        '&:hover': {
            color: theme.palette.muted.dark,
        },
    },
    nameFilter: {
        '&:placeholder': {
            fontSize: '14',
            fontColor: 'red',
        },
        marginLeft: '1rem',
        borderRadius: '.35rem',
        border: '1px solid ' + theme.palette.primary.main,
        width: '18rem',
        //   fontWeight: '700',
        marginTop: '1rem',
    },

    inputxy: {
        '&::placeholder': {
            fontSize: '14',
            fontColor: 'red',
        },
    },
}));

/** 
 * Present a list of the schools or districts either in table or card format
 * @param lng - Current language
 * @param entities - JSON with the entities (school or district to be displayed)
 * @param viewType - the view selected "schools" or "districts" 
 * @param homeAddress - If defined, the value of the homeAddress selected in the CardAddressFilter
 * @param schoolPrograms - The json array with the school_program (spanish or english)
 * @param schools - The JSON array with the schools list
 * @param district - The JSON array with the districts list
 * @param zoomMap - The function to call to zoom the map to the selected school by clicking on the address pin.
 * @example
 * <CardsList
      lng={lng}
      entities={entities}
      viewType={viewType}
      homeAddress={homeAddress}
      schoolPrograms={schoolPrograms}
      schoolAP={schoolAp}
      srclists={srclists}
      schools={schools}
      districts={districts}
      onZoomMap={zoomMap}
    />
 *
*/
const ROWS_PER_PAGE = 15;

const CardsList = ({
    lng,
    schoolPrograms,
    schoolAp,
    schoolUil,
    homeAddress,
    viewType,
    entities,
    srclists,
    onZoomMap,
    onFilter,
}) => {
    const classes = useStyles();

    const [pages, setPages] = useState(1);
    const [currpage, setCurrpage] = useState(1);
    const [nameFilter, setNameFilter] = useState('');
    const [filtered, setFiltered] = useState([]);

    /* update things that may change */
    useEffect(() => {
        const _filtered = entities.filter((_entity) => _entity.name.toLowerCase().includes(nameFilter.toLowerCase()));

        setPages(Math.ceil(0.5 + _filtered.length / ROWS_PER_PAGE));
        setCurrpage(1);
        setFiltered(_filtered);
        onFilter(_filtered);
    }, [srclists, lng, entities, nameFilter, viewType, onFilter]);

    const gradeRange = (start, end) => {
        const startGrade = utils.translateCode2Label(start, srclists.grades);
        const endGrade = utils.translateCode2Label(end, srclists.grades);
        return startGrade === endGrade ? startGrade : startGrade + ' - ' + endGrade;
    };

    const HandlePageChange = (e, newpage) => {
        setCurrpage(newpage);
    };

    const schoolColumns = () => {
        const columns = [
            {
                id: 'name',
                label: lng === 'en' ? 'School Name' : 'Nombre de la escuela',
                format: (row, column) => {
                    return (
                        <div>
                            <a
                                href={process.env.REACT_APP_BASEPATH + '?view=school&id=' + row.id + '&lng=' + lng}
                                target='_blank'
                            >
                                {row.name}
                            </a>
                        </div>
                    );
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'district_name',
                label: lng === 'en' ? 'School District' : 'Distrito Escolar',
                format: (row, column) => {
                    return (
                        <a
                            href={
                                process.env.REACT_APP_BASEPATH + '?view=district&id=' + row.district_id + '&lng=' + lng
                            }
                            target='_blank'
                        >
                            {row.district_name}
                        </a>
                    );
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'address',
                label: lng === 'en' ? 'Street Address' : 'Dirección',
                format: (row, column) => {
                    return (
                        <div>
                            {row.address}
                            {/* <IconButton
                                onClick={() => {
                                    onZoomMap(row.id, 'school');
                                }}
                                className={classes.pin}
                            >
                                <LocationOn /> 
                            </IconButton> */}
                        </div>
                    );
                },
                minWidth: 15,
                align: 'left',
            },
            {
                id: 'grades_served',
                label: lng === 'en' ? 'Grades Served' : 'Grados cumplidos',
                format: (row, column) => {
                    return gradeRange(row.low_grade_cd, row.high_grade_cd);
                },
                minWidth: 15,
                align: 'left',
            },
            {
                id: 'score',
                label: lng === 'en' ? 'Overall Rating' : 'Puntuación general',
                format: (row, column) => {
                    if (row.rating === 'Not Rated' && lng === 'en') {
                        return 'Not Rated';
                    }
                    if (row.rating === 'Not Rated' && lng === 'es') {
                        return 'No Clasificado';
                    }
                    if (row.rating === 'K') {
                        return 'Acceptable';
                    }
                    {
                        return row.rating;
                    }
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
        ];
        if (homeAddress !== null) {
            columns.push({
                id: 'distance',
                label: lng === 'en' ? 'Distance' : 'Distancia',
                format: (row, column) => {
                    return row.distance.toString() + (lng === 'en' ? ' miles' : ' millas');
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            });
        }
        return columns;
    };
    const districtColumns = () => {
        return [
            {
                id: 'name',
                label: lng === 'en' ? 'School District' : 'Distrito Escolar',
                format: (row, column) => {
                    return (
                        <a
                            href={process.env.REACT_APP_BASEPATH + '?view=district&id=' + row.id + '&lng=' + lng}
                            target='_blank'
                        >
                            {row.name}
                        </a>
                    );
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'county',
                label: lng === 'en' ? 'County' : 'Nombre del Condado',
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'region',
                label: lng === 'en' ? 'Region Name' : 'Nombre de la Región',
                format: (row, column) => {
                    return <p>{row.region}</p>;
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'entity_type',
                label: lng === 'en' ? 'District Type' : 'Tipo de Distrito',
                format: (row, column) => {
                    return row.entity_type_cd === 'T' ? 'Traditional' : 'Open-enrollment Charter';
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'address',
                label: lng === 'en' ? 'Street Address' : 'Dirección',
                // format: (row, column) =>
                //     {
                //       return <div>{row.Address}<a  href={"/districts/"+row.ID}><LocationOn/></a></div>},
                minWidth: 15,
                align: 'left',
            },
            {
                id: 'score',
                label: lng === 'en' ? 'Overall Score' : 'Puntuación General',
                format: (row, column) => {
                    // return row.rating === 'Not Rated' ? 'Not Rated' : row.rating + ' / ' + row.score;

                    if (row.rating === 'Not Rated' && lng === 'en') {
                        return 'Not Rated';
                    }
                    if (row.rating === 'Not Rated' && lng === 'es') {
                        return 'No Clasificado';
                    }
                    {
                        return row.rating + ' / ' + row.score;
                    }

                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
        ];
    };

    return (
        <div>
            <span style={{ display: 'flex', marginBottom: '.95rem' }}>
                <Input
                    value={nameFilter}
                    onChange={(evt) => {
                        setNameFilter(evt.target.value);
                    }}
                    placeholder={
                        lng === 'en'
                            ? 'Filter by ' + (viewType === 'schools' ? 'School' : 'District') + ' Name'
                            : 'Filtrar por Nombre de ' + (viewType === 'schools' ? 'la Escuela' : 'Distritos')
                    }
                    endAdornment={
                        !!nameFilter ? (
                            <InputAdornment position='end'>
                                <Button
                                    onClick={() => {
                                        setNameFilter('');
                                    }}
                                    aria-label='clear name filter'
                                >
                                    <Clear />
                                </Button>
                            </InputAdornment>
                        ) : null
                    }
                    className={classes.nameFilter}
                />
                <br />
            </span>
            <Grid container direction='row' spacing={1} alignItems='stretch'>
                <Hidden mdUp>
                    {!filtered.length
                        ? null
                        : (homeAddress === null
                              ? filtered
                              : utils.sortIt(utils.addDistance2Schools(filtered, homeAddress), [
                                    { key: 'distance', direction: 'ASC' },
                                    { key: 'Name', direction: 'ASC' },
                                ])
                          ).map((entity, index) => {
                              let shown = true;
                              const lowerlimit = (currpage - 1) * ROWS_PER_PAGE;
                              const upperlimit = currpage * ROWS_PER_PAGE - 1;
                              if (index > upperlimit || index < lowerlimit) {
                                  shown = false;
                              }
                              if (shown === true) {
                                  return (
                                      <Grid item key={'schoolCard' + index} xs={12} md={6} lg={4}>
                                          {entity.entity_cd === 'D' ? (
                                              <DistrictCard district={entity} lng={lng} onZoomMap={onZoomMap} />
                                          ) : (
                                              <SchoolCard
                                                  school={entity}
                                                  onZoomMap={onZoomMap}
                                                  lng={lng}
                                                  srclists={srclists}
                                                  homeAddress={homeAddress}
                                                  schoolPrograms={schoolPrograms}
                                                  schoolAp={schoolAp}
                                                  schoolUil={schoolUil}
                                              />
                                          )}
                                      </Grid>
                                  );
                              }
                          })}
                </Hidden>
                <Hidden smDown>
                    {viewType === 'schools' ? (
                        filtered.length ? (
                            <ResponsiveTable
                                rows={filtered}
                                page={currpage - 1}
                                rowsPerPage={ROWS_PER_PAGE}
                                count={pages}
                                columns={schoolColumns()}
                                sortBy={homeAddress === null ? 'name' : 'distance'}
                                columnOrder='asc'
                            />
                        ) : (
                            <Typography>
                                {lng === 'en'
                                    ? 'No schools found matching the criteria you selected. Please change your selections.'
                                    : 'No se encontró ninguna escuela que coincida con los criterios que seleccionó. Por favor, cambie sus selecciones.'}
                            </Typography>
                        )
                    ) : filtered.length ? (
                        <ResponsiveTable
                            rows={
                                homeAddress === null
                                    ? filtered
                                    : utils.sortIt(utils.addDistance2Schools(filtered, homeAddress), [
                                          { key: 'distance', direction: 'asc' },
                                          { key: 'name', direction: 'asc' },
                                      ])
                            }
                            page={currpage - 1}
                            rowsPerPage={ROWS_PER_PAGE}
                            count={pages}
                            columns={districtColumns()}
                            sortBy='name'
                            columnOrder='asc'
                        />
                    ) : (
                        <Typography>
                            No districts found matching the criteria you selected. Please change your selections.
                        </Typography>
                    )}
                </Hidden>
            </Grid>
            <span style={{ display: 'flex', marginTop: '.45rem' }}>
                {pages < 2 ? null : (
                    <Pagination
                        count={pages}
                        page={currpage}
                        onChange={HandlePageChange}
                        shape='rounded'
                        showFirstButton
                        showLastButton
                    />
                )}{' '}
            </span>
        </div>
    );
};

export default CardsList;

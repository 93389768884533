import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import utils from '../../components/utils';
import { Modal } from 'react-bootstrap';
import '../../bootstrap_m.css';
import ModalEntity from '../../components/modalEntity';
import ChartjsBulletFin from '../../components/chartjsBulletFin';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, ChartDataLabels, Legend);
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    blueFunds: {
        color: theme.palette.primary.main,
        textAlign: 'center',
        fontSize: '2.5rem',
        fontWeight: 'bold',
        padding: '25px 5px ',
    },
    tabsWrapper: {
        textAlign: 'center',
        fontSize: '1.5rem',
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        fontWeight: 'bold',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    gradepass: {
        color: '#CA313C',
    },
    grade: {
        backgroundColorcolor: '#FFFFFF',
    },
    ratingpassnote: {
        fontSize: '0.8rem',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    ratingpass: {
        fontSize: '3.5rem',
        color: 'Gray',
        fontWeight: 'bold',
    },
    Gradeheadera: {
        fontSize: '7rem',
        color: '#278143',
        padding: '14px 0px 0px 51px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '7rem',
        color: '#39398C',
        padding: '14px 0px 0px 56px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '7rem',
        color: '#9C118B',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '7rem',
        color: '#E0803D',
        padding: '14px 0px 0px 54px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '7rem',
        color: '#CA313C',
        padding: '14px 0px 0px 58px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.6rem',
        color: 'black',
        padding: '30px 0px 0px 34px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    gradenr: {
        color: '#51555A',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    header: {
        fontSize: '1rem',
        color: theme.palette.secondary.main,
    },
    header2: {
        fontSize: '1rem',
        textAlign: 'center',
    },
    subHeader: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '1.8rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },
    grayHeader: {
        backgroundColor: '#51555A',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.3rem',
        fontWeight: 'bold',
        marginBottom: '.7rem',
    },
    perfBoxr: {
        textAlign: 'center',
        borderRight: '.1px solid ' + theme.palette.primary.main,
    },
    perfBox2: {
        textAlign: 'center',
        align: 'center',
    },
    sectionButton: {
        marginTop: '.5rem',
        width: '12rem',
        marginBottom: '.8rem',
    },
    sectionButtont: {
        marginTop: '-1rem',
        width: '12rem',
        marginBottom: '.8rem',
    },
    sectionButtonb: {
        marginTop: '.5rem',
        width: '12rem',
        marginBottom: '.8rem',
    },
    perftar: {
        textAlign: 'center',
    },
    perfWrapper: {},
    perfHeader: {
        color: theme.palette.secondary.main,
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.6rem',
    },
    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },
    frmszeaa: {
        width: '100%',
        height: '100%',
        border: '0',
    },
    frmszeaaa: {
        width: '100%',
        height: '69%',
        border: '0',
    },
    frmszeab: {
        width: '100%',
        height: '72%',
        border: '0',
        minHeight: '62vh',
    },
    frmszeac: {
        width: '100%',
        height: '60%',
        border: '0',
        minHeight: '31vh',
    },
    frmszead: {
        width: '100%',
        height: '68%',
        border: '0',
    },
    buttonbar: {
        textAlign: 'center',
        fontFamily: 'Arial',
        fontSize: '12pt',
        color: '#000000',
        fontWeight: 'bold',
        paddingTop: '10px',
    },
    aButton: {
        transform: 'scale(1.3)',
        cursor: 'pointer',
    },
}));
export default ({ district }) => {
    utils.setHomeTitle(!!district && district.name + ' | Finance');
    const myClasses = useStyles();
    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }
    const _Level = lang === 'en' ? 'Level' : 'Nivel';
    const _School = lang === 'en' ? 'School' : 'Escuela';
    const _Peer = lang === 'en' ? 'Peer' : 'Par';
    const _District = lang === 'en' ? 'District' : 'Distrito';
    const _State = lang === 'en' ? 'State' : 'Estado';
    const _Revenue = lang === 'en' ? 'Revenue' : 'Ingresos';
    const _Expenditure = lang === 'en' ? 'Expenditure' : 'Gasto';
    const _Debt = lang === 'en' ? 'Debt' : 'Deuda';
    const _OutOf = lang === 'en' ? 'out of' : 'de';
    const _Pass = lang === 'en' ? 'Pass' : 'Pasar';

    const [value, setValue] = React.useState(1);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const [finance, setFinance] = useState([]);
    useEffect(() => {
        fetch('../data/finance_district.json')
            .then((resp) => resp.json())
            .then((json) => {
                setFinance(json);
            });
    }, []);
    // use district id to filter out data.
    const financeMatchedList = finance.filter((item) => {
        return item.id === district.id;
    });
    const checkMatchedList = financeMatchedList.length === 0;
    const datascoreListDistrict = checkMatchedList ? '' : financeMatchedList[0].score.toString();
    const datarating_cdListDistrict = checkMatchedList ? '' : financeMatchedList[0].rating_cd;

    const datapeer_revenue_minListDistrict = checkMatchedList ? null : financeMatchedList[0].peer_revenue_min;
    const datapeer_revenue_aveListDistrict = checkMatchedList ? null : financeMatchedList[0].peer_revenue_ave;
    const datapeer_revenue_maxListDistrict = checkMatchedList ? null : financeMatchedList[0].peer_revenue_max;
    const datapeer_revenue_per_studentListDistrict = checkMatchedList
        ? null
        : financeMatchedList[0].peer_revenue_per_student;

    const datarevenue_allListDistrict = checkMatchedList ? null : financeMatchedList[0].revenue_all;
    const dataexpenditures_allListDistrict = checkMatchedList ? null : financeMatchedList[0].expenditures_all;
    const labelsYear = checkMatchedList ? [null, null, null, null] : financeMatchedList[0].year;
    const datarevenue_districtListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].revenue_district;
    const datarevenue_peerListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].revenue_peer;
    const datarevenue_stateListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].revenue_state;
    const dataexpenditure_districtListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_district;
    const dataexpenditure_peerListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_peer;
    const dataexpenditure_stateListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].expenditure_state;
    const datadebt_districtListDistrict = checkMatchedList
        ? [null, null, null, null]
        : financeMatchedList[0].debt_district;
    const datadebt_stateListDistrict = checkMatchedList ? [null, null, null, null] : financeMatchedList[0].debt_state;
    const datarevenue = {
        labels: labelsYear,
        datasets: [
            {
                finType: _Revenue,
                label: _District,
                data: datarevenue_districtListDistrict,
                borderColor: '#ECAF33',
                backgroundColor: '#ECAF33',
                datalabels: {
                    labels: {
                        value: {
                            color: '#ECAF33',
                        },
                    },
                },
            },
            {
                finType: _Revenue,
                label: _Peer,
                data: datarevenue_peerListDistrict,
                borderColor: '#56B7E6',
                backgroundColor: '#56B7E6',
                datalabels: {
                    labels: {
                        value: {
                            color: '#56B7E6',
                        },
                    },
                },
            },
            {
                finType: _Revenue,
                label: _State,
                data: datarevenue_stateListDistrict,
                borderColor: '#548235',
                backgroundColor: '#548235',
                datalabels: {
                    labels: {
                        value: {
                            color: '#548235',
                        },
                    },
                },
            },
        ],
    };
    const dataexpenditure = {
        labels: labelsYear,
        datasets: [
            {
                finType: _Expenditure,
                label: _District,
                data: dataexpenditure_districtListDistrict,
                borderColor: '#ECAF33',
                backgroundColor: '#ECAF33',
                datalabels: {
                    labels: {
                        value: {
                            color: '#ECAF33',
                        },
                    },
                },
            },
            {
                finType: _Expenditure,
                label: _Peer,
                data: dataexpenditure_peerListDistrict,
                borderColor: '#56B7E6',
                backgroundColor: '#56B7E6',
                datalabels: {
                    labels: {
                        value: {
                            color: '#56B7E6',
                        },
                    },
                },
            },
            {
                finType: _Expenditure,
                label: _State,
                data: dataexpenditure_stateListDistrict,
                borderColor: '#548235',
                backgroundColor: '#548235',
                datalabels: {
                    labels: {
                        value: {
                            color: '#548235',
                        },
                    },
                },
            },
        ],
    };
    const datadebt = {
        labels: labelsYear,
        datasets: [
            {
                finType: _Debt,
                label: _District,
                data: datadebt_districtListDistrict,
                borderColor: '#ECAF33',
                backgroundColor: '#ECAF33',
                datalabels: {
                    labels: {
                        value: {
                            color: '#ECAF33',
                        },
                    },
                },
            },
            {
                finType: _Debt,
                label: _State,
                data: datadebt_stateListDistrict,
                borderColor: '#548235',
                backgroundColor: '#548235',
                datalabels: {
                    labels: {
                        value: {
                            color: '#548235',
                        },
                    },
                },
            },
        ],
    };
    // line chart setup
    const options = {
        spanGaps: true,
        radius: 5,
        pointStyle: 'circle',
        responsive: true,
        layout: {
            padding: {
                top: 20,
                left: 100,
                right: 100,
            },
        },
        scales: {
            x: {
                backgroundColor: 'white',
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
            y: {
                display: false,
                grid: {
                    display: false,
                },
                suggestedMin: 10000,
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (context) {
                        let labelLevel = 'Level:   ';
                        let labelFinType = 'Financial Type:   ';
                        let labelAmount = 'Per Student:   ';
                        if (context.dataset.label !== null) {
                            labelLevel += context.dataset.label;
                        }
                        if (context.dataset.finType !== null) {
                            labelFinType += context.dataset.finType;
                        }
                        if (context.parsed.y !== null) {
                            labelAmount = labelAmount + '$' + new Intl.NumberFormat().format(context.parsed.y);
                        }
                        return [labelLevel, labelFinType, labelAmount];
                    },
                },
                displayColors: false,
                titleColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white',
            },
            legend: {
                title: {
                    display: true,
                    text: _Level,
                },
                labels: {
                    usePointStyle: true,
                    PointStyle: 'line',
                },
                position: 'bottom',
                onClick: function (e, legendItem) {
                    const index = legendItem.datasetIndex;
                    const ci = this.chart;
                    ci.data.datasets.forEach(function (e, i) {
                        const meta = ci.getDatasetMeta(i);
                        if (i !== index) {
                            ci.data.datasets[i].radius = 5;
                        } else if (i === index) {
                            ci.data.datasets[i].radius = 10;
                        }
                    });
                    ci.update();
                },
            },
            datalabels: {
                display: 'auto',
                clamp: true,
                anchor: 'center',
                align: 'top',
                formatter: (val) => `\$${Intl.NumberFormat().format(val)}`,
                font: {
                    size: 14,
                    weight: 'bold',
                },
            },
        },
    };
    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
    }
    const handleClick1 = () => {
        window.open(
            'https://rptsvr1.tea.texas.gov/cgi/sas/broker?_service=marykay&_program=sfadhoc.actual_report_2021.sas&_service=appserv&_debug=0&who_box=&who_list=' +
                district.id
        );
    };
    const handleClick2 = () => {
        window.open(
            'https://tea.texas.gov/finance-and-grants/state-funding/additional-finance-resources/fiscal-peer-groups'
        );
    };
    const handleClick3 = () => {
        window.open(
            'https://tea.texas.gov/finance-and-grants/financial-accountability/financial-integrity-rating-system-of-texas-first/financial-integrity-rating-system-of-texas'
        );
    };
    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }
    const packurl3 = process.env.REACT_APP_BASEPATH + '/reports/finance';
    const [showrea, setShowrea] = useState(false);
    const handleCloserea = () => {
        setShowrea(!showrea);
    };
    const [showrec, setShowrec] = useState(false);
    const handleCloserec = () => {
        setShowrec(!showrec);
    };
    const [showret, setShowret] = useState(false);
    const handleCloseret = () => {
        setShowret(!showret);
    };
    const [showres, setShowres] = useState(false);
    const handleCloseres = () => {
        setShowres(!showres);
    };
    return (
        <Paper style={{ padding: '.5rem' }}>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/finance_white.png'
                    alt='finance Icon'
                    style={{ height: '2.5rem', marginRight: '.5rem', marginTop: '.2rem' }}
                />{' '}
                {lang === 'en' ? 'FINANCE ' : 'FINANZAS'}
            </div>
            <Grid container spacing={1}>
                <Grid item xs={12} md={4} className={myClasses.perfBoxr}>
                    <br />
                    <br />
                    <div className={myClasses.perfWrapper}>
                        <b className={myClasses.perfHeader}>
                            {lang === 'en' ? 'FIRST Rating' : 'PRIMERA Calificación'}{' '}
                        </b>
                    </div>
                    {!!datarating_cdListDistrict && datarating_cdListDistrict !== 'Pass' ? (
                        <>
                            <br />
                            <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                <b
                                    id='gradea'
                                    className={myClasses['Gradeheader' + datarating_cdListDistrict.toLowerCase()]}
                                >
                                    {datarating_cdListDistrict}
                                </b>
                                <CircularProgress
                                    className={myClasses.Graycircle}
                                    variant='determinate'
                                    value={100}
                                    size={185}
                                />
                                <CircularProgress
                                    className={myClasses['grade' + datarating_cdListDistrict.toLowerCase()]}
                                    variant='determinate'
                                    value={parseFloat(datascoreListDistrict, 10)}
                                    size={185}
                                />
                            </div>
                            <b className={myClasses.subHeader}>
                                <span className={myClasses['grade' + datarating_cdListDistrict.toLowerCase()]}>
                                    {datascoreListDistrict} {_OutOf} 100{' '}
                                </span>
                            </b>
                        </>
                    ) : (
                        <>
                            <br />
                            <b className={myClasses.subHeader}>
                                <span className={myClasses.ratingpass}>{_Pass}</span>
                                <br />
                                <br />

                                <span className={myClasses.ratingpassnote}>
                                    {lang === 'en'
                                        ? 'The pie chart contains all zero or negative values and cannot be displayed.'
                                        : 'El gráfico circular contiene todos los valores cero o negativos y no se puede mostrar.'}
                                </span>
                            </b>
                            <br />
                        </>
                    )}
                    <br />
                    <br />
                    <div style={{ textAlign: 'center', marginTop: '1rem' }}>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ModalEntity entity={district} modalId='137' lang={lang} />
                            <span style={{ marginLeft: '8px' }}>
                                <Button variant='contained' color='primary' onClick={handleClick3}>
                                    {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                                </Button>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid
                    item
                    alignItems='center'
                    xs={12}
                    md={8}
                    className={myClasses.perfBox2}
                    style={{ marginTop: '2rem' }}
                >
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} centered className={myClasses.tabsWrapper}>
                                <Tab
                                    label={lang === 'en' ? 'Revenue' : 'Ingreso'}
                                    {...a11yProps(0)}
                                    classes={{ selected: myClasses.selected }}
                                />
                                <Tab
                                    label={lang === 'en' ? 'Expenditure' : 'Gasto'}
                                    {...a11yProps(1)}
                                    classes={{ selected: myClasses.selected }}
                                />
                                <Tab
                                    label={lang === 'en' ? 'Debt' : 'Deuda'}
                                    {...a11yProps(2)}
                                    classes={{ selected: myClasses.selected }}
                                />
                            </Tabs>
                        </Box>
                        <TabPanel value={value} index={0}>
                            <Chart type='line' options={options} data={datarevenue} />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Chart type='line' options={options} data={dataexpenditure} />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Chart type='line' options={options} data={datadebt} />
                        </TabPanel>
                    </Box>
                    <div
                        style={{
                            textAlign: 'center',
                            marginTop: '1rem',
                        }}
                    >
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <ModalEntity entity={district} modalId='133' lang={lang} />
                            <span style={{ marginLeft: '8px' }}>
                                <Button variant='contained' color='primary' onClick={handleClick1}>
                                    {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                                </Button>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={4} className={myClasses.perfBoxr}>
                    <div className={myClasses.grayHeader}>
                        <img
                            src='./images/totals_finance_white.png'
                            alt='finance totals Icon'
                            style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                        />{' '}
                        {lang === 'en' ? 'Totals' : 'Totales'}
                    </div><br/>
                    <b style={{ fontSize: '1.3rem' }}>
                        {' '}
                        {lang === 'en' ? 'Revenue - All Funds' : 'Ingresos – Todos los fondos'}
                    </b>
                    <div className={myClasses.blueFunds}>
                        ${Intl.NumberFormat().format(datarevenue_allListDistrict)}
                    </div>
                    <b style={{ fontSize: '1.3rem' }}>
                        {' '}
                        {lang === 'en' ? 'Expenditures - All Funds' : 'Gastos – Todos los fondos'}
                    </b>
                    <div className={myClasses.blueFunds}>
                        ${Intl.NumberFormat().format(dataexpenditures_allListDistrict)}
                    </div><br/><br/>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <ModalEntity entity={district} modalId='135' lang={lang} />
                    </div>
                </Grid>
                <Grid item xs={12} md={8} className={myClasses.perfBox2}>
                    <div className={myClasses.grayHeader}>
                        <img
                            src='./images/peer_finance_white.png'
                            alt='finance peers Icon'
                            style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                        />{' '}
                        {lang === 'en' ? 'Peer Comparisons' : 'Comparaciones entre Compañeros'}
                    </div>
                    <ChartjsBulletFin
                        dataMin={datapeer_revenue_minListDistrict}
                        dataMax={datapeer_revenue_maxListDistrict}
                        dataAve={datapeer_revenue_aveListDistrict}
                        dataRev={datapeer_revenue_per_studentListDistrict}
                        lang={lang}
                    />
                    <p style={{marginTop: '-3rem'}} >
                    <ModalEntity entity={district} modalId='132' lang={lang} />
                    <span style={{ marginLeft: '8px' }}>
                        <Button variant='contained' color='primary' onClick={handleClick2}>
                            {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                        </Button>
                    </span></p>
                </Grid>
            </Grid>
        </Paper>
    );
};

import React, { createElement } from 'react';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';

export default () => {
    return (
      <br/>
        // <Grid container spacing={2} style={{ margin: '2rem 0 .5rem 0' }}>
        //    <Grid item xs={12} md={4} lg={2}>
        //        <a href='https://txparentportal.emetric.net/login' target='_blank'>
        //            <img src='./images/TexasAssessment.png' alt='emetric portal' width='200px' />
        //        </a>
        //        <Hidden smDown>
        //            <span style={{ borderLeft: '1px solid #000', margin: '1rem', height: '20px' }} />
         //       </Hidden>
        //    </Grid>
        //    <Grid item xs={12} md={8} lg={10}>
        //        <a href='https://txparentportal.emetric.net/login' target='_blank'>
        //            Log In
        //        </a>{' '}
        //        to the secure TexasAssessment.gov Family Portal to learn more about your child's assessment scores and view custom
        //        resources for your child.
        //    </Grid>
       // </Grid>
    );
};

import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import ClearIcon from '@material-ui/icons/Clear';

import ResponsiveTable from '../../components/responsiveTable';
import utils from '../../components/utils';

const useStyles = makeStyles((theme) => ({
    nameFilter: {
        marginRight: 'auto',
        borderRadius: '.25rem',
        border: '1px solid ' + theme.palette.primary.main,
        width: '15rem',
        marginLeft: '.2rem',
    },
}));

export default ({ district, schools, srclists, lng }) => {
    utils.setHomeTitle(!!district && district.name + ' | Schools');

    const myClasses = useStyles();

    const [nameFilter, setNameFilter] = useState('');
    const [filtered, setFiltered] = useState([]);

    /* useEffect(() => {
        setNameFilter('');
        setFiltered(schools);
    }, [schools, nameFilter]);
    */

    useEffect(() => {
        const _filtered = schools.filter((_school) => _school.name.toLowerCase().includes(nameFilter.toLowerCase()));
        setFiltered(_filtered);
    }, [nameFilter, schools, setFiltered]);

    const gradeRange = (start, end) => {
        const startGrade = utils.translateCode2Label(start, srclists.grades);
        const endGrade = utils.translateCode2Label(end, srclists.grades);
        return startGrade === endGrade ? startGrade : startGrade + ' - ' + endGrade;
    };

    const schoolColumns = () => {
        const columns = [
            {
                id: 'name',
                label: lng === 'en' ? 'School Name' : 'Nombre de la escuela',
                format: (row, column) => {
                    return (
                        <div>
                            <a
                                href={process.env.REACT_APP_BASEPATH + '?view=school&id=' + row.id + '&lng=' + lng}
                                target='_blank'
                            >
                                {row.name}
                            </a>
                        </div>
                    );
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
            {
                id: 'address',
                label: lng === 'en' ? 'Street Address' : 'Dirección',
                format: (row, column) => row.address,
                minWidth: 15,
                align: 'left',
            },
            {
                id: 'grades_served',
                label: lng === 'en' ? 'Grades Served' : 'Grados cumplidos',
                format: (row, column) => {
                    return gradeRange(row.low_grade_cd, row.high_grade_cd);
                },
                minWidth: 15,
                align: 'left',
            },
            {
                id: 'score',
                label: lng === 'en' ? 'Overall Score' : 'Puntuación general',
                format: (row, column) => {
                    if (row.rating === 'Not Rated') {
                        return 'Not Rated';
                    }
                    if (row.rating === 'K') {
                        return 'Acceptable';
                    }
                    {
                        return row.rating;
                    }
                },
                minWidth: 15,
                align: 'left',
                sortable: true,
            },
        ];
        return columns;
    };

    return (
        <div>
            <span style={{ display: 'flex', marginBottom: '.2rem', marginTop: '.2rem' }}>
                <Input
                    value={nameFilter}
                    onChange={(evt) => {
                        setNameFilter(evt.target.value);
                    }}
                    placeholder={lng === 'en' ? 'Filter by School Name' : 'Filtrar por Nombre de la Escuela'}
                    endAdornment={
                        !!nameFilter ? (
                            <InputAdornment position='end'>
                                <Button
                                    onClick={() => {
                                        setNameFilter('');
                                    }}
                                    aria-label='clear name filter'
                                >
                                    <ClearIcon />
                                </Button>
                            </InputAdornment>
                        ) : null
                    }
                    className={myClasses.nameFilter}
                />
            </span>
            <br />
            <ResponsiveTable
                rows={filtered}
                page={0}
                rowsPerPage={1000}
                count={1}
                columns={schoolColumns()}
                sortBy={'name'}
                columnOrder='asc'
            />
        </div>
    );
};

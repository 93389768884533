import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { CircularProgress } from '@material-ui/core';
import utils from '../../components/utils';
import '../../bootstrap_m.css';
import DtAccountability from '../../components/dtAccountability';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
    },
    subHeader: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    subHeadernr: {
        fontSize: '3rem',
    },

    pairLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },

    gridItem: {
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        borderRadius: '.25rem',
        textAlign: 'center',
    },

    perfGridItem: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfBox: {
        margin: '.5rem',
        border: '.1px solid ' + theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfWrapper: {},
    perfHeader: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.primary.main,
        minHeight: '4rem',
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.7rem',
    },
    perfIcon: {
        height: '3.5rem',
        float: 'left',
        padding: '.5rem',
    },
    perfText: {
        textAlign: 'left',
        padding: '.5rem',
        [theme.breakpoints.up('md')]: {
            minHeight: '5rem',
        },
        [theme.breakpoints.down('sm')]: {
            minHeight: '5rem',
        },
    },
    perfButton: {
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '13rem',
    },
    lasButton: {
        marginBottom: '2rem',
        width: '17rem',
        textAlign: 'center',
        align: 'center',
    },
    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },

    frmszeb: {
        minHeight: '17rem',
        height: '65%',
        width: '100%',
        margin: 'auto',
        border: '0',
    },
    frmszea: {
        height: '100%',
        width: '100%',
        margin: '0',
        border: 'none',
    },
    sectionbbb: {
        position: 'relative',
        height: '100vh',
    },
    sectionButton: {
        width: '18rem',
    },
    section: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.tertiary,
    },

    sectionf: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },

    sectionIcons: {
        margin: '1rem',
        textAlign: 'center',
    },
    dIcons: {
        textAlign: 'center',
        height: '300px',
        width: '300px',
    },
    sectionIcon: {
        width: '190px',
        height: '180px',
    },
    gradelas: {
        color: 'blue',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    gradenr: {
        color: '#51555A',
    },
    Gradeheadera: {
        fontSize: '6rem',
        color: '#278143',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderlas: {
        fontSize: '6rem',
        color: 'blue',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '6rem',
        color: '#39398C',
        padding: '14px 0px 0px 53px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '6rem',
        color: '#9C118B',
        padding: '14px 0px 0px 49px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '6rem',
        color: '#E0803D',
        padding: '14px 0px 0px 53px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '6rem',
        color: '#CA313C',
        padding: '14px 0px 0px 57px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.6rem',
        color: 'black',
        padding: '30px 0px 0px 34px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.7rem',
        color: 'black',
        padding: '45px 0px 0px 22px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrs: {
        fontSize: '2.4rem',
        color: 'black',
        padding: '27px 0px 0px 31px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    subHeadernrspm: {
        fontSize: '2rem',
        color: 'black',
        padding: '27px 0px 0px 31px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrssp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 21px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderna: {
        fontSize: '5.5rem',
        marginTop: '5rem',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
}));

export default ({ school, las, paired, onPair, onPaird, onNav }) => {
    utils.setHomeTitle(!!school && school.name + ' | Accountability Overview');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://vyuprodva.ondemand.sas.com';
    }
    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use school id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === school.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdOverPerfOrder2 = getContentId(dynModMatchedList, 'overall_performance');

    // map rating_cd to modal ID
    const getModalId = (rating_cd) => {
        const modalMap = { A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' };
        return modalMap[rating_cd];
    };

    const filtered = las.filter((obj) => obj.id === school.id);
    const ld1gf = filtered.map((obj) => {
        return obj.ld1g;
    });
    const ld2gf = filtered.map((obj) => {
        return obj.ld2g;
    });
    const ld3gf = filtered.map((obj) => {
        return obj.ld3g;
    });
    const ld4gf = filtered.map((obj) => {
        return obj.ld4g;
    });
    const ld5gf = filtered.map((obj) => {
        return obj.ld5g;
    });
    const ld1sf = filtered.map((obj) => {
        return obj.ld1s;
    });
    const ld2sf = filtered.map((obj) => {
        return obj.ld2s;
    });
    const ld3sf = filtered.map((obj) => {
        return obj.ld3s;
    });
    const ld4sf = filtered.map((obj) => {
        return obj.ld4s;
    });
    const ld5sf = filtered.map((obj) => {
        return obj.ld5s;
    });
    const l_score = filtered.map((obj) => {
        return obj.las_score;
    });
    const l_rating = filtered.map((obj) => {
        return obj.las_rating;
    });

    const [showov, setShowov] = useState(false);
    const handleCloseov = () => {
        setShowov(!showov);
    };
    // const handleShowov = () => setShowov(true);

    const [showla, setShowla] = useState(false);
    const handleClosela = () => {
        setShowla(!showla);
    };
    // const handleShowla = () => setShowla(true);

    const [showlc, setShowlc] = useState(false);
    const handleCloselc = () => {
        setShowlc(!showlc);
    };
    // const handleShowlc = () => setShowlc(true);

    const [showdis, setShowdis] = useState(false);
    const handleClosedis = () => {
        setShowdis(!showdis);
    };
    // const handleShowdis = () => setShowdis(true);

    const [showld, setShowld] = useState(false);
    const handleCloseld = () => {
        setShowld(!showld);
    };
    // const handleShowld = () => setShowld(true);

    const [showdd, setShowdd] = useState(false);
    const handleClosedd = () => {
        setShowdd(!showdd);
    };
    // const handleShowdd = () => setShowdd(true);

    const perfBoxa = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {school.d1g === 'Not Rated' && !school.d1s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : school.d1g === 'Not Rated' && school.d1s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    {lang === 'en' ? (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrs}>
                                Not
                                <br />
                                Rated
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d1s, 10)}
                                size={165}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrssp}>
                                No
                                <br />
                                Clasificado
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d1s, 10)}
                                size={165}
                            />
                        </div>
                    )}
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}>
                            {school.d1s}
                            {lang === 'en' ? ' out of' : ' de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + school.d1g.toLowerCase()]}>
                            {school.d1g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + school.d1g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(school.d1s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + school.d1g.toLowerCase()]}>
                            {' '}
                            {school.d1s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBoxb = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {school.d2g === 'Not Rated' && !school.d2s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Progress icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />

                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : school.d2g === 'Not Rated' && school.d2s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    {lang === 'en' ? (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrs}>
                                Not
                                <br />
                                Rated
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d2s, 10)}
                                size={165}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrssp}>
                                No
                                <br />
                                Clasificado
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d2s, 10)}
                                size={165}
                            />
                        </div>
                    )}
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}>
                            {' '}
                            {school.d2s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Progress icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + school.d2g.toLowerCase()]}>
                            {school.d2g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + school.d2g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(school.d2s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + school.d2g.toLowerCase()]}>
                            {' '}
                            {school.d2s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBoxc = (imgSrc, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            {school.d3g === 'Not Rated' && !school.d3s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Closing the gaps icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : school.d3g === 'Not Rated' && school.d3s ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Achievement icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    {lang === 'en' ? (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrs}>
                                Not
                                <br />
                                Rated
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d3s, 10)}
                                size={165}
                            />
                        </div>
                    ) : (
                        <div
                            style={{
                                position: 'relative',
                                backgroundColor: '#FFFFFF',
                                height: '11rem',
                                margin: 'auto',
                            }}
                        >
                            <b id='gradea' className={myClasses.Gradeheadernrssp}>
                                No
                                <br />
                                Clasificado
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses.gradenr}
                                variant='determinate'
                                value={parseFloat(school.d3s, 10)}
                                size={165}
                            />
                        </div>
                    )}
                    <span className={myClasses.subHeader}>
                        <span className={myClasses.gradenr}> {school.d3s} out of 100</span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Closing the gaps icon' />
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + school.d3g.toLowerCase()]}>
                            {school.d3g}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + school.d3g.toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(school.d3s, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + school.d3g.toLowerCase()]}>
                            {' '}
                            {school.d3s} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                </div>
            )}
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBox = (sdkId, imgSrc, reportId, reportObj, title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    <img src={'./images/' + imgSrc} className={myClasses.perfIcon} alt='Accountability icon' />
                    <p style={{ padding: '.5rem' }}>{title}</p>
                </div>
                <sas-report-object
                    id={sdkId}
                    authenticationType='guest'
                    url={vaserver}
                    reportUri={'/reports/reports/' + reportId}
                    objectName={reportObj}
                    style={{ height: '15rem', margin: 'auto' }}
                />

                <p className={myClasses.perfText}>{text}</p>
            </div>
            <Button
                variant='contained'
                onClick={() => {
                    window.scrollTo(0, 0);
                    onNav(navPage);
                }}
                className={myClasses.perfButton}
            >
                {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
            </Button>
        </Box>
    );

    const perfBoxla = (title, text) => (
        <Box className={myClasses.perfBox}>
            {/*  {ld1gf === '' ? (  */}
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    <p style={{ padding: '.5rem' }}>{title}</p>
                </div>
                <br />
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                    <b id='gradea' className={myClasses.Gradeheaderna}>
                        N/A
                    </b>
                </div>
                <span className={myClasses.subHeader}>
                    <span className={myClasses.gradea}> &nbsp; </span>{' '}
                </span>
                <br />
                <div className={myClasses.perfText}>{text}</div>
                {/* '155' academics_local_accountability modal 
	Local Accountability Academics	*/}
                <ModalEntity modalId={'155'} lang={lang} />
            </div>
            {/*  ) : ( 
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    <p style={{ padding: '.5rem' }}>{title}</p>
                </div>
                <br />
   	         <div style={{position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto'}}>
		   <b id="gradea" className={myClasses['Gradeheader'+ ld1gf.toString().toLowerCase()]}>{ld1gf}</b>
                   <CircularProgress className={myClasses.Graycircle} variant="determinate" value={100} size={165}/>
		   <CircularProgress className={myClasses['grade'+ ld1gf.toString().toLowerCase()]} variant="determinate" value={parseFloat(ld1sf,10)} size={165}/>
                 </div>
		 <span className={myClasses.subHeader}><span className={myClasses['grade'+ ld1gf.toString().toLowerCase()]}> {ld1sf} out of 100</span></span> 
		<br />
                <div className={myClasses.perfText}>{text}</div>
            </div> 
         ) }  */}
        </Box>
    );

    const perfBoxlb = (title, text) => (
        <Box className={myClasses.perfBox}>
            {ld2gf === '' ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                    {/* '153' culture_climate_local_accountability modal
		    a. Campus Staff Engagement 
		    b. Parent/Guardian Satisfaction */}
                    <ModalEntity modalId={'153'} lang={lang} />
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + ld2gf.toString().toLowerCase()]}>
                            {ld2gf}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + ld2gf.toString().toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(ld2sf, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + ld2gf.toString().toLowerCase()]}>
                            {' '}
                            {ld2sf} {lang === 'en' ? 'out of' : 'de'} 100{' '}
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                    {/* '153' culture_climate_local_accountability 
				a. Campus Staff Engagement 
				b. Parent/Guardian Satisfaction
		    modal*/}
                    <ModalEntity modalId={'153'} lang={lang} />
                </div>
            )}
        </Box>
    );

    const perfBoxlc = (title, text) => (
        <Box className={myClasses.perfBox}>
            {ld5gf === '' ? (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                    </div>
                    <div className={myClasses.perfText}>{text}</div>
                    {/* '154' locally_determined_local_accountability modal
                          Student Classroom Experience */}
                    <ModalEntity modalId={'154'} lang={lang} />
                </div>
            ) : (
                <div className={myClasses.perfWrapper}>
                    <div className={myClasses.perfHeader}>
                        <p style={{ padding: '.5rem' }}>{title}</p>
                    </div>
                    <br />
                    <div style={{ position: 'relative', backgroundColor: '#FFFFFF', height: '11rem', margin: 'auto' }}>
                        <b id='gradea' className={myClasses['Gradeheader' + ld5gf.toString().toLowerCase()]}>
                            {ld5gf}
                        </b>
                        <CircularProgress
                            className={myClasses.Graycircle}
                            variant='determinate'
                            value={100}
                            size={165}
                        />
                        <CircularProgress
                            className={myClasses['grade' + ld5gf.toString().toLowerCase()]}
                            variant='determinate'
                            value={parseFloat(ld5sf, 10)}
                            size={165}
                        />
                    </div>
                    <span className={myClasses.subHeader}>
                        <span className={myClasses['grade' + ld5gf.toString().toLowerCase()]}>
                            {' '}
                            {ld5sf} {lang === 'en' ? 'out of' : 'de'} 100
                        </span>
                    </span>
                    <br />
                    <div className={myClasses.perfText}>{text}</div>
                    {/* '154' locally_determined_local_accountability modal
                          Student Classroom Experience */}
                    <ModalEntity modalId={'154'} lang={lang} />
                </div>
            )}
        </Box>
    );

    return (
        <div>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/overview2_white.png'
                    alt='Accountability icon'
                    style={{ height: '2.6rem', marginRight: '.5rem', marginTop: '.7rem', marginBottom: '-.3rem' }}
                />{' '}
                {lang === 'en' ? 'ACCOUNTABILITY OVERVIEW' : 'VISIÓN GENERAL DE DESEMPEÑO'}{' '}
            </div>
            <Grid container>
                {/* Grid handled 3 ways: Not Rated, Acceptable, all else.  Acceptable condition should be removed for 22 ratings */}

                {school.rating_cd === 'X' || school.rating_cd === 'Z' ? (
                    <Grid item xs={12} md={6} className={myClasses.gridItem}>
                        <br />
                        <b className={myClasses.subHeader}>
                            {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                        </b>
                        <br />
                        <br />
                        <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                            <b className={myClasses.subHeadernr}>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</b>
                        </div>
                        <br />
                        <br />

                        {!!paired && paired.id !== school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />
                                <br />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPair} className={myClasses.pairLink}>
                                    {paired.name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {!!paired && paired.id === school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />
                                <br />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPaird} className={myClasses.pairLink}>
                                    {paired.district_name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {school.las_flag === 'Y' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/las_icon.png'
                                    alt='Local Accountability school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />
                                This district has an approved local accountability plan.
                            </p>
                        ) : null}
                        {school.alt_standards === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'Alternative standards were used to rate this school.'
                                    : 'Se utilizaron estándares alternativos para calificar esta escuela.'}
                                &nbsp;
                                {school.aea_bonus === 'Yes'
                                    ? 'Bonus points for an Alternate Education school have been applied.'
                                    : null}
                            </p>
                        ) : null}
                        {school.appeal === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/info_icon_navy.png'
                                    alt='AEA school icon'
                                    style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'A rating or scaled score was changed due to an appeal. The underlying data remains the same.'
                                    : 'Se cambió una calificación o puntuación escalonada debido a una apelación. Los datos subyacentes siguen siendo los mismos.'}
                            </p>
                        ) : null}
                        {school.peg === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                {/*  <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />{' '} */}
                                {lang === 'en'
                                    ? 'This school is identified as a Public Education Grant (PEG) school. '
                                    : 'PEG translate '}
                                <a
                                    target='_blank'
                                    href='https://tea.texas.gov/texas-schools/accountability/academic-accountability/performance-reporting/public-education-grant'
                                >
                                    {lang === 'en' ? 'Learn more about PEG.' : 'PEG translate'}
                                </a>
                            </p>
                        ) : null}
                        {school.mult_year === '2' || school.mult_year === '3' || school.mult_year === '4' || school.mult_year === '5' || school.mult_year === '6' || school.mult_year === '7' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/info_icon_navy.png'
                                    alt='AEA school icon'
                                    style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'This campus has had an unacceptable rating for '  
                                    : 'Este campus ha tenido una calificación inaceptable durante '  }
                                {school.mult_year} 
                              {lang === 'en' ? ' years.' : ' años.' }
                            </p>
                        ) : null}

                        <br />
                        <p style={{ fontSize: '1rem' }}>
                            {lang === 'en'
                                ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                                : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                        </p>
                        <br />
                        {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                        {/* modal=overall_performance */}
                        <ModalEntity modalId={getModalId(school.rating_cd)} modalId2={mIdOverPerfOrder2} lang={lang} />
                    </Grid>
                ) : school.rating_cd === 'U' || school.rating_cd === 'Q' ? (
                    <Grid item xs={12} sm={6} className={myClasses.gridItem}>
                        <br />
                        <b className={myClasses.subHeader}>
                            {' '}
                            {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                        </b>
                        <br />
                        <br />
                        {lang === 'en' ? (
                            <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                <b id='gradea' className={myClasses.Gradeheadernr}>
                                    {lang === 'en' ? 'Not' : 'No'}
                                    <br />
                                    {lang === 'en' ? 'Rated' : 'Clasificado'}
                                </b>
                                <CircularProgress
                                    className={myClasses.Graycircle}
                                    variant='determinate'
                                    value={100}
                                    size={185}
                                />
                                <CircularProgress
                                    className={myClasses.gradenr}
                                    variant='determinate'
                                    value={parseFloat(school.score, 10)}
                                    size={185}
                                />
                            </div>
                        ) : (
                            <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                <b id='gradea' className={myClasses.Gradeheadernrsp}>
                                    {lang === 'en' ? 'Not' : 'No'}
                                    <br />
                                    {lang === 'en' ? 'Rated' : 'Clasificado'}
                                </b>
                                <CircularProgress
                                    className={myClasses.Graycircle}
                                    variant='determinate'
                                    value={100}
                                    size={185}
                                />
                                <CircularProgress
                                    className={myClasses.gradenr}
                                    variant='determinate'
                                    value={parseFloat(school.score, 10)}
                                    size={185}
                                />
                            </div>
                        )}

                        <b className={myClasses.subHeader}>
                            <span className={myClasses['grade' + school.rating.toLowerCase()]}>
                                {' '}
                                {school.score} {lang === 'en' ? 'out of' : 'de'} 100
                            </span>
                        </b>
                        <br />
                        {school.rating_cd === 'U' ? (
                           <p style={{ fontSize: '1.2rem' }}>
                            <img
                                src='./images/info_icon_navy.png'
                                alt='not rated school icon'
                                style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                            />
                            &nbsp;
                            {lang === 'en'
                                ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                            <br />
                        </p>
                       ) : null}
                        {school.rating_cd === 'Q' ? (
                           <p style={{ fontSize: '1.2rem' }}>
                            <img
                                src='./images/exclamation_point.jpg'
                                alt='not rated school icon'
                                style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                            />
                            &nbsp;
                            {lang === 'en'
                                ? 'This campus is Not Rated: Data Under Review.'
                                : 'Este campus no Está Clasificado: Datos Bajo Revisión.'}
                            <br />
                        </p>
                       ) : null}
                        {!!paired && paired.id !== school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPair} className={myClasses.pairLink}>
                                    {paired.name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {!!paired && paired.id === school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPaird} className={myClasses.pairLink}>
                                    {paired.district_name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {school.las_flag === 'Y' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/las_icon.png'
                                    alt='Local Accountability school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />
                                This district has an approved local accountability plan.
                            </p>
                        ) : null}
                        {school.alt_standards === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />
                                {lang === 'en'
                                    ? 'Alternative standards were used to rate this school.'
                                    : 'Se utilizaron estándares alternativos para calificar esta escuela.'}
                                &nbsp;
                                {school.aea_bonus === 'Yes'
                                    ? 'Bonus points for an Alternate Education school have been applied.'
                                    : null}
                            </p>
                        ) : null}
                        {school.peg === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                {/*  <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />{' '} */}
                                {lang === 'en'
                                    ? 'This school is identified as a Public Education Grant (PEG) school. '
                                    : 'PEG translate '}
                                <a
                                    target='_blank'
                                    href='https://tea.texas.gov/texas-schools/accountability/academic-accountability/performance-reporting/public-education-grant'
                                >
                                    {lang === 'en' ? 'Learn more about PEG.' : 'PEG translate'}
                                </a>
                            </p>
                        ) : null}
                        {school.mult_year === '2' || school.mult_year === '3' || school.mult_year === '4' || school.mult_year === '5' || school.mult_year === '6' || school.mult_year === '7' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/info_icon_navy.png'
                                    alt='AEA school icon'
                                    style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'This campus has had an unacceptable rating for '  
                                    : 'Este campus ha tenido una calificación inaceptable durante '  }
                                {school.mult_year} 
                              {lang === 'en' ? ' years.' : ' años.' }
                            </p>
                        ) : null}


                        <p style={{ fontSize: '1rem' }}>
                            {lang === 'en'
                                ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                                : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                        </p>
                        <br />
                        {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                        {/* modal=overall_performance */}
                        <ModalEntity modalId={getModalId(school.rating_cd)} modalId2={mIdOverPerfOrder2} lang={lang} />
                    </Grid>
                ) : (
                    <Grid item xs={12} md={6} className={myClasses.gridItem}>
                        <br />
                        <b className={myClasses.subHeader}>
                            {' '}
                            {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                        </b>
                        <br />
                        <br />
                        <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                            <b id='gradea' className={myClasses['Gradeheader' + school.rating.toLowerCase()]}>
                                {school.rating}
                            </b>
                            <CircularProgress
                                className={myClasses.Graycircle}
                                variant='determinate'
                                value={100}
                                size={165}
                            />
                            <CircularProgress
                                className={myClasses['grade' + school.rating.toLowerCase()]}
                                variant='determinate'
                                value={parseFloat(school.score, 10)}
                                size={165}
                            />
                        </div>
                        <span className={myClasses.subHeader}>
                            <span className={myClasses['grade' + school.rating.toLowerCase()]}>
                                {' '}
                                {school.score} {lang === 'en' ? 'out of' : 'de'} 100
                            </span>
                        </span>

                        {!!paired && paired.id !== school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPair} className={myClasses.pairLink}>
                                    {paired.name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {!!paired && paired.id === school.id ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/paired_campus_icon_green.png'
                                    alt='paired school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />
                                &nbsp;{lang === 'en' ? 'This campus is paired with' : 'Este campus está emparejado con'}
                                &nbsp;
                                <Link onClick={onPaird} className={myClasses.pairLink}>
                                    {paired.district_name}
                                </Link>
                                <br />
                            </p>
                        ) : null}
                        {school.las_flag === 'Y' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/las_icon.png'
                                    alt='Local Accountability school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />{' '}
                                This district has an approved local accountability plan.
                            </p>
                        ) : null}
                        {school.alt_standards === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-1rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'Alternative standards were used to rate this school.'
                                    : 'Se utilizaron estándares alternativos para calificar esta escuela.'}
                                &nbsp;
                                {school.aea_bonus === 'Yes'
                                    ? 'Bonus points for an Alternate Education school have been applied.'
                                    : null}
                            </p>
                        ) : null}
                        {school.appeal === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/info_icon_navy.png'
                                    alt='AEA school icon'
                                    style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'A rating or scaled score was changed due to an appeal. The underlying data remains the same.'
                                    : 'Se cambió una calificación o puntuación escalonada debido a una apelación. Los datos subyacentes siguen siendo los mismos.'}
                            </p>
                        ) : null}
                        {school.peg === 'Yes' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                {/*  <img
                                    src='./images/aea_icon.png'
                                    alt='AEA school icon'
                                    style={{ height: '3rem', marginTop: '-2rem' }}
                                />{' '} */}
                                {lang === 'en'
                                    ? 'This school is identified as a Public Education Grant (PEG) school. '
                                    : 'PEG translate '}
                                <a
                                    target='_blank'
                                    href='https://tea.texas.gov/texas-schools/accountability/academic-accountability/performance-reporting/public-education-grant'
                                >
                                    {lang === 'en' ? 'Learn more about PEG.' : 'PEG translate'}
                                </a>
                            </p>
                        ) : null}
                        {school.mult_year === '2' || school.mult_year === '3' || school.mult_year === '4' || school.mult_year === '5' || school.mult_year === '6' || school.mult_year === '7' ? (
                            <p style={{ fontSize: '1.2rem' }}>
                                <img
                                    src='./images/info_icon_navy.png'
                                    alt='AEA school icon'
                                    style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                />{' '}
                                {lang === 'en'
                                    ? 'This campus has had an unacceptable rating for '  
                                    : 'Este campus ha tenido una calificación inaceptable durante '  }
                                {school.mult_year} 
                              {lang === 'en' ? ' years.' : ' años.' }
                            </p>
                        ) : null}


                        <br />
                        <p style={{ fontSize: '1rem' }}>
                            {lang === 'en'
                                ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                                : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                        </p>
                        <br />
                        {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                        {/* modal=overall_performance */}
                        <ModalEntity modalId={getModalId(school.rating_cd)} modalId2={mIdOverPerfOrder2} lang={lang} />
                    </Grid>
                )}

                {/* end 3 way grid conditional */}

                <Grid item xs={12} md={6} className={myClasses.gridItem}>
                    <br />
                    <b className={myClasses.subHeader}>
                        {lang === 'en' ? 'Change Over Time' : 'Cambio a lo largo del Tiempo'}
                    </b>
                    <br />
                    <br />

                    <div>
                        {' '}
                        <DtAccountability entity={school} />{' '}
                    </div>

                    {/* <iframe className={myClasses.frmszeb}
                       src={'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' + [window.ccyy] + '&id=' + school.id + '&lng=' + lang + '&prgopt=txs/acct_calc.sas'
                                } />  */}

                    <p style={{ fontSize: '.7rem', margin: '0% 10% 0%' }}>
                        {' '}
                        {lang === 'en'
                            ? '* Given the impact of COVID-19, all districts and schools received a label of Not Rated: Declared State of Disaster for their 2019-20 and 2020-21 accountability ratings.'
                            : '* Dado el impacto de COVID-19, todos los distritos y escuelas recibieron una etiqueta de No Calificado: Declarado Estado de Desastre para sus calificaciones de responsabilidad 2019-20 y 2020-21.'}
                    </p>
                    <p style={{ fontSize: '1rem' }}>
                        {lang === 'en'
                            ? 'This shows how overall performance at the school has changed over time.'
                            : 'Esto muestra cómo el desempeño general en la escuela ha cambiado con el tiempo. '}
                    </p>
                </Grid>
            </Grid>

            <div className={myClasses.grayHeader}>
                <img
                    src='./images/students_profile_white.png'
                    alt='Student profile icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem', marginRight: '.7rem' }}
                />
                {lang === 'en' ? 'Overall Performance Details' : 'Detalles Generales del Desempeño'}
            </div>

            <Grid container>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxa(
                        'icon-school-achievement_white.png',
                        lang === 'en' ? 'Student Achievement' : 'Logro Estudiantil',
                        lang === 'en'
                            ? 'Student Achievement measures whether students met expectations on the STAAR test. It also measures graduation rates and how prepared students are for success after high school.'
                            : 'El Logro Estudiantil mide si los estudiantes cumplieron con las expectativas en el examen STAAR. También mide el porcentaje de graduados y qué tan preparados están los estudiantes para el éxito después de la escuela secundaria.',
                        'achievement'
                    )}
                </Grid>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxb(
                        'icon-school-progress.png',
                        lang === 'en' ? 'School Progress' : 'Progreso Escolar',
                        lang === 'en'
                            ? "School Progress shows how students perform over time and how the school's performance compares to other schools with similar economically disadvantaged student populations."
                            : 'El Progreso Escolar muestra cómo se desempeñan los estudiantes a lo largo del tiempo y cómo el desempeño de las escuelas se compara con otras escuelas con poblaciones estudiantiles económicamente desfavorecidas similares.',
                        'progress'
                    )}
                </Grid>
                <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                    {perfBoxc(
                        'icon-closing-gap.png',
                        lang === 'en' ? 'Closing the Gaps' : 'Cierre de Diferencias',
                        lang === 'en'
                            ? 'Closing the Gaps tells us how well a school is ensuring that all student groups are successful.'
                            : 'Cierre de Diferencias nos dice qué tan bien una escuela se está asegurando de que todos los grupos de estudiantes tengan éxito.',
                        'gaps'
                    )}
                </Grid>
            </Grid>

            {school.las_flag === 'Y' ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/school_building_white.png'
                        alt='Local Accountability icon'
                        style={{ height: '1.9rem', marginLeft: '1rem', marginTop: '.6rem', marginRight: '.7rem' }}
                    />
                    Local Accountability Performance Details
                </div>
            ) : null}

            <div>
                <Grid container>
                    {school.las_flag === 'Y' && !!las.find((las) => las.id === school.id) ? (
                        <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                            {perfBoxla(
                                'Academics',
                                'This school district measures Academics using data on student achievement, teacher performance, or other areas.'
                            )}
                        </Grid>
                    ) : null}
                    {school.las_flag === 'Y' && !!las.find((las) => las.id === school.id) ? (
                        <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                            {perfBoxlb(
                                'Culture and Climate',
                                'This school district measures Culture and Climate using data on student, family, and staff engagement, campus discipline procedures, or other areas.'
                            )}
                        </Grid>
                    ) : null}
                    {school.las_flag === 'Y' && !!las.find((las) => las.id === school.id) ? (
                        <Grid item xs={12} md={4} className={myClasses.perfGridItem}>
                            {perfBoxlc(
                                'Locally Determined',
                                'This school district measures performance using criteria specific to the local community.'
                            )}
                        </Grid>
                    ) : null}
                    {school.las_flag === 'Y' && !!las.find((las) => las.id === school.id) ? (
                        <Grid item xs={12} className={myClasses.perfGridItem}>
                            {/* '143' local_accountability modal*/}
                            <ModalEntity
                                modalId={'143'}
                                lang={lang}
                                customButtonColor='Primary'
                                customButtonTitle={
                                    lang === 'es' ? 'Detalles de responsabilidad local' : 'Local Accountability Details'
                                }
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </div>
            {!!school.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/distinction_white.png'
                        alt='Distinctions icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Distinction Designations' : 'Designaciones de Distinción'}
                </div>
            ) : null}

            {!!school.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.sectionf}>
                    <div className={myClasses.sectionIcons}>
                        {school.distinctions[0] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_ela-' +
                                    (school.distinctions[0] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='ELA distinction icon'
                            />
                        ) : null}
                        {school.distinctions[1] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_math-' +
                                    (school.distinctions[1] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='math distinction icon'
                            />
                        ) : null}
                        {school.distinctions[2] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_science-' +
                                    (school.distinctions[2] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Science distinction icon'
                            />
                        ) : null}
                        {school.distinctions[3] !== 'X' ? (
                            <img
                                src={
                                    './images/aa_socstudies-' +
                                    (school.distinctions[3] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Social Studies distinction icon'
                            />
                        ) : null}
                        {school.distinctions[4] !== 'X' ? (
                            <img
                                src={
                                    './images/acad_grow-' +
                                    (school.distinctions[4] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Academic Growth distinction icon'
                            />
                        ) : null}
                        {school.distinctions[5] !== 'X' ? (
                            <img
                                src={
                                    './images/postsec-' +
                                    (school.distinctions[5] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Postsecondary distinction icon'
                            />
                        ) : null}
                        {school.distinctions[6] !== 'X' ? (
                            <img
                                src={
                                    './images/ctg-' +
                                    (school.distinctions[6] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Closing the Gaps distinction icon'
                            />
                        ) : null}
                        <br />
                        <br />
                        {/* '142' distinction_designations modal*/}
                        <ModalEntity modalId={'142'} lang={lang} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default function pins(
    i = 0,
) {
            const schoolSymbolUrls = [
                './images/NR_Map_Pin.svg',
                './images/A_Map_Pin.svg',
                './images/B_Map_Pin.svg',
                './images/C_Map_Pin.svg',
            ];
	 const NRpin= {
	 type: 'picture-marker',
	 url: schoolSymbolUrls[0],
	  width: "35.5px",
	  height: "50px",
	 };
	 const Apin= {
	 type: 'picture-marker',
	 url: schoolSymbolUrls[1],
	  width: "35.5px",
	  height: "50px",
	 };
	 const Bpin= {
	 type: 'picture-marker',
	 url: schoolSymbolUrls[2],
	  width: "35.5px",
	  height: "50px",
	 };
	 const Cpin= {
	 type: 'picture-marker',
	 url: schoolSymbolUrls[3],
	  width: "35.5px",
	  height: "50px",
	 };
	 const pinList = [
		NRpin,
		Apin,
		Bpin,
		Cpin,];
    return pinList[i];
}

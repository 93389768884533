import { createElement, useState, useEffect } from 'react';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    LineController,
    BarController,
    Legend,
} from 'chart.js';
import { Bar, Chart } from 'react-chartjs-2';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    Title,
    PointElement,
    Tooltip,
    LineController,
    BarController,
    Legend,
    annotationPlugin
);
export default function ChartjsMinAveMaxRev({ dataMin, dataMax, dataAve, dataRev, lang }) {
    const _RevPerStu = lang === 'es' ? 'Ingresos por estudiante' : 'Revenue Per Student';
    const _Min = lang === 'es' ? 'Mínimo' : 'Minimum';
    const _Max = lang === 'es' ? 'Máximo' : 'Maximum';
    const _Ave = lang === 'es' ? 'Promedio de pares' : 'Peer Average';
    const delta = 0.9;
    const options = {
        layout: {
            padding: {
                top: 10,
                left: 10,
                right: 10,
                bottom: 0,
            },
        },
        indexAxis: 'y',
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
                text: '',
            },
            annotation: {
                annotations: {
                    boxAve: {
                        type: 'box',
                        xMin: 0,
                        xMax: dataAve,
                        yMin: 1.25 - delta,
                        yMax: 2.75 - delta,
                        borderColor: 'black',
                        backgroundColor: '#56b7e6',
                    },
                    boxMax: {
                        type: 'box',
                        xMin: dataAve,
                        xMax: dataMax,
                        yMin: 1.25 - delta,
                        yMax: 2.75 - delta,
                        borderColor: 'black',
                        backgroundColor: '#e0ecf6',
                    },
                    boxRev: {
                        type: 'box',
                        xMin: 0,
                        xMax: dataRev,
                        yMin: 1.5 - delta,
                        yMax: 2.5 - delta,
                        backgroundColor: '#012169',
                    },
                    lineRev: {
                        type: 'line',
                        xMin: dataRev,
                        xMax: dataRev,
                        yMin: 0.75 - delta,
                        yMax: 1 - delta,
                        borderColor: 'blue',
                        borderWidth: 2,
                    },
                    lineAve: {
                        type: 'line',
                        xMin: dataAve,
                        xMax: dataAve,
                        yMin: 3 - delta,
                        yMax: 3.25 - delta,
                        borderColor: 'blue',
                        borderWidth: 2,
                    },
                    textAve: {
                        type: 'label',
                        content: [_Ave + ': ' + '$' + new Intl.NumberFormat().format(dataAve)],
                        align: 'center',
                        xValue: dataAve,
                        yValue: 3.5 - delta,
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                    textRev: {
                        type: 'label',
                        content: [_RevPerStu + ': ' + '$' + new Intl.NumberFormat().format(dataRev)],
                        xValue: dataRev,
                        yValue: 0.5 - delta,
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                    textMin: {
                        type: 'label',
                        content: [_Min + ':', '$' + new Intl.NumberFormat().format(dataMin)],
                        textAlign: 'end',
                        xValue: -dataMax * 0.08,
                        yValue: 2 - delta,
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                    textMax: {
                        type: 'label',
                        content: [_Max + ':', '$' + new Intl.NumberFormat().format(dataMax)],
                        xValue: dataMax * 1.08,
                        yValue: 2 - delta,
                        textAlign: 'start',
                        font: {
                            size: 14,
                            weight: 'bold',
                        },
                    },
                },
            },
        },
        responsive: true,
        scales: {
            x: {
                display: false,
                backgroundColor: 'white',
                ticks: {
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
                max: dataMax * 1.2,
                beginAtZero: true,
                min: -dataMax * 0.2,
                stacked: false,
            },
            y: {
                stacked: false,
                display: false,
                grid: {
                    display: false,
                },
            },
        },
    };

    const labelsData = ['', '', '', ''];
    const data = {
        labels: labelsData,
        datasets: [],
    };

    return <Bar options={options} data={data} />;
}

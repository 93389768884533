import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import utils from '../components/utils';
import { Grid } from '@material-ui/core';
import LocationOn from '@material-ui/icons/LocationOn';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
    root: {
        '&:hover': {
            borderColor: theme.palette.primary.main,
            border: '1px solid',
            boxShadow: '2px 2px 2px, -1px -1px -1pxs',
        },
    },
    title: {
        fontWeight: 600,
        lineHeight: 1,
    },

    subtitle: {
        lineHeight: 1,
    },
    details: {
        lineHeight: 1,
    },
    grade: {
        fontSize: '3em',
        color: '#000000',
        fontWeight: 700,
        lineHeight: 1,
        height: '1.1em',
        float: 'right',
    },
    topCard: {
        // minHeight: 275,
    },
    pin: {
        color: theme.palette.muted.main,
        '&:hover': {
            color: theme.palette.muted.dark,
        },
    },
}));

const SchoolCard = ({ lng, school, srclists, homeAddress, schoolPrograms, schoolAp, onZoomMap }) => {
    const classes = useStyles();

    const gradeRange = (start, end) => {
        const startGrade = utils.translateCode2Label(start, srclists.grades);
        const endGrade = utils.translateCode2Label(end, srclists.grades);
        return startGrade === endGrade ? startGrade : startGrade + ' - ' + endGrade;
    };
    const zoomMap = () => {
        onZoomMap(school.id, 'school');
    };

    return (
        <Card className={classes.root}>
            <CardContent className={classes.topCard}>
                <a
                    href={process.env.REACT_APP_BASEPATH + '?view=school&id=' + school.id + '&lng=' + lng}
                    target='_blank'
                >
                    <Typography className={classes.title} gutterBottom>
                        {school.name}
                    </Typography>
                    {!!school.distance
                        ? (lng === 'en' ? 'Distance to school: ' : 'Distancia a la escuela: ') +
                          school.distance +
                          (lng === 'en' ? ' miles' : ' millas')
                        : null}
                    <Typography className={classes.grade}>
                        {school.rating === 'Not Rated' ? ' ' : school.rating}
                    </Typography>
                </a>

                <Grid container direction='row'>
                    <Grid item xs={9}>
                        <a
                            href={
                                process.env.REACT_APP_BASEPATH +
                                '?view=district&id=' +
                                school.district_id +
                                '&lng=' +
                                lng
                            }
                            target='_blank'
                        >
                            <Typography className={classes.subtitle} gutterBottom>
                                {lng === 'en' ? 'School District:' : 'Distrito Escolar:'} {school.district_name}
                            </Typography>
                        </a>
                        <Typography className={classes.details} gutterBottom>
                            {school.address}&nbsp;
                            <IconButton onClick={zoomMap} className={classes.pin}>
                                <LocationOn />
                            </IconButton>
                            <br />
                            {lng === 'en' ? 'Grades Served:' : 'Grados cumplidos:'}{' '}
                            {gradeRange(school.low_grade_cd, school.high_grade_cd)}
                            {/* <br/>    */}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography className={classes.details} gutterBottom></Typography>
                        {school.rating_cd === 'NR' ? (
                            ' '
                        ) : (
                            <b>
                                {lng === 'en' ? 'Overall Rating' : 'Puntuación general'}
                                <br />
                                {school.rating}
                            </b>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SchoolCard;

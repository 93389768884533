import React, { createElement, useState, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';

import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import { CircularProgress } from '@material-ui/core';

import utils from '../../components/utils';
import { Modal } from 'react-bootstrap';
import '../../bootstrap_m.css';
import TxsMap1 from '../../components/TxsMap1';
import ChartjsRaceEthnicity1 from '../../components/chartjsRaceEthnicity1';
import ChartjsRaceEthnicity2 from '../../components/chartjsRaceEthnicity2';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
    },
    perfButton: {
        marginLeft: '.5rem',
        width: '12rem',
    },
    subHeader: {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    grade: {
        backgroundColorcolor: '#FFFFFF',
    },
    Gradeheadera: {
        fontSize: '7rem',
        color: '#278143',
        padding: '14px 0px 0px 51px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '7rem',
        color: '#39398C',
        padding: '14px 0px 0px 56px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '7rem',
        color: '#9C118B',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '7rem',
        color: '#E0803D',
        padding: '14px 0px 0px 54px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '7rem',
        color: '#CA313C',
        padding: '14px 0px 0px 58px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.6rem',
        color: 'black',
        padding: '30px 0px 0px 34px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.7rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    gradenr: {
        color: '#51555A',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    subHeadernr: {
        fontSize: '3.2rem',
    },

    pairLink: {
        color: theme.palette.primary.main,
        cursor: 'pointer',
    },

    gridItem: {
        backgroundColor: theme.palette.background.default,
        padding: '2rem',
        borderRadius: '.25rem',
    },
    districtInfo: {
        [theme.breakpoints.up('sm')]: {
            borderLeft: '1px solid',
        },
        [theme.breakpoints.down('xs')]: {
            borderTop: '1px solid',
        },
        textAlign: 'left',
        fontSize: '1.1rem',
    },
    overviewBtn: {
        // color: theme.palette.text.tertiary,
        // backgroundColor: theme.palette.secondary.main,
        // '&:hover': { backgroundColor: theme.palette.secondary.light, },
        margin: '.1rem',
        minWidth: '13rem',
        justifyContent: 'left',
    },

    sectionButton: {
        marginTop: '1rem',
        width: '12rem',
    },

    infoOdd: {
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderTop: '1px Solid',
    },
    infoEven: {
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
    },
    infoOddn: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
    },
    infoOddna: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderLeft: '1px Solid',
        //	borderTop: '1px Solid',
        //	borderBottom: '1px Solid',
        borderRight: '1px Solid',
    },
    infoOddA: {
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        //	borderLeft: '1px Solid',
        borderTop: '1px Solid',
        //	borderBottom: '1px Solid',
        //	borderRight: '1px Solid',
    },
    infoEvena: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        //	borderLeft: '1px Solid',
        borderTop: '1px Solid',
        borderBottom: '1px Solid',
        //	borderRight: '1px Solid',
    },
    infoEvenb: {
        // backgroundColor: theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
        borderRadius: '.25rem',
        borderTop: '1px Solid',
    },

    sectionIcons: {
        margin: '1rem',
        textAlign: 'center',
    },
    sectionIcon: {
        width: '190px',
        height: '180px',
    },
    frmsze: {
        width: '100%',
        height: '100%',
        border: '0',
    },
    frmszeaa: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '42.5rem',
        overflow: 'hidden',
    },
    frmszeab: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '41rem',
        overflow: 'hidden',
    },

    sectionf: {
        padding: '1rem',
        marginTop: '1rem',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },
}));

export default ({ district, onNav }) => {
    utils.setHomeTitle(!!district && district.name + ' | Profile');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [enroll, setEnroll] = useState([]);
    // read in RaceEthnicity json
    useEffect(() => {
        fetch('../data/profile_tab.json')
            .then((resp) => resp.json())
            .then((json) => {
                setEnroll(json);
            });
    }, []);
    const entity = district;
    const enrollMatchedList = enroll.filter((item) => {
        return item.id === entity.id;
    });
    const dataSchool_Year = enrollMatchedList.length === 0 ? 0 : enrollMatchedList[0].School_Year;

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';

    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
    }

    const handleClicka = () => {
        window.open(
            'https://' +
                locserver +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' +
                [window.ccyy] +
                '&lev=D&id=' +
                district.id +
                '&prgopt=reports/tapr/student.sas'
        );
    };
    const handleClickb = () => {
        window.open(
            'https://' +
                locserver +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' +
                [window.ccyy] +
                '&lev=D&id=' +
                district.id +
                '&prgopt=reports/tapr/staff.sas'
        );
    };

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools.gov')) {
        vaserver = 'https://viya.txschools.gov';
    }
    if (window.location.href.includes('txschools.org')) {
        vaserver = 'https://viya.txschools.gov';
    }

    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
    }
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use district id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === district.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdOverPerfOrder2 = getContentId(dynModMatchedList, 'overall_performance');

    // map rating_cd to modal ID
    const getModalId = (rating_cd) => {
        const modalMap = { A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' };
        return modalMap[rating_cd];
    };

    const [showov, setShowov] = useState(false);
    const handleCloseov = () => setShowov(false);
    const handleShowov = () => setShowov(true);

    const [showdis, setShowdis] = useState(false);
    const handleClosedis = () => setShowdis(false);
    const handleShowdis = () => setShowdis(true);

    const [showsti, setShowsti] = useState(false);
    const handleClosesti = () => setShowsti(false);
    const handleShowsti = () => setShowsti(true);

    const [showtai, setShowtai] = useState(false);
    const handleClosetai = () => setShowtai(false);
    const handleShowtai = () => setShowtai(true);

    const myClasses = useStyles();

    return (
        <div>
            <div className={myClasses.blueHeader}>
                <img
                    src='./images/profile_school_white.png'
                    alt='school profile icon'
                    style={{ height: '2.4rem', marginRight: '.5rem', marginTop: '.6rem' }}
                />
                {lang === 'en' ? 'DISTRICT PROFILE' : 'PERFIL DE DISTRITO'}
            </div>
            <Grid container style={{ marginBottom: '1rem' }}>
                <Grid item xs={12} md={7} className={myClasses.gridItem}>
                    <Grid container spacing={2} style={{ textAlign: 'center' }}>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            {/* <img src='./images/School_Building_profile.jpg' style={{ height: '2rem' }} />
                            <b className={myClasses.header}>DISTRICT PROFILE</b> */}
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <b className={myClasses.subHeader}>
                                {lang === 'en' ? 'Overall Rating' : 'Calificación General'}
                            </b>
                            <br />
                            <br />
                            <div style={{ position: 'relative', left: 'auto', top: 'auto' }}>
                                {district.rating !== 'Not Rated' ? (
                                    <b id='gradea' className={myClasses['Gradeheader' + district.rating.toLowerCase()]}>
                                        {district.rating}
                                    </b>
                                ) : district.rating === 'Not Rated' && district.score !== null ? (
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado'}
                                    </b>
                                ) : (
                                    <b className={myClasses.subHeadernr}> Not Rated </b>
                                )}
                                {district.rating !== 'Not Rated' ? (
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={185}
                                    />
                                ) : district.rating === 'Not Rated' && district.score !== null ? (
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={185}
                                    />
                                ) : (
                                    <br />
                                )}
                                {district.rating !== 'Not Rated' ? (
                                    <CircularProgress
                                        className={myClasses['grade' + district.rating.toLowerCase()]}
                                        variant='determinate'
                                        value={parseFloat(district.score, 10)}
                                        size={185}
                                    />
                                ) : district.rating === 'Not Rated' && district.score !== null ? (
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={parseFloat(district.score, 10)}
                                        size={185}
                                    />
                                ) : (
                                    <br />
                                )}
                            </div>
                            {district.rating !== 'Not Rated' ? (
                                <b className={myClasses.subHeader}>
                                    <span className={myClasses['grade' + district.rating.toLowerCase()]}>
                                        {' '}
                                        {district.score}
                                        {lang === 'en' ? ' out of' : ' de'} 100
                                    </span>
                                </b>
                            ) : district.rating === 'Not Rated' && district.score !== null ? (
                                <b className={myClasses.subHeader}>
                                    <span className={myClasses.gradenr}> {district.score} out of 100</span>
                                </b>
                            ) : (
                                <br />
                            )}
                            {district.alt_standards === 'Yes' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                    <img
                                        src='./images/aea_icon.png'
                                        alt='AEA school icon'
                                        style={{ height: '3rem', marginTop: '-2rem', marginBottom: '-.5rem' }}
                                    />{' '}
                                    {lang === 'en'
                                        ? 'Alternative standards were used to rate this district.'
                                        : 'Se usaron estándares alternativos para calificar este distrito.'}
                                </p>
                            ) : null}

                            {district.rating_cd === 'U' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                    <img
                                        src='./images/info_icon_navy.png'
                                        alt='not rated school icon'
                                        style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                    />
                                    &nbsp;{' '}
                                    {lang === 'en'
                                        ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                        : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                                    <br />
                                </p>
                            ) : null}
                            {district.rating_cd === 'Q' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                 <img
                                  src='./images/exclamation_point.jpg'
                                  alt='not rated school icon'
                                  style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                 />
                               &nbsp;
                               {lang === 'en'
                                ? 'Not Rated: Data Under Review. Rating is pending while data is reviewed by TEA. Learn more: '
                                : 'Este distrito no Está Clasificado: Datos Bajo Revisión. '}
                               <a href='https://tea.texas.gov/about-tea/contact-us/self-reported-data-compliance-review' target="_blank" alt="Data compliance review link"> Data Compliance Review</a>
                              <br />
                             </p>
                            ) : null}
                            {district.alls_chgf === 'Y' ? (
                                <p style={{ fontSize: '1.2rem' }}>
                                 <img
                                  src='./images/exclamation_point.jpg'
                                  alt='attention school icon'
                                  style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                 />
                               &nbsp;
                               {lang === 'en'
                                ? 'Overall score or rating updated as a result of a Data Compliance Review. Learn more: '
                                : 'La calificación total o puntaje se actualizó como resultado de una Revisión de Cumplimiento de Datos.'}
                               <a href='https://tea.texas.gov/about-tea/contact-us/self-reported-data-compliance-review' target="_blank" alt="Data compliance review link"> Data Compliance Review</a>
                              <br />
                             </p>
                            ) : null}
                            <p style={{ fontSize: '1rem' }}>
                                {lang === 'en'
                                    ? 'This measures how much students are learning in each grade and whether or not they are ready for the next grade. It also shows how well a school or district prepares their students for success after high school in college, the workforce, or the military.'
                                    : 'Esto mide lo que los estudiantes están aprendiendo en cada grado y si están o no listos para el siguiente grado. También muestra qué tan bien una escuela prepara a sus estudiantes para el éxito después de la escuela secundaria en la universidad, la fuerza laboral o el ejército. '}
                            </p>
                            <br />
                            {/* A: '1', B: '2', C: '3', D: '4', F: '5', U: '6', X: '6', Z: '6', Q: '6', V: '6', K: '150' */}
                            <ModalEntity
                                modalId={getModalId(district.rating_cd)}
                                modalId2={mIdOverPerfOrder2}
                                lang={lang}
                            />
                            <span style={{ marginLeft: '8px' }}>
                                <Button
                                    variant='contained'
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        onNav('accountability');
                                    }}
                                    className={myClasses.perfButton}
                                >
                                    {lang === 'en' ? 'Additional Details' : 'Detalles Adicionales'}
                                </Button>
                            </span>
                        </Grid>
                        <Grid item xs={12} sm={4} className={myClasses.districtInfo}>
                            {/*  {!!district.email ? (
                                <Button href={'mailto:' + district.email} className={myClasses.overviewBtn}>
                                    <EmailIcon /> &nbsp; E-mail Superintendent
                                </Button>
                            ) : null} */}
                            <br />
                            <br />
                            <p>
                                <b>{lang === 'en' ? 'Address' : 'Dirección'}:</b>
                                <br />
                                {district.street_address}
                                <br />
                                {district.city}, {district.state} {district.zip_5}
                            </p>
                            <p>
                                <b>{lang === 'en' ? 'Phone' : 'Teléfono'}: </b>
                                <br />
                                {utils.formatPhone(district.phone)}
                            </p>
                            <p>
                                <b>{lang === 'en' ? 'Superintendent Name' : 'Nombre del Superintendente'}:</b>
                                <br />
                                {district.superintendent}
                            </p>
                            {/* <p>
                                <b>Total Student Enrollment:</b>
                                <br />
                                {district.enrollment.toLocaleString()}
                            </p> */}

                            {!!district.website ? (
                                <Button
                                    variant='contained'
                                    href={'http://' + district.website}
                                    target='_blank'
                                    className={myClasses.overviewBtn}
                                >
                                    <LanguageIcon />
                                    &nbsp; {lang === 'en' ? 'District Website' : 'Sitio web del distrito'}
                                </Button>
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={5}>
                    <div id='school_overview_map'>
                        <br />
                        <TxsMap1 entity={district} txsMapStyle={{ height: '31rem' }} />
                    </div>
                </Grid>
            </Grid>

            <div className={myClasses.grayHeader}>
                <img
                    src='./images/students_profile_white.png'
                    alt='student profile icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                />{' '}
                {lang === 'en' ? 'Student Information' : 'Información Estudiantil'} {dataSchool_Year}
            </div>

            {/*    <ChartjsRaceEthnicity entity={district} /> */}

            <div spacing={2} style={{ marginBottom: '.4rem' }}>
                {enrollMatchedList !== null && enrollMatchedList.length === 1 ? (
                    <ChartjsRaceEthnicity1 enroll={enrollMatchedList} lang={lang} />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.4rem' }}>
                    {/* '148' student_information modal*/}
                    <ModalEntity modalId={'148'} lang={lang} />
                    <span style={{ marginLeft: '8px' }}>
                        <Button variant='contained' color='primary' onClick={handleClicka}>
                            {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                        </Button>
                    </span>
                </div>
            </div>
            <div
                style={{
                    backgroundColor: '#012169',
                    color: 'white',
                    textAlign: 'left',
                    fontSize: '1.6rem',
                    fontWeight: 'bold',
                    padding: '1px 1px 7px 1px',
                }}
            >
                <img
                    src='./images/staff_info_white.png'
                    alt='staff icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                />{' '}
                {lang === 'en' ? 'Staff Information' : 'Información del Personal'} {dataSchool_Year}
            </div>

            <div spacing={2} style={{ marginBottom: '.4rem' }}>
                {enrollMatchedList !== null && enrollMatchedList.length === 1 ? (
                    <ChartjsRaceEthnicity2 enroll={enrollMatchedList} lang={lang} />
                ) : null}
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '.4rem' }}>
                    {/* '149' staff_information modal*/}
                    <ModalEntity modalId={'149'} lang={lang} />
                    <span style={{ marginLeft: '8px' }}>
                        <Button variant='contained' color='primary' onClick={handleClickb}>
                            {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
                        </Button>
                    </span>
                </div>
            </div>

            {!!district.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.grayHeader}>
                    <img
                        src='./images/distinction_white.png'
                        alt='distinctions icon'
                        style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem' }}
                    />{' '}
                    {lang === 'en' ? 'Distinction Designations' : 'Designaciones de Distinción'}
                </div>
            ) : null}

            {!!district.distinctions.some((distinction) => distinction !== 'X') ? (
                <div className={myClasses.infoOdd}>
                    <div className={myClasses.sectionIcons}>
                        {district.distinctions[5] !== 'X' ? (
                            <img
                                src={
                                    './images/postsec-' +
                                    (district.distinctions[5] === 'Y' ? 'yes' : 'no') +
                                    (lang === 'es' ? '-spanish' : '') +
                                    '.svg'
                                }
                                className={myClasses.sectionIcon}
                                alt='Postsecondary distinction icon'
                            />
                        ) : null}
                        <br />
                        <br />
                        {/* '142' distinction_designations modal*/}
                        <ModalEntity modalId={'142'} lang={lang} />
                    </div>
                </div>
            ) : null}
        </div>
    );
};

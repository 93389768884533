import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
export default function DtAccountability({ entity }) {
    let locserver = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sasdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
    }

    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }

    const [account, setAccount] = useState([]);
    useEffect(() => {
        const jsonFile = lang === 'en' ? './data/change_over_time.json' : './data/change_over_time_es.json';
        fetch(jsonFile)
            .then((resp) => resp.json())
            .then((json) => {
                setAccount(json);
            });
    }, [entity, lang]);

    // use entity id to filter out data.
    const accountMatchedList = account.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = accountMatchedList.length === 0;
    const labelsAccount = checkMatchedList ? ['', '', '', '', ''] : accountMatchedList[0].academic_year;
    const dataoverall_ratingListEntity = checkMatchedList ? ['', '', '', '', ''] : accountMatchedList[0].overall_rating;
    const datascoreListEntity = checkMatchedList ? ['', '', '', '', ''] : accountMatchedList[0].score;

    //  const uI1 =
    //      'https://' + locserver + '/cgi/sas/broker?_service=marykay&_debug=0&_program=perfrept.perfmast.sas&ccyy=20';
    //  const uI2 = '&lev=';
    //  const uI3 = '&id=';
    //  const uI4 = '&prgopt=reports/acct/summary.sas';

    const getUrl = (yr) => {
        if (yr === '18') {
            // 18 url is different from other years.
            if (entity.entity_cd === 'C') {
                return (
                    'https://' +
                    locserver +
                    '/cgi/sas/broker?_service=marykay&_debug=0&_program=perfrept.perfmast.sas&ptype=H&level=campus&search=campnum&namenum=' +
                    entity.id +
                    '&prgopt=20' +
                    yr +
                    '/acct/summary.sas'
                );
            } else {
                return (
                    'https://' +
                    locserver +
                    '/cgi/sas/broker?_service=marykay&_debug=0&_program=perfrept.perfmast.sas&ptype=H&level=district&search=distnum&namenum=' +
                    entity.id +
                    '&prgopt=20' +
                    yr +
                    '/acct/summary.sas'
                );
            }
        } else {
            // not 18
            return (
                'https://' +
                locserver +
                '/cgi/sas/broker?_service=marykay&_debug=0&_program=perfrept.perfmast.sas&ccyy=20' +
                yr +
                '&lev=' +
                entity.entity_cd +
                '&id=' +
                entity.id +
                '&prgopt=reports/acct/summary.sas'
            );
        }
    };

    const dataDT = labelsAccount.map(function (value, index) {
        return {
            id: index,
            academic_year: value,
            overall_rating: dataoverall_ratingListEntity[index],
            score: datascoreListEntity[index],
            Url: `${getUrl(value.substring(5))}`,
            // Url: `${uI1}${value.substring(5)}${uI2}${entity.entity_cd}${uI3}${entity.id}${uI4}`,
        };
    });
    const columnsDT = [
        {
            name: lang === 'en' ? 'Academic Year' : 'Año Académico',
            cell: (row) => (
                <a href={row.Url} target='_blank'>
                    {row.academic_year}
                </a>
            ),
        },
        {
            name: lang === 'en' ? 'Overall Rating' : 'Calificación General',
            selector: (row) => row.overall_rating,
        },
        {
            name: lang === 'en' ? 'Score' : 'Puntaje',
            selector: (row) => row.score,
        },
    ];

    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'center',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
                justifyContent: 'center',
            },
        },
    };
    return (
        <div style={{ margin: '0% 10% 0%' }}>
            <DataTable columns={columnsDT} data={dataDT} customStyles={customStyles} highlightOnHover />
        </div>
    );
}

import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DataTable from 'react-data-table-component';

export default function ChartjsAchievementccmr({ entity, achieve }) {
    // 1. Common Setup --------------------------------
    let condition;
    if (!!entity && entity.entity_cd === 'C') {
        condition = 1; // campus.
    } else if (!!entity && entity.entity_cd === 'D') {
        condition = 2; // district.
    } else {
        condition = 0; // error.
    }

    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }
    // readin data;
    // different column names for col 2 and col 3 in entity and district case.
    // const entityCol2 = condition === 1 ? 'This School' : 'This District';
    // const entityCol3 = condition === 1 ? 'District' : 'State';
    const entityCol2 =
        condition === 1
            ? lang === 'en'
                ? 'This School'
                : 'Esta Escuela'
            : lang === 'en'
            ? 'This District'
            : 'Este Distrito';
    const entityCol3 = condition === 1 ? (lang === 'en' ? 'District' : 'Distrito') : lang === 'en' ? 'State' : 'Estado';
    // use entity id to filter out data.
    const achieveMatchedList = achieve.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = achieveMatchedList.length === 0;
    // STARR Participation Rate string list: [0] for 2022, [1] for 2021:
    const dataparticipationListEntity = checkMatchedList ? ['', ''] : achieveMatchedList[0].participation;
    // Setup Ends--------------------------------

    // 4.College, Career, and Military Readiness Details DT Setup -----------
    const labelsAchieveCcmr = [
        lang === 'en' ? 'Total credit for CCMR criteria' : 'Crédito Total para los Criterios del CCMR',
        lang === 'en'
            ? 'Scored at or above the college ready standard on SAT, ACT, TSIA, or earned credit for a college prep course'
            : 'Obtuvo un puntaje igual o superior al estándar listo para la universidad en SAT, ACT, TSIA o obtuvo crédito para un curso de preparación para la universidad',
        lang === 'en'
            ? 'Met criterion score on AP/IB exam(s)'
            : 'Cumplió con la Puntuación del Criterio en los exámenes AP/IB',
        lang === 'en'
            ? 'Earned college credit for a dual credit course'
            : 'Obtuvo Crédito Universitario para un Curso de Crédito Dual',
        lang === 'en' ? 'Earned an industry-based certification' : 'Obtuvo una Certificación Basada en la Industria',
        lang === 'en' ? 'Earned a level I or level II certificate' : 'Obtuvo un certificado de nivel I o nivel II',
        lang === 'en' ? 'Earned an associate degree' : 'Obtuvo un grado de asociado ',
        lang === 'en'
            ? 'Completed an OnRamps course and qualified for college credit'
            : 'Completó un curso de OnRamps y calificó para crédito universitario',
        lang === 'en'
            ? 'Graduated with completed individualized education program (IEP) and workforce readiness'
            : 'Graduado con el programa de educación individualizada (IEP) completado y la preparación de la fuerza laboral',
        lang === 'en'
            ? 'Graduated under an advanced diploma plan and identified as a current special education student'
            : 'Graduado bajo un plan de diploma avanzado e identificado como un estudiante actual de educación especial',
    ];
    const dataccmr_col2ListEntity = checkMatchedList ? [''] : achieveMatchedList[0].ccmr_col2;
    const dataccmr_col3ListEntity = checkMatchedList ? [''] : achieveMatchedList[0].ccmr_col3;
    const dataDTStuccmr = labelsAchieveCcmr.map(function (value, index) {
        return {
            id: index,
            criteria: value,
            ccmr_col2: dataccmr_col2ListEntity[index],
            ccmr_col3: dataccmr_col3ListEntity[index],
        };
    });
    const columnsDTStuccmr = [
        {
            grow: 6,
            name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'CCMR Criteria' : 'Criterios del CCMR'}</div>,
            cell: (row) => row.criteria,
            style: {
                justifyContent: 'left',
                textAlign: 'left',
            },
        },
        {
            grow: 1,
            name: <div style={{ textOverflow: 'unset' }}>{entityCol2}</div>,
            selector: (row) => row.ccmr_col2,
            style: {
                justifyContent: 'center',
            },
        },
        {
            grow: 1,
            name: entityCol3,
            selector: (row) => row.ccmr_col3,
            style: {
                justifyContent: 'center',
            },
        },
    ];
    const conditionalRowStylesStuccmr = [
        {
            when: (row) => row.id === 0,
            style: {
                backgroundColor: '#56b7e6',
                fontWeight: 'bold',
            },
        },
    ];
    const customStylesStuccmr = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'center',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
            },
        },
    };
    // Setup Ends--------------------------------

    // DT datatable data setting above --------------------------------
    return (
        <div>
            {dataccmr_col2ListEntity.length > 5 ? (
                <DataTable
                    columns={columnsDTStuccmr}
                    data={dataDTStuccmr}
                    customStyles={customStylesStuccmr}
                    conditionalRowStyles={conditionalRowStylesStuccmr}
                    highlightOnHover
                />
            ) : null}
        </div>
    );
}

import { createElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';

import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
// import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import { Icon, Button } from '@material-ui/core';
import { ExpandMore, ExpandLess, Clear } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import Chip from '@material-ui/core/Chip';
// import { props } from 'bluebird';

/**   Card filter with autosuggest
 * @param placeholder - placeholder text to prompt input
 * @param filterName - string to identify the filter being processed.  This can be dynamic.
 * @param onChange - function process any change.  
 *    It will be passed a hash with "selection" being the selected 
 *    item and filterName equal to the input "filterName"
 * @param suggestions - JSON array with items to select. There should be properties for "label", "value" and optionally "address"
 * @param showAddress - Option to append "-" plus the value of the address when rendering the item as a suggestion.
 * 
 * @example
 * <CardFilterMultiselect   
        placeholder={"Search by "+["County","District","School"][ ["counties","districts","schools"].indexOf(viewType)]}
        showAddress={viewType === "schools" ? true : false}
        onChange={handleFilterChange} 
        selectedItem={selectedItem}
        // setSelectedItem={setSelectedItem}
        filterName = {viewType}
        suggestions={
            viewType === "schools" ? srclists.schools.filter((item) => item.year = "2019")
            : srclists[viewType] }/>
 * 
 */

const useStyles = makeStyles((theme) => ({
    root: {
        width: '99.5%',
        backgroundColor: 'white',
        padding: '.7rem',
        borderRadius: '.25rem',
    },
}));

function CardFilterMultiselect(props) {
    const { placeholder, label, onChange } = props;
    const classes = useStyles();
    const [value, setValue] = useState(props.value);
    const [suggestions, setSuggestions] = useState(props.suggestions);

    useEffect(() => {
        setValue(Object.assign([], props.value));
        setSuggestions(props.suggestions);
    }, [props.value, props.suggestions]);

    const handleChange = (event, newvalue) => {
        setValue(newvalue);
        onChange({ selection: newvalue, filterName: props.filterName });
    };

    return (
        <div className={classes.root} style={props.style}>
            <Autocomplete
                multiple
                options={suggestions}
                disableClearable
                getOptionLabel={(option) => option.label}
                onChange={handleChange}
                value={value}
                filterSelectedOptions
                renderInput={(params) => (
                    <TextField {...params} variant='outlined' label={label} placeholder={placeholder} />
                )}
            />
        </div>
    );
}

export default CardFilterMultiselect;

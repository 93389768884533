import { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
// import Typography from '@material-ui/core/Typography';
// import {  Grid } from "@material-ui/core";

/** SAS VA State Map
 *
 * @param report - json for the report
 *
 */

    let vaserver = 'https://viyadev.tea.state.tx.us'; 
     if (window.location.href.includes('rptsvr1')) {
       vaserver = 'https://viyaprod.tea.state.tx.us'
       };
     if (window.location.href.includes('txschools.gov')) {
       vaserver = 'https://viya.txschools.gov'
       };
     if (window.location.href.includes('txschools.org')) {
       vaserver = 'https://viya.txschools.gov'
       };


const SASStateMap = ({ entities, selectedSchool }) => {
    const [mapReportHandle, setMapReportHandle] = useState(null);

    useEffect(() => {
        const sasReport = document.getElementById('va-report-element');
        window.areSDKReportsLoaded.then(() => {
            sasReport.getReportHandle().then((reportHandle) => {
                setMapReportHandle(reportHandle);
            });
        });
    }, []);

    useEffect(() => {
        const scrollToRef = (ref) => {
            const sasMap = document.getElementById('va-report-element');
            window.scrollTo(0, sasMap.offsetTop);
        };

        if ((entities.length > 0 || !!selectedSchool) && mapReportHandle !== null) {
            const parameters = {};
            if (!!selectedSchool) {
                parameters.Selected_School_Parameter = selectedSchool.id;
            } else {
                parameters.Selected_School_Parameter = null;
            }

            if (entities.length > 0) {
                const entityIds = entities.map((_entity) => _entity.id);
                parameters.Entity_Parameters = entityIds;
            }

            mapReportHandle.updateReportParameters(parameters);
            scrollToRef();
        }
    }, [mapReportHandle, selectedSchool, entities]);

    return (
        <sas-report-object 
            id='va-report-element'
            authenticationType='guest'
            url={vaserver}
            reportUri='/reports/reports/4de86b44-386a-49bf-bac0-3dffde5d63d6'
            objectName='ve16'
        ></sas-report-object>
    );
};

export default SASStateMap;

import React, { createElement, useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ArrowBack from '@material-ui/icons/ArrowBack';
import utils from '../components/utils';
import TexasAssessment from '../components/texasAssessment';
import Overview from './district/Overview';
import Accountability from './district/Accountability';
import Achievement from './district/Achievement';
import Progress from './district/Progress';
import Gaps from './district/Gaps';
import Schools from './district/Schools';
import Finance from './district/Finance';
import Hidden from '@material-ui/core/Hidden';
import NestedMenuItem from 'material-ui-nested-menu-item';

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundImage: "url('./images/home-header.jpg')",
        color: theme.palette.text.tertiary,
        padding: '.4rem',
        marginBottom: '1px',
        borderRadius: '.15rem',
    },
    headerRight: {
        [theme.breakpoints.up('md')]: {
            borderLeft: 'white 1px solid',
        },
        [theme.breakpoints.down('sm')]: {
            borderTop: 'white 1px solid',
        },
        paddingLeft: '1rem',
        fontSize: '1rem',
    },

    menuBar: {
        backgroundColor: theme.palette.background.default,
    },
    tabs: {
        color: theme.palette.primary.main,
    },
    tab: {
        height: '3rem',
        minHeight: 0,
        minWidth: '7rem',
        fontSize: '1rem',
        fontWeight: '600',
        color: theme.palette.primary.main,
    },
    iconTabWrapper: {
        flexDirection: 'row-reverse',
    },
    iconTabLabelIcon: {
        paddingTop: '6px',
    },
}));

District.defaultProps = { tabId: 'overview' };

export default function District({ district, tabId, schools, srclists, lng, onChangeTab }) {
    utils.setHomeTitle(!!district && district.name);

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svsasdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    const [tab, setTab] = useState(null);
    const [menu, setMenu] = useState(null);
    const [menuAnchor, setMenuAnchor] = useState(null);
    const [reportsMenuAnchor, setReportsMenuAnchor] = useState(null);
    const [completeMenuAnchor, setCompleteMenuAnchor] = useState(null);

    const onTabChange = (evt, theTab) => {
        if (theTab === 'performance') {
            setMenuAnchor(evt.currentTarget);
            setReportsMenuAnchor(null);
            setCompleteMenuAnchor(null);
        } else if (theTab === 'reports') {
            setReportsMenuAnchor(evt.currentTarget);
            setMenuAnchor(null);
            setCompleteMenuAnchor(null);
        } else if (theTab === 'complete') {
            setCompleteMenuAnchor(evt.currentTarget);
            setReportsMenuAnchor(null);
        } else if (theTab === 'compare') {
            window.open(
                'https://' + locserver + '/perfreport/account/va/school-compare.html?id=' + district.id,
                '_blank'
            );
        } else if (theTab === 'parent') {
            window.open('https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html', '_blank');
        } else {
            setMenuAnchor(null);
            setReportsMenuAnchor(null);
            setCompleteMenuAnchor(null);
            onChangeTab(theTab);
        }
    };
    const onMenuChange = (menuId, theTab) => {
        setMenuAnchor(null);
        setCompleteMenuAnchor(null);
        onChangeTab(theTab + '|' + menuId);
    };

    const onMenuChangeSm = (menuId, theTab) => {
        setCompleteMenuAnchor(null);
        onChangeTab(theTab + '|' + menuId);
    };

    useEffect(() => {
        if (tabId.includes('|')) {
            const [theTab, theMenu] = tabId.split('|');
            setTab(theTab);
            setMenu(theMenu);
        } else {
            setTab(tabId);
            setMenu(null);
        }
    }, [tabId]);

    if (!!district) {
        return (
            <>
                <AppBar position='sticky'>
                    <Grid container spacing={1} className={myClasses.header}>
                        <Grid item md={1} />
                        <Grid item xs={12} md={6}>
                            <h1 style={{ textTransform: 'uppercase', fontSize: '2.2rem' }}>{district.name}</h1>

                            <span style={{ fontSize: '1.2rem' }}>
                                <span style={{ marginRight: '1.75rem' }}>
                                    {lng === 'en'
                                        ? '2021-22 Total Student Enrollment: '
                                        : '2021-22 Matrícula Total de Estudiantes: '}{' '}
                                    <b>{district.enrollment.toLocaleString()}</b>
                                </span>
                                {lng === 'en' ? 'Schools: ' : 'Escuelas: '}
                                <b>{district.schools}</b>
                            </span>
                        </Grid>
                        <Hidden smDown>
                            <Grid item xs={12} md={5} className={myClasses.headerRight}>
                                <p>
                                    <b>{lng === 'en' ? 'ADDRESS: ' : 'DIRECCIÓN: '}</b>
                                    <br />
                                    {district.address}
                                </p>

                                <p>
                                    <b>{lng === 'en' ? 'PHONE: ' : 'TELÉFONO: '}</b>
                                    <br />
                                    {utils.formatPhone(district.phone)}
                                </p>
                            </Grid>
                        </Hidden>
                    </Grid>

                    <AppBar position='sticky' className={myClasses.menuBar}>
                        <Hidden mdUp>
                            <Tabs value={tab} onChange={onTabChange} className={myClasses.tabs}>
                                <Tab value='overview' label='Profile' className={myClasses.tab} />
                                <Tab
                                    value='complete'
                                    label='Other Resources'
                                    icon={!!completeMenuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                            </Tabs>
                        </Hidden>
                        <Hidden smDown>
                            <Tabs value={tab} onChange={onTabChange} className={myClasses.tabs}>
                                <Tab
                                    value='overview'
                                    label={lng === 'en' ? 'Profile' : 'perfil'}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='performance'
                                    label={lng === 'en' ? 'Performance' : 'Rendimiento'}
                                    icon={!!menuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='finance'
                                    label={lng === 'en' ? 'Finance' : 'Finanzas'}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='schools'
                                    label={lng === 'en' ? 'schools' : 'escuelas'}
                                    className={myClasses.tab}
                                />

                                <Tab
                                    value='parent'
                                    label={lng === 'en' ? 'Parent Resources' : 'Recursos para padres'}
                                    className={myClasses.tab}
                                    style={{ marginLeft: 'auto' }}
                                />
                                <Tab
                                    value='compare'
                                    label={lng === 'en' ? 'Compare' : 'comparar'}
                                    className={myClasses.tab}
                                />
                                <Tab
                                    value='reports'
                                    label={lng === 'en' ? 'PDF Reports' : 'Informes de Formato de Documento Portátil'}
                                    icon={!!reportsMenuAnchor ? <ExpandLess /> : <ExpandMore />}
                                    classes={{
                                        wrapper: myClasses.iconTabWrapper,
                                        labelIcon: myClasses.iconTabLabelIcon,
                                    }}
                                    className={myClasses.tab}
                                />
                            </Tabs>
                        </Hidden>
                    </AppBar>
                </AppBar>
                <Menu
                    anchorEl={menuAnchor}
                    open={!!menuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('accountability', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Accountability Overview' : 'Visión General de desempeño'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('achievement', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Student Achievement' : 'Logros Estudiantiles'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('progress', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'School Progress' : 'Progreso Escolar'}
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            onMenuChange('gaps', 'performance');
                        }}
                    >
                        {lng === 'en' ? 'Closing the Gaps' : 'Cierre de Diferencias'}
                    </MenuItem>
                </Menu>

                <Menu
                    anchorEl={reportsMenuAnchor}
                    open={!!reportsMenuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setReportsMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=D&prgopt=reports%2Fsrc%2Fdrc.sas&id=' +
                                    district.id,
                                '_blank'
                            );
                        }}
                    >
                        2021-22
                    </MenuItem>
                    <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' +
                                    locserver +
                                    '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2021&lev=D&prgopt=reports%2Fsrc%2Fdrc.sas&id=' +
                                    district.id,
                                '_blank'
                            );
                        }}
                    >
                        2020-21
                    </MenuItem>
                    {/*     <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                'https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2020&lev=D&prgopt=reports%2Fsrc%2Fsrc.sas&id=' +
                                    district.id,
                                '_blank'
                            );
                        }}
                    >
                        2019-20
                    </MenuItem> */}
                    {/* <MenuItem
                        onClick={(evt) => {
                            setReportsMenuAnchor(null);
                            window.open(
                                ' https://' + locserver + '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&cyr=2019&level=district&search=distnum&prgopt=2019%2Fsrc%2Fsrc.sas&namenum=' +
                                    district.id,
                                '_blank'
                            );
                        }}
                    >
                        2018-19
                    </MenuItem> */}
                </Menu>

                <Menu
                    anchorEl={completeMenuAnchor}
                    open={!!completeMenuAnchor}
                    keepMounted={true}
                    onClose={() => {
                        setCompleteMenuAnchor(null);
                    }}
                    style={{ top: '2.5rem' }}
                >
                    <NestedMenuItem label='Performance' parentMenuOpen={!!completeMenuAnchor}>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('accountability', 'complete');
                            }}
                        >
                            {lng === 'en' ? 'Accountability Overview' : 'Visión General de desempeño'}
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('achievement', 'complete');
                            }}
                        >
                            {lng === 'en' ? 'Student Achievement' : 'Logros Estudiantiles'}
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('progress', 'complete');
                            }}
                        >
                            {lng === 'en' ? 'School Progress' : 'Progreso Escolar'}
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setCompleteMenuAnchor(null);
                                onMenuChangeSm('gaps', 'complete');
                            }}
                        >
                            {lng === 'en' ? 'Closing the Gaps' : 'Cierre de Diferencias'}
                        </MenuItem>
                    </NestedMenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            onMenuChangeSm('financem', 'complete');
                        }}
                    >
                        {lng === 'en' ? 'Finance' : 'Finanzas'}
                    </MenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            onMenuChangeSm('schoolsm', 'complete');
                        }}
                    >
                        {lng === 'en' ? 'Schools' : 'Escuelas'}
                    </MenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            window.open(
                                'https://rptsvr1.tea.texas.gov/perfreport/parentresources/index.html',
                                '_blank'
                            );
                        }}
                    >
                        {' '}
                        {lng === 'en' ? 'Parent Resources' : 'Recursos para padres'}
                    </MenuItem>

                    <MenuItem
                        onClick={(evt) => {
                            setCompleteMenuAnchor(null);
                            window.open(
                                'https://' + locserver + '/perfreport/account/va/school-compare.html?id=' + district.id,
                                '_blank'
                            );
                        }}
                    >
                        {' '}
                        {lng === 'en' ? 'Compare' : 'comparar'}
                    </MenuItem>

                    <NestedMenuItem label='PDF Reports' parentMenuOpen={!!completeMenuAnchor}>
                        <MenuItem
                            onClick={(evt) => {
                                setReportsMenuAnchor(null);
                                window.open(
                                    'https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2022&lev=D&prgopt=reports%2Fsrc%2Fdrc.sas&id=' +
                                        district.id,
                                    '_blank'
                                );
                            }}
                        >
                            2021-22
                        </MenuItem>
                        <MenuItem
                            onClick={(evt) => {
                                setReportsMenuAnchor(null);
                                window.open(
                                    'https://' +
                                        locserver +
                                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=2021&lev=D&prgopt=reports%2Fsrc%2Fdrc.sas&id=' +
                                        district.id,
                                    '_blank'
                                );
                            }}
                        >
                            2020-21
                        </MenuItem>
                    </NestedMenuItem>
                </Menu>

                <Paper>
                    {tab === 'overview' ? (
                        <Overview
                            district={district}
                            onNav={(page) => {
                                onMenuChange(page, 'performance');
                            }}
                        />
                    ) : null}
                    {menu === 'accountability' ? (
                        <Accountability
                            district={district}
                            onNav={(page) => {
                                onMenuChange(page, 'performance');
                            }}
                            show={menu === 'accountability'}
                        />
                    ) : null}
                    {menu === 'achievement' ? <Achievement district={district} /> : null}
                    {menu === 'progress' ? <Progress district={district} /> : null}
                    {menu === 'gaps' ? <Gaps district={district} /> : null}
                    {tab === 'finance' ? <Finance district={district} /> : null}
                    {menu === 'financem' ? <Finance district={district} /> : null}
                    {tab === 'schools' ? (
                        <Schools district={district} schools={schools} srclists={srclists} lng={lng} />
                    ) : null}
                    {menu === 'schoolsm' ? (
                        <Schools district={district} schools={schools} srclists={srclists} lng={lng} />
                    ) : null}
                </Paper>

                <TexasAssessment />
            </>
        );
    } else return <></>;
}

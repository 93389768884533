import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DataTable from 'react-data-table-component';

let lang = 'en';
if (window.location.href.includes('lng=es')) {
    lang = 'es';
}
// bar chart configurations --------------------------------
const optionsBar = {
    indexAxis: 'y',
    elements: {
        bar: {
            borderWidth: 1,
        },
    },
    barPercentage: 1.1,
    responsive: true,
    // aspectRatio: 1,
    scales: {
        x: {
            max: 120,
            display: false,
            grid: {
                display: false,
            },
        },
        y: {
            ticks: {
                font: {
                    size: 14,
                    weight: 'bold',
                },
                color: 'black',
            },
            grid: {
                display: false,
            },
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: true,
            position: 'top',
            align: 'start',
            font: {
                size: 20,
                weight: 'bold',
            },
            color: 'black',
        },
        datalabels: {
            anchor: 'end',
            align: 'end',
            formatter: (val) => `${val >= 0 ? val + '%' : '*'}`,
            labels: {
                value: {
                    color: '#236FAD',
                },
            },
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `${context.parsed.x >= 0 ? context.parsed.x + '%' : '*'}`;
                },
            },
            enabled: true,
            displayColors: false,
            titleColor: 'black',
            bodyColor: 'black',
            backgroundColor: 'white',
        },
    },
};
// bar chart labele and options setting above --------------------------------
export default function ChartjsAchievement({ entity, achieve }) {
    // 1. Common Setup --------------------------------
    let condition;
    if (!!entity && entity.entity_cd === 'C') {
        condition = 1; // campus.
    } else if (!!entity && entity.entity_cd === 'D') {
        condition = 2; // district.
    } else {
        condition = 0; // error.
    }
    // readin data;
    // different column names for col 2 and col 3 in entity and district case.
    const entityCol2 = condition === 1 ? 'This entity' : 'This District';
    const entityCol3 = condition === 1 ? 'District' : 'State';
    // use entity id to filter out data.
    const achieveMatchedList = achieve.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = achieveMatchedList.length === 0;
    // STARR Participation Rate string list: [0] for 2022, [1] for 2021:
    const dataparticipationListEntity = checkMatchedList ? ['', ''] : achieveMatchedList[0].participation;
    // Setup Ends--------------------------------

    // 3.Percentage of Students DT Setup --------------------------------
    const labelsAchieve = checkMatchedList ? ['', '', '', '', '', ''] : achieveMatchedList[0].subject;
    const dataapproachListEntity = checkMatchedList ? [0, 0, 0, 0, 0, 0] : achieveMatchedList[0].approach;
    const datameetListEntity = checkMatchedList ? [0, 0, 0, 0, 0, 0] : achieveMatchedList[0].meet;
    const datamasterListEntity = checkMatchedList ? [0, 0, 0, 0, 0, 0] : achieveMatchedList[0].master;
    // dataapproach  --------------------------------
    const dataapproach = {
        labels: labelsAchieve,
        datasets: [
            {
                data: dataapproachListEntity,
                borderColor: 'black',
                backgroundColor: ['#e7e3db', '#A1268F', '#A1268F', '#A1268F', '#A1268F', '#A1268F'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // datameet --------------------------------
    const datameet = {
        labels: labelsAchieve,
        datasets: [
            {
                data: achieveMatchedList.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedList[0].meet,
                borderColor: 'black',
                backgroundColor: ['#e7e3db', '#21448F', '#21448F', '#21448F', '#21448F', '#21448F'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // datamaster --------------------------------
    const datamaster = {
        labels: labelsAchieve,
        datasets: [
            {
                data: achieveMatchedList.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedList[0].master,
                borderColor: 'black',
                backgroundColor: ['#e7e3db', '#0E8241', '#0E8241', '#0E8241', '#0E8241', '#0E8241'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 14,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // switch condition --------------------------------
    let mapLabel,
        columnsDTapproach,
        columnsDTmeet,
        columnsDTmaster,
        dataDTapproach,
        dataDTmeet,
        dataDTmaster = [];

    switch (condition) {
        case 1: {
            const achieveMatchedListDistrict = achieve.filter((item) => {
                return item.id === entity.district_id;
            });
            const datasubjectListDistrict =
                achieveMatchedListDistrict.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListDistrict[0].subject;
            // mapLabel returns Array of subject index of selected school in corresponding district.
            mapLabel = labelsAchieve.map(function (value, index) {
                return datasubjectListDistrict.indexOf(value);
            });
            const dataapproachListDistrict =
                achieveMatchedListDistrict.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListDistrict[0].approach;
            const datameetListDistrict =
                achieveMatchedListDistrict.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListDistrict[0].meet;
            const datamasterListDistrict =
                achieveMatchedListDistrict.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListDistrict[0].master;
            columnsDTapproach = [
                {
                    wrap: true,
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    selector: (row) => row.subject,
                },
                {
                    name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This School' : 'Esta Escuela'}</div>,
                    selector: (row) => row.Entity,
                    style: {
                        color: '#A1268F',
                    },
                },
                {
                    name: lang === 'en' ? 'District' : 'Distrito',
                    selector: (row) => row.District,
                },
            ];
            columnsDTmeet = [
                {
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    wrap: true,
                    selector: (row) => row.subject,
                },
                {
                    name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This School' : 'Esta Escuela'}</div>,
                    selector: (row) => row.Entity,
                    style: {
                        color: '#21448F',
                    },
                },
                {
                    name: lang === 'en' ? 'District' : 'Distrito',
                    selector: (row) => row.District,
                },
            ];
            columnsDTmaster = [
                {
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    wrap: true,
                    selector: (row) => row.subject,
                },
                {
                    name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This School' : 'Esta Escuela'}</div>,
                    selector: (row) => row.Entity,
                    style: {
                        color: '#0E8241',
                    },
                },
                {
                    name: lang === 'en' ? 'District' : 'Distrito',
                    selector: (row) => row.District,
                },
            ];
            dataDTapproach = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${dataapproachListEntity[index] >= 0 ? dataapproachListEntity[index] + '%' : '*'}`,
                    // Entity: `${dataapproachListEntity[index]}%`,
                    District: `${dataapproachListDistrict[mapLabel[index]]}%`,
                };
            });
            dataDTmeet = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${datameetListEntity[index] >= 0 ? datameetListEntity[index] + '%' : '*'}`,
                    District: `${datameetListDistrict[mapLabel[index]]}%`,
                };
            });
            dataDTmaster = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${datamasterListEntity[index] >= 0 ? datamasterListEntity[index] + '%' : '*'}`,
                    District: `${datamasterListDistrict[mapLabel[index]]}%`,
                };
            });
            break;
        }
        case 2: {
            const achieveMatchedListState = achieve.filter((item) => {
                return item.id === '48000'; // TEXAS STATE
            });
            const datasubjectListState =
                achieveMatchedListState.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListState[0].subject;
            // mapLabel returns Array of subject index of selected district in state.
            mapLabel = labelsAchieve.map(function (value, index) {
                return datasubjectListState.indexOf(value);
            });
            const dataapproachListState =
                achieveMatchedListState.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListState[0].approach;
            const datameetListState =
                achieveMatchedListState.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListState[0].meet;
            const datamasterListState =
                achieveMatchedListState.length === 0 ? [0, 0, 0, 0, 0, 0] : achieveMatchedListState[0].master;
            columnsDTapproach = [
                {
                    wrap: true,
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    selector: (row) => row.subject,
                },
                {
                    name: (
                        <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This District' : 'Este Distrito'}</div>
                    ),
                    selector: (row) => row.Entity,
                    style: {
                        color: '#A1268F',
                    },
                },
                {
                    name: lang === 'en' ? 'State' : 'Estado',
                    selector: (row) => row.State,
                },
            ];
            columnsDTmeet = [
                {
                    wrap: true,
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    selector: (row) => row.subject,
                },
                {
                    name: (
                        <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This District' : 'Este Distrito'}</div>
                    ),
                    selector: (row) => row.Entity,
                    style: {
                        color: '#21448F',
                    },
                },
                {
                    name: lang === 'en' ? 'State' : 'Estado',
                    selector: (row) => row.State,
                },
            ];
            columnsDTmaster = [
                {
                    wrap: true,
                    name: lang === 'en' ? 'Subject' : 'Materia',
                    selector: (row) => row.subject,
                },
                {
                    name: (
                        <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'This District' : 'Este Distrito'}</div>
                    ),
                    selector: (row) => row.Entity,
                    style: {
                        color: '#0E8241',
                    },
                },
                {
                    name: lang === 'en' ? 'State' : 'Estado',
                    selector: (row) => row.State,
                },
            ];
            dataDTapproach = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${dataapproachListEntity[index] >= 0 ? dataapproachListEntity[index] + '%' : '*'}`,
                    State: `${dataapproachListState[mapLabel[index]]}%`,
                };
            });
            dataDTmeet = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${datameetListEntity[index] >= 0 ? datameetListEntity[index] + '%' : '*'}`,
                    State: `${datameetListState[mapLabel[index]]}%`,
                };
            });
            dataDTmaster = labelsAchieve.map(function (value, index) {
                return {
                    id: index,
                    subject: value,
                    Entity: `${datamasterListEntity[index] >= 0 ? datamasterListEntity[index] + '%' : '*'}`,
                    State: `${datamasterListState[mapLabel[index]]}%`,
                };
            });
            break;
        }
    }
    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'center',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '12pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                fontSize: '12pt',
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                justifyContent: 'center',
            },
        },
    };
    // Setup Ends--------------------------------

    // DT datatable data setting above --------------------------------
    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={4}>
                    <Box style={{ height: '100%', padding: '.5rem', border: '.1px solid' }}>
                        <b style={{ fontSize: '1.5rem', color: '#A1268F' }}>
                            {' '}
                            {lang === 'en'
                                ? 'Percentage of Students Approaching Grade Level or Above'
                                : 'Porcentaje de Estudiantes que se Acercan al Nivel de Grado o Superior'}{' '}
                        </b>
                        <br />
                        <Bar options={optionsBar} data={dataapproach} style={{ height: '17rem' }} />
                        <DataTable
                            columns={columnsDTapproach}
                            data={dataDTapproach}
                            customStyles={customStyles}
                            highlightOnHover
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box style={{ height: '100%', padding: '.5rem', border: '.1px solid' }}>
                        <b style={{ fontSize: '1.5rem', color: '#21448F' }}>
                            {' '}
                            {lang === 'en'
                                ? 'Percentage of Students Meeting Grade Level or Above'
                                : 'Porcentaje de Estudiantes que Cumplen con el Nivel de Grado o Superior'}{' '}
                        </b>
                        <br />
                        <Bar options={optionsBar} data={datameet} style={{ height: '17rem' }} />
                        <DataTable
                            columns={columnsDTmeet}
                            data={dataDTmeet}
                            customStyles={customStyles}
                            highlightOnHover
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Box style={{ height: '100%', padding: '.5rem', border: '.1px solid' }}>
                        <b style={{ fontSize: '1.5rem', color: '#0E8241' }}>
                            {' '}
                            {lang === 'en'
                                ? 'Percentage of Students Mastering Grade Level or Above'
                                : 'Porcentaje de Estudiantes que Dominan el Nivel de Grado '}{' '}
                        </b>
                        <br />
                        <Bar options={optionsBar} data={datamaster} style={{ height: '17rem' }} />
                        <DataTable
                            columns={columnsDTmaster}
                            data={dataDTmaster}
                            customStyles={customStyles}
                            highlightOnHover
                        />
                    </Box>
                </Grid>
            </Grid>

            <span style={{ fontSize: '.8rem' }}>
                {lang === 'en'
                    ? '*	Indicates results are masked due to small numbers to protect student confidentiality'
                    : '*	Indica que los resultados están enmascarados debido a los números pequeños para proteger la confidencialidad del estudiante'}
            </span>
            <br />
            <br />
            {dataparticipationListEntity[0]}
            <br />
            {dataparticipationListEntity[1]}
            <br />
        </div>
    );
}

import React, { createElement } from 'react';

export default () => {
    return (
        
        <div style={{ textAlign: 'center', fontSize: '1.2rem' }}>
            <p><img src='./images/school_building_black.png' alt='paired school building' style={{ height: '2.5rem' }}/>
            &nbsp;This school is a paired school and does not have sufficient data for their own rating.</p>
        </div> 
    );
};

import { red } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#0D6CB9',
        },
        secondary: {
            main: '#704280',
        },
        tertiary: {
            main: '#F16038',
            dark: '#D85632',
        },
        error: {
            main: '#DA3E26',
        },
        text: {
            primary: '#000000',
            secondary: '#AAAAAA',
            tertiary: '#FFFFFF',
        },
        background: {
            default: '#FFFFFF',
            secondary: '#704280',
            tertiary: '#E7E3DB',
        },
        muted: {
            main: '#E7E3DB',
            dark: '#5A6267',
        },
    },
    typography: {
        fontFamily: 'Arial',
    },
    shape: {
        borderRadius: '.25rem',
    },
    link: {
                '&:focus': {
                 outline: 'green solid 3px',
                 },
    },
    overrides: {
        MuiLink: {
            root: {
                color: 'white',
                '&:focus': {
                 outline: 'green solid 3px',
                           },
                  },
                 },
    
     
     },
});

export default theme;

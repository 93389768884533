import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import DataTable from 'react-data-table-component';

export default function ChartjsAchievementcalc({ entity, achieve }) {
    // 1. Common Setup --------------------------------
    let condition;
    if (!!entity && entity.entity_cd === 'C') {
        condition = 1; // campus.
    } else if (!!entity && entity.entity_cd === 'D') {
        condition = 2; // district.
    } else {
        condition = 0; // error.
    }

    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }
    // readin data;
    // different column names for col 2 and col 3 in entity and district case.
    const entityCol2 = condition === 1 ? 'This entity' : 'This District';
    const entityCol3 = condition === 1 ? 'District' : 'State';
    // use entity id to filter out data.
    const achieveMatchedList = achieve.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = achieveMatchedList.length === 0;
    // STARR Participation Rate string list: [0] for 2022, [1] for 2021:
    const dataparticipationListEntity = checkMatchedList ? ['', ''] : achieveMatchedList[0].participation;
    // Setup Ends--------------------------------

    // 2. Student Achievement Calculation DT Setup --------------------------------
    const labelsAchieveCalc = checkMatchedList ? ['', '', '', ''] : achieveMatchedList[0].component;
    const datascoreListEntity = checkMatchedList ? ['', '', '', ''] : achieveMatchedList[0].score;
    const datapct_of_gradeListEntity = checkMatchedList ? ['', '', '', ''] : achieveMatchedList[0].pct_of_grade;
    const dataDTStuCalc = labelsAchieveCalc.map(function (value, index) {
        return {
            id: index,
            component: value,
            score: datascoreListEntity[index],
            pct_of_grade: datapct_of_gradeListEntity[index],
        };
    });
    const columnsDTStuCalc = [
        {
            grow: 4,
            name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'Component' : 'Componente'}</div>,
            cell: (row) => row.component,
            style: {
                justifyContent: 'left',
                textAlign: 'left',
            },
        },
        {
            grow: 1,
            name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? 'Score' : 'Puntaje'}</div>,
            selector: (row) => row.score,
        },
        {
            grow: 1,
            name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? '% of Grade' : '% de Grado'}</div>,
            selector: (row) => row.pct_of_grade,
        },
    ];
    const conditionalRowStylesStuCalc = [
        {
            when: (row) => row.component === 'Total',
            style: {
                backgroundColor: '#E7E3DB',
                fontWeight: 'bold',
            },
        },
    ];

    const customStylesStuCalc = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
                justifyContent: 'center',
            },
        },
    };
    // Setup Ends--------------------------------

    // Setup Ends--------------------------------

    // DT datatable data setting above --------------------------------
    return (
        <div>
            <div style={{ margin: '0% 10% 0%' }}>
                <DataTable
                    columns={columnsDTStuCalc}
                    data={dataDTStuCalc}
                    customStyles={customStylesStuCalc}
                    conditionalRowStyles={conditionalRowStylesStuCalc}
                    highlightOnHover
                />
            </div>
        </div>
    );
}

import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Modal } from 'react-bootstrap';
import '../modal.css';
export default function ModalEntity({
    entity,
    modalId,
    lang,
    extraContent,
    extraUrl,
    modalId2,
    customButtonColor,
    customButtonTitle,
}) {
    const [showov, setShowov] = useState(false);
    const handleCloseov = () => {
        setShowov(!showov);
    };
    const [modal_content, setModal_content] = useState([]);
    useEffect(() => {
        fetch('./data/modal_content.json?v=.0001')
            .then((resp) => resp.json())
            .then((json) => {
                setModal_content(json);
            });
    }, []);
    const modal_contentMatchedList = modal_content.filter((item) => {
        return item.content_id === modalId;
    });
    const checkMatchedContent = modal_contentMatchedList.length === 0;
    const content_id = checkMatchedContent ? 'missing' : modal_contentMatchedList[0].content_id;
    const title_en = checkMatchedContent ? 'missing' : modal_contentMatchedList[0].title_en;
    const title_es = checkMatchedContent ? 'missing' : modal_contentMatchedList[0].title_es;
    let button_title = lang === 'es' ? 'Cuénteme Más' : 'Tell Me More';
    if (!!customButtonTitle) {
        button_title = customButtonTitle;
    }
    const title = !!title_es && lang === 'es' ? title_es : title_en;
    const content_en = checkMatchedContent ? 'missing' : modal_contentMatchedList[0].content_en;
    const content_es = checkMatchedContent ? 'missing' : modal_contentMatchedList[0].content_es;
    const content = !!content_es && lang === 'es' ? content_es : content_en;
    const urlExtracted = content.replace(/(.*w3-include-html.*\/perfreport)(.*\.html).*|(.*)/, '$2');
    // if there is extra modal: modalId2, extract content and load into the same modal.
    let modal_contentMatchedList2,
        checkMatchedContent2,
        title_en2,
        title_es2,
        title2,
        content_en2,
        content_es2,
        content2,
        urlExtracted2;
    if (!!modalId2) {
        modal_contentMatchedList2 = modal_content.filter((item) => {
            return item.content_id === modalId2;
        });
        checkMatchedContent2 = modal_contentMatchedList.length === 0;
        title_en2 = checkMatchedContent2 ? 'missing' : modal_contentMatchedList2[0].title_en;
        title_es2 = checkMatchedContent2 ? 'missing' : modal_contentMatchedList2[0].title_es;
        title2 = !!title_es2 && lang === 'es' ? title_es2 : title_en2;
        content_en2 = checkMatchedContent2 ? 'missing' : modal_contentMatchedList2[0].content_en;
        content_es2 = checkMatchedContent2 ? 'missing' : modal_contentMatchedList2[0].content_es;
        content2 = !!content_es2 && lang === 'es' ? content_es2 : content_en2;
        urlExtracted2 = content2.replace(/(.*w3-include-html.*\/perfreport)(.*\.html).*|(.*)/, '$2');
    }

    return (
        <>
            <Button
                variant='contained'
                color={!!customButtonColor ? customButtonColor : 'secondary'}
                onClick={handleCloseov}
            >
                {button_title}
            </Button>

            <Modal show={showov} onHide={handleCloseov}>
                <Modal.Body style={{ width: '100%', height: '90%', overflowY: 'auto', overflowX: 'auto' }}>
                    <h3>{title}</h3>
                    <div dangerouslySetInnerHTML={{ __html: content }} />
                    {!!urlExtracted ? (
                        <iframe
                            style={{
                                position: 'relative',
                                top: '0',
                                left: '0',
                                bottom: '0',
                                right: '0',
                                width: '100%',
                                height: '100%',
                            }}
                            src={'.' + urlExtracted}
                        />
                    ) : null}
                    {!!extraContent ? <div dangerouslySetInnerHTML={{ __html: extraContent }} /> : null}
                    {!!extraUrl ? (
                        <iframe
                            style={{
                                position: 'relative',
                                top: '0',
                                left: '0',
                                bottom: '0',
                                right: '0',
                                width: '100%',
                                height: '100%',
                                overflow: 'hide',
                            }}
                            src={extraUrl}
                        />
                    ) : null}
                    {!!modalId2 ? (
                        <>
                            <h3>{title2}</h3>
                            <div dangerouslySetInnerHTML={{ __html: content2 }} />
                            {!!urlExtracted2 ? (
                                <iframe
                                    style={{
                                        position: 'relative',
                                        top: '0',
                                        left: '0',
                                        bottom: '0',
                                        right: '0',
                                        width: '100%',
                                        height: '65%',
                                        overflowX: 'auto',
                                        overflowY: 'auto',
                                    }}
                                    src={'.' + urlExtracted2}
                                />
                            ) : null}
                        </>
                    ) : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button style={{ margin: '0 auto', display: 'flex' }} variant='contained' onClick={handleCloseov}>
                        {lang === 'es' ? 'Cerrar' : 'Close'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

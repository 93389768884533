import { createElement, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';
import Downshift from 'downshift';
import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import { Icon, Button } from '@material-ui/core';
import { Search, Clear } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';

function renderInput({
    InputProps,
    classes,
    ref,
    selectedItem,
    isOpen,
    clearSelection,
    openMenu,
    closeMenu,
    ...other
}) {
    return (
        <div>
            <TextField
                id='TextField' fullWidth multiline minRows={1} maxRows={2} 
                InputProps={{
                    inputRef: ref,
                    classes: {
                        input: classes.inputInput,
                    },
                    ...InputProps,
                }}
                style={{ backgroundColor: 'white' }}
                {...other}
            />
        </div>
    );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem, lng }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = selectedItem === null ? false : (selectedItem.label || '').indexOf(suggestion.label) > -1;

    const school = lng === 'en' ? 'School' : 'Escuela';
    const district = lng === 'en' ? 'District' : 'Distrito';
    const county = lng === 'en' ? 'County' : 'Condado';

    const typeLabel = suggestion.entity_cd === 'C' ? school : district;
    const location =
        suggestion.entity_cd === 'C' ? district + ': ' + suggestion.district_name : county + ': ' + suggestion.county;

    return (
        <MenuItem
            {...itemProps}
            key={suggestion.id}
            value={suggestion.id}
            selected={isHighlighted}
            component='div'
            style={{
                fontWeight: isSelected ? 500 : 400,
                backgroundColor: '#EEEEEE',
                margin: '.25rem',
                border: isHighlighted ? '#00ACBB outset' : '',
                
            }}
        >
            <div>
                <div >{suggestion.name}</div>
                <div style={{ color: '#0D6CB9' }}>{typeLabel}</div>
                <div>{location}</div>
            </div>
        </MenuItem>
    );
}
renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.object,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(suggestions, value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    return inputLength === 0
        ? []
        : suggestions.filter((suggestion) => {
              const keep = count < 10 && suggestion.name.toLowerCase().includes(inputValue);

              if (keep) {
                  count += 1;
              }

              return keep;
          });
}

const styles = (theme) => ({
    root: {},
    container: {
        flexGrow: 1,
        position: 'relative',
    },
    paper: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(),
        left: 0,
        right: 0,
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    inputRoot: {
        // flexWrap: 'wrap',
    },
    inputInput: {
        padding: '.5rem',
        backgroundColor: 'white',
        fontWeight: '700',
    },
    divider: {
        height: theme.spacing(2),
    },
});

/**   Card filter with autosuggest
 * @param placeholder - placeholder text to prompt input
 * @param filterName - string to identify the filter being processed.  This can be dynamic.
 * @param onChange - function process any change.  
 *    It will be passed a hash with "selection" being the selected 
 *    item and filterName equal to the input "filterName"
 * @param suggestions - JSON array with items to select. There should be properties for "label", "value" and optionally "address"
 * 
 * @example
 * <CardFilterAutosuggest   
        placeholder={"Search by "+["County","District","School"][ ["counties","districts","schools"].indexOf(viewType)]}
        onChange={handleFilterChange} 
        suggestions={
            viewType === "schools" ? srclists.schools.filter((item) => item.year = "2019")
            : srclists[viewType] }/>
 * 
 */

function CardFilterAutosuggest(props) {
    const { classes, lng, suggestions } = props;

    const headerWidth = !!props.inHeader ? 'max-content' : '';

    const [selectedItem, setSelectedItem] = useState(null);

    const onSelected = (selection) => {
        setSelectedItem(selection);

        if (!!selection) {
            const filterName = selection.entity_cd === 'C' ? 'school' : 'district';
            props.onChange({ selection, filterName: filterName });
        }
    };

    const ariaLabelId = 'cardFilterAutoSuggest' + (!!props.inHeader ? '-header' : '');

    return (
        <div className={classes.root} style={{ width: headerWidth }}>
            <label id={ariaLabelId + '-label'} style={{ display: 'none' }}>
                {props.placeholder || ''}
            </label>
            <Downshift id={ariaLabelId} onChange={onSelected} itemToString={(item) => ''}>
                {({
                    getInputProps,
                    getItemProps,
                    getMenuProps,
                    highlightedIndex,
                    inputValue,
                    isOpen,
                    openMenu,
                    closeMenu,
                    selectedItem,
                    clearSelection,
                }) => (
                    <div className={classes.container}>
                        {renderInput({
                            fullWidth: true,
                            classes,
                            selectedItem,
                            isOpen,
                            openMenu,
                            closeMenu,
                            clearSelection,
                            InputProps: getInputProps({
                                placeholder: props.placeholder || '',
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <Search style={{ marginLeft: '.25rem', color: '#e7e3db' }} />
                                    </InputAdornment>
                                ),
                                 endAdornment: !!selectedItem ? (
                                    <InputAdornment position='end'>
                                        <Button
                                            style={{ float: 'right' }}
                                            onClick={clearSelection}
                                            aria-label='clear selection'
                                        >
                                            <Clear />
                                        </Button>
                                    </InputAdornment>
                                ) : null,
                                'aria-labelledby': null, 
                            }),
                        })}

                        <div {...getMenuProps()}>
                            {isOpen ? (
                                <Paper className={classes.paper} square>
                                    {getSuggestions(suggestions, inputValue).map((suggestion, index) =>
                                        renderSuggestion({
                                            suggestion,
                                            index,
                                            itemProps: getItemProps({ item: suggestion }),
                                            highlightedIndex,
                                            selectedItem,
                                            lng,
                                        })
                                    )}
                                </Paper>
                            ) : null}
                        </div>
                    </div>
                )}
            </Downshift>
        </div>
    );
}
CardFilterAutosuggest.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CardFilterAutosuggest);

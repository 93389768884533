import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
export default function DtCTG({ entity }) {
    let lang = 'en';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
    }

    const [ctg, setCtg] = useState([]);

   useEffect(() => {
        const jsonFile = lang === 'en' ? './data/ctg_tab.json' : './data/ctg_tab_es.json';
        fetch(jsonFile)
            .then((resp) => resp.json())
            .then((json) => {
                setCtg(json);
            });
    }, [entity, lang]);

    // use entity id to filter out data.
    const ctgMatchedList = ctg.filter((item) => {
        return item.id === entity.id;
    });
    const checkMatchedList = ctgMatchedList.length === 0;
    const labelsCtg = checkMatchedList ? ['', '', '', '', ''] : ctgMatchedList[0].component;
    const datascoreListEntity = checkMatchedList ? ['', '', '', '', ''] : ctgMatchedList[0].score;
    const datapct_of_gradeListEntity = checkMatchedList ? ['', '', '', '', ''] : ctgMatchedList[0].pct_of_grade;
    const dataDT = labelsCtg.map(function (value, index) {
        return {
            id: index,
            component: value,
            score: datascoreListEntity[index],
            pct_of_grade: datapct_of_gradeListEntity[index],
        };
    });
    const columnsDT = [
        {
            name: lang === 'en' ? 'Component' : 'Componente',
            cell: (row) => row.component,
        },
        {
            name: lang === 'en' ? 'Score' : 'Puntaje',
            selector: (row) => row.score,
        },
        {
            name: <div style={{ textOverflow: 'unset' }}>{lang === 'en' ? '% of Grade' : '% de Grado'}</div>,
            selector: (row) => row.pct_of_grade,
        },
    ];

    const conditionalRowStyles = [
        {
            when: (row) => row.component === 'Total',
            style: {
                backgroundColor: '#E7E3DB',
                fontWeight: 'bold',
            },
        },
    ];
    const customStyles = {
        headRow: {
            style: {
                border: 'none',
            },
        },
        headCells: {
            style: {
                justifyContent: 'center',
                backgroundColor: '#E7E3DB',
                borderStyle: 'none',
                fontSize: '13pt',
                fontWeight: 'bold',
                verticalAlign: 'bottom',
            },
        },
        rows: {
            style: {
                borderStyle: 'solid none',
                borderWidth: '0.5px',
            },
            highlightOnHoverStyle: {
                backgroundColor: 'rgb(230, 244, 244)',
                borderBottomColor: '#FFFFFF',
                borderRadius: '25px',
            },
        },
        cells: {
            style: {
                fontSize: '12pt',
                justifyContent: 'center',
            },
        },
    };
    return (
        <div style={{ margin: '0% 10% 0%' }}>
            <DataTable
                columns={columnsDT}
                data={dataDT}
                customStyles={customStyles}
                conditionalRowStyles={conditionalRowStyles}
                highlightOnHover
            />
        </div>
    );
}

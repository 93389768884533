import { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

export default function ChartjsRaceEthnicity2({  enroll, lang }) {
    // label and options setting below --------------------------------
    // optionsEnrollment --------------------------------

    // optionsStaff_Years --------------------------------
    const labelsStaff_Years = [
        lang === 'en' ? 'Beginning Teachers' : 'Maestros Principiantes',
        lang === 'en' ? '1 to 5 Years' : '1 a 5 Años',
        lang === 'en' ? '6 to 10 Years' : '6 a 10 Años',
        lang === 'en' ? '11 to 20 Years' : '11 a 20 Años',
        lang === 'en' ? '21 to 30 Years' : '21 a 30 Años',
        lang === 'en' ? '> 30 Years' : '> 30 Años',
    ];

    const optionsStaff_Years = {
        layout: {
            padding: {
                right: 45,
            },
        },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        // aspectRatio: 1,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: lang === 'en' ? 'Teachers by Years of Experience,' : 'Profesores por Años de Experiencia',
                position: 'top',
                align: 'start',
                font: {
                    size: 19,
                    weight: 'bold',
                },
                color: 'black',
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (val) => `${val}%`,
                labels: {
                    value: {
                        color: '#236FAD',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.parsed.x}%`;
                    },
                },
                enabled: true,
                displayColors: false,
                titleColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white',
            },
        },
    };
    // optionsStaff_Race --------------------------------
    const labelsStaff_Race = [
        lang === 'en' ? 'African American' : 'Afroamericano',
        lang === 'en' ? 'Hispanic' : 'Hispánico',
        lang === 'en' ? 'White' : 'Blanco',
        lang === 'en' ? 'American Indian' : 'Indio Americano',
        lang === 'en' ? 'Asian' : 'Asiático',
        lang === 'en' ? 'Pacific Islander' : 'Isleño del Pacífico',
        lang === 'en' ? 'Two or More Races' : 'Dos o mas Razas',
    ];
    const optionsStaff_Race = {
        layout: {
            padding: {
                right: 45,
            },
        },
        indexAxis: 'y',
        elements: {
            bar: {
                borderWidth: 1,
            },
        },
        responsive: true,
        // aspectRatio: 1,
        scales: {
            x: {
                display: false,
                grid: {
                    display: false,
                },
            },
            y: {
                ticks: {
                    font: {
                        size: 12,
                        weight: 'bold',
                    },
                    color: 'black',
                },
                grid: {
                    display: false,
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: lang === 'en' ? 'Teachers by Race/Ethnicity' : 'Maestros por Raza/Etnia',
                position: 'top',
                align: 'start',
                font: {
                    size: 19,
                    weight: 'bold',
                },
                color: 'black',
            },
            datalabels: {
                anchor: 'end',
                align: 'end',
                formatter: (val) => `${val}%`,
                labels: {
                    value: {
                        color: '#236FAD',
                    },
                },
            },
            tooltip: {
                callbacks: {
                    label: function (context) {
                        return `${context.parsed.x}%`;
                    },
                },
                enabled: true,
                displayColors: false,
                titleColor: 'black',
                bodyColor: 'black',
                backgroundColor: 'white',
            },
        },
    };
    // labele and options setting above --------------------------------
    // data for different charts
    // data setting below --------------------------------
    // dataEnrollment  --------------------------------
    // dataStaff_Years --------------------------------
    const dataStaff_Years = {
        labels: labelsStaff_Years,
        datasets: [
            {
                data: enroll.length === 0 ? [0, 0, 0, 0, 0, 0, 0] : enroll[0].Staff_Years,
                borderColor: 'black',
                backgroundColor: ['#C7E3F9', '#7DC3FC', '#33A3FF', '#2C8AD6', '#2470AD', '#2470AD'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // dataStaff_Race --------------------------------
    const dataStaff_Race = {
        labels: labelsStaff_Race,
        datasets: [
            {
                data: enroll.length === 0 ? [0, 0, 0, 0, 0, 0, 0] : enroll[0].Staff_Race,
                borderColor: 'black',
                backgroundColor: ['#548235', '#56B7E6', '#F16038', '#E7E3DB', '#92C740', '#704280', '#ECAF33'],
                datalabels: {
                    align: 'right',
                    font: {
                        size: 13,
                        weight: 'bold',
                    },
                },
            },
        ],
    };
    // data setting above --------------------------------
    // single point data setting--------------------------------
    const dataStu_Per_Staff = enroll.length === 0 ? 0 : enroll[0].Stu_Per_Staff;
    const dataFull_Time_Staff = enroll.length === 0 ? 0 : enroll[0].Full_Time_Staff;
    const dataFull_Time_Teachers = enroll.length === 0 ? 0 : enroll[0].Full_Time_Teachers;
    const dataAvg_Salary = enroll.length === 0 ? 0 : enroll[0].Avg_Salary;

    return (
        <Grid container>
            <Grid container spacing={2}>
                {dataAvg_Salary !== 0 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <br />
                        <b style={{ fontSize: '1.3rem', marginLeft: '5px' }}>
                            {' '}
                            {lang === 'en'
                                ? 'Number of Students Per Teacher'
                                : 'Numero de Estudiantes por Maestro'}{' '}
                        </b>
                        <br />{' '}
                        <b style={{ fontSize: '2rem', color: 'blue', marginLeft: '25px' }}>{dataStu_Per_Staff}</b>
                        <br />
                        <br />
                    </Grid>
                ) : (
                    <p style={{ textAlign: 'center', fontSize: '2rem', fontWeight: '600', marginLeft: '1rem' }}>
                        {lang === 'en' ? 'No Information Available' : 'No hay Información Disponible'}{' '}
                    </p>
                )}
                {dataAvg_Salary !== 0 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <br />
                        <b style={{ fontSize: '1.3rem', marginLeft: '5px' }}>
                            {' '}
                            {lang === 'en' ? 'Number of Full-time Staff' : 'Número de Personal a Tiempo Completo'}{' '}
                        </b>
                        <br />{' '}
                        <b style={{ fontSize: '2rem', color: 'blue', marginLeft: '25px' }}>
                            {Intl.NumberFormat().format(dataFull_Time_Staff)}
                        </b>
                        <br />
                        <br />
                    </Grid>
                ) : null}
                {dataAvg_Salary !== 0 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <br />
                        <b style={{ fontSize: '1.3rem', marginLeft: '5px' }}>
                            {' '}
                            {lang === 'en' ? 'Number of Full-time Teachers' : 'Número de Maestros a Tiempo Completo'}
                        </b>
                        <br />{' '}
                        <b style={{ fontSize: '2rem', color: 'blue', marginLeft: '25px' }}>
                            {Intl.NumberFormat().format(dataFull_Time_Teachers)}
                        </b>
                        <br />
                        <br />
                    </Grid>
                ) : null}
                {dataAvg_Salary !== 0 ? (
                    <Grid item xs={12} sm={12} md={3}>
                        <br />
                        <b style={{ fontSize: '1.3rem', marginLeft: '5px' }}>
                            {' '}
                            {lang === 'en' ? 'Average Teacher Salary' : 'Salario Promedio de Maestros'}{' '}
                        </b>
                        <br />{' '}
                        <b style={{ fontSize: '2rem', color: 'blue', marginLeft: '25px' }}>
                            ${Intl.NumberFormat().format(dataAvg_Salary)}
                        </b>
                        <br />
                        <br />
                    </Grid>
                ) : null}

                {dataAvg_Salary !== 0 ? (
                    <Grid item style={{ marginTop: '2rem' }} xs={12} sm={12} md={6}>
                        <Bar options={optionsStaff_Years} data={dataStaff_Years} style={{ height: '33rem' }} />
                    </Grid>
                ) : null}
                {dataAvg_Salary !== 0 ? (
                    <Grid item style={{ marginTop: '2rem' }} xs={12} sm={12} md={6}>
                        <Bar options={optionsStaff_Race} data={dataStaff_Race} style={{ height: '33rem' }} />
                    </Grid>
                ) : null}
            </Grid>
        </Grid>
    );
}

import React, { createElement, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import PairedSchool from '../../components/PairedSchool';
import utils from '../../components/utils';
import { CircularProgress } from '@material-ui/core';
import '../../bootstrap_m.css';
import DtCTG from '../../components/dtCTG';
import ModalEntity from '../../components/modalEntity';

const useStyles = makeStyles((theme) => ({
    header: {
        fontSize: '1.5rem',
    },
    subHeader: {
        color: theme.palette.secondary.main,
        fontSize: '1.5rem',
    },
    subHeadernr: {
        fontSize: '3rem',
    },
    subHeadera: {
        color: theme.palette.primary.main,
        fontSize: '1.5rem',
        backgroundColor: '#FFFFFF',
    },

    gridItem: {
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.background.tertiary,
        backgroundColor: theme.palette.background.default,
        borderRadius: '.25rem',
        textAlign: 'center',
    },

    perfGridItem: {
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfBox: {
        margin: '.25rem',
        padding: '.5rem',
        border: '.1px solid ' + theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        height: '100%',
        minHeight: '32rem',
    },
    perfBox1: {
        margin: '.75rem',
        border: '.1px solid ' + theme.palette.primary.main,
        backgroundColor: theme.palette.background.default,
        height: '100%',
    },
    perfWrapper: {},
    perfHeader: {
        color: theme.palette.text.tertiary,
        backgroundColor: theme.palette.primary.main,
        minHeight: '3rem',
        margin: 0,
        fontWeight: 'bold',
        fontSize: '1.15rem',
    },
    perfIcon: {
        height: '3.5rem',
        float: 'left',
        padding: '.5rem',
    },
    perfSubHeader: {
        fontSize: '1.5rem',
        height: '3rem',
    },
    perfText: {
        textAlign: 'left',
        padding: '.5rem',
        minHeight: '5rem',
    },
    perfTell: {
        // padding: '.2rem',
        // minHeight: '2rem',
        marginBottom: '1.1rem',
        marginTop: '.9rem',
    },
    sectionButton: {
        marginTop: '1rem',
        marginBottom: '1rem',
        width: '12rem',
    },

    secButton: {
        position: 'relative',
        marginTop: '1rem',
        marginBottom: '1rem',
    },

    section: {
        color: theme.palette.primary.main,
        width: '100%',
        textAlign: 'center',
        backgroundColor: theme.palette.background.default,
        margin: '1rem 0',
    },
    sectionTitle: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.tertiary,
        fontSize: '1.5rem',
        padding: '.5rem',
    },
    sectionContent: {
        margin: 'auto',
        width: '95%',
        backgroundColor: theme.palette.background.default,
        fontSize: '1.25rem',
        padding: '1rem',
    },
    sectionContentb: {
        margin: 'auto',
        width: '95%',
        height: '51rem',
        backgroundColor: theme.palette.background.default,
        fontSize: '1.25rem',
        padding: '1rem',
    },
    sectionContentbb: {
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        position: 'relative',
    },
    grade: {
        color: '#414a4c',
    },
    gradea: {
        color: '#278143',
    },
    gradeb: {
        color: '#39398C',
    },
    gradec: {
        color: '#9C118B',
    },
    graded: {
        color: '#E0803D',
    },
    gradef: {
        color: '#CA313C',
    },
    Gradeheader: {
        fontSize: '3rem',
        color: '#414a4c',
        padding: '40px 0px 0px 45px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheader1: {
        fontSize: '3rem',
        color: '#414a4c',
        padding: '40px 0px 0px 36px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheader0: {
        fontSize: '3rem',
        color: '#414a4c',
        padding: '40px 0px 0px 58px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadera: {
        fontSize: '6rem',
        color: '#278143',
        padding: '14px 0px 0px 51px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderb: {
        fontSize: '6rem',
        color: '#39398C',
        padding: '14px 0px 0px 56px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderc: {
        fontSize: '6rem',
        color: '#9C118B',
        padding: '14px 0px 0px 50px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderd: {
        fontSize: '6rem',
        color: '#E0803D',
        padding: '14px 0px 0px 54px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheaderf: {
        fontSize: '6rem',
        color: '#CA313C',
        padding: '14px 0px 0px 58px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernr: {
        fontSize: '2.2rem',
        color: 'black',
        padding: '28px 0px 0px 32px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    Gradeheadernrsp: {
        fontSize: '1.5rem',
        color: 'black',
        padding: '45px 0px 0px 20px',
        position: 'absolute',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    gradenr: {
        color: '#51555A',
        fontSize: '1.5rem',
    },
    Graycircle: {
        position: 'absolute',
        color: '#D5DBDB',
        marginbottom: '1.25vw',
        marginleft: '-6.25vh',
    },
    frmsze: {
        height: '68%',
        width: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '15.5rem',
    },
    frmszeaa: {
        width: '100%',
        height: '100%',
        border: '0',
        //  minHeight: '47vh',
    },
    frmszenr: {
        height: '66%',
        width: '100%',
        margin: 'auto',
        border: '0',
        minHeight: '14rem',
    },
    frmsz: {
        width: '100%',
        height: '100%',
        border: '0',
    },

    blueHeader: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        textAlign: 'center',
        fontSize: '2rem',
        fontWeight: 'bold',
        padding: '1px 1px 10px 1px',
    },

    grayHeader: {
        backgroundColor: '#012169',
        color: 'white',
        textAlign: 'left',
        fontSize: '1.6rem',
        fontWeight: 'bold',
        padding: '1px 1px 7px 1px',
    },

    frmszeb: {
        height: '100%',
        width: '100%',
        margin: 'auto',
        border: '0',
    },
}));

export default ({ school, paired, onNav }) => {
    utils.setHomeTitle(!!school && school.name + ' | Closing the Gaps');

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const myClasses = useStyles();

    let locserver = 'rptsvr1.tea.texas.gov';
    let locserver2 = 'rptsvr1.tea.texas.gov';
    let locserver3 = 'rptsvr1.tea.texas.gov';
    if (window.location.href.includes('dev.txschools')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('local')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('txsdev')) {
        locserver = 'tea4svtxsdev.tea.state.tx.us';
        locserver2 = 'tea4svtxsdev.tea.state.tx.us';
        locserver3 = 'tea4svsasdev.tea.state.tx.us';
    }
    if (window.location.href.includes('sastst')) {
        locserver = 'tea4svsastst.tea.state.tx.us';
        locserver2 = 'tea4svsastst.tea.state.tx.us';
        locserver3 = 'tea4svsastst.tea.state.tx.us';
    }

    const handleClicka = () => {
        window.open(
            'https://' +
                locserver3 +
                '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' +
                [window.ccyy] +
                '&lev=C&id=' +
                school.id +
                '&prgopt=reports/acct/domain3.sas'
        );
    };

    let vaserver = 'https://viyadev.tea.state.tx.us';
    if (window.location.href.includes('rptsvr1')) {
        vaserver = 'https://viyaprod.tea.state.tx.us';
    }
    if (window.location.href.includes('txschools')) {
        vaserver = 'https://viya.txschools.gov';
    }

    let lang = 'en';
    let lngtype = 'Gradeheadernr';
    if (window.location.href.includes('lng=es')) {
        lang = 'es';
        lngtype = 'Gradeheadernrsp';
    }
    // dynamic modal
    const [dynMod, setDynMod] = useState([]);
    useEffect(() => {
        fetch('../data/modal_dynamic.json')
            .then((resp) => resp.json())
            .then((json) => {
                setDynMod(json);
            });
    }, []);
    // use school id to filter out data.
    const dynModMatchedList = dynMod.filter((item) => {
        return item.id === school.id;
    });
    const getContentId = (_dynModMatchedList, modal_id) => {
        const _dynModMatchedList2 = _dynModMatchedList.filter((item) => {
            return item.modal_id === modal_id;
        });
        const _getContentId = _dynModMatchedList2.length === 0 ? null : _dynModMatchedList2[0].content_id.toString();
        return _getContentId;
    };
    const mIdclosing_the_gaps_rating = getContentId(dynModMatchedList, 'closing_the_gaps_rating');

    const [showcgr, setShowcgr] = useState(false);
    const handleClosecgr = () => {
        setShowcgr(!showcgr);
    };
    // const handleShowcgr = () => setShowcgr(true);

    const [showcgw, setShowcgw] = useState(false);
    const handleClosecgw = () => {
        setShowcgw(!showcgw);
    };
    // const handleShowcgw = () => setShowcgw(true);

    const [showcga, setShowcga] = useState(false);
    const handleClosecga = () => {
        setShowcga(!showcga);
    };
    // const handleShowcga = () => setShowcga(true);

    const [showcgg, setShowcgg] = useState(false);
    const handleClosecgg = () => {
        setShowcgg(!showcgg);
    };
    // const handleShowcgg = () => setShowcgg(true);

    const [showcge, setShowcge] = useState(false);
    const handleClosecge = () => {
        setShowcge(!showcge);
    };
    // const handleShowcge = () => setShowcge(true);

    const [showcgq, setShowcgq] = useState(false);
    const handleClosecgq = () => {
        setShowcgq(!showcgq);
    };
    // const handleShowcgq = () => setShowcgq(true);

    const [showcgs, setShowcgs] = useState(false);
    const handleClosecgs = () => {
        setShowcgs(!showcgs);
    };
    // const handleShowcgs = () => setShowcgs(true);

    const [showgrw, setShowgrw] = useState(false);
    const handleClosegrw = () => {
        setShowgrw(!showgrw);
    };
    // const handleShowgrw = () => setShowgrw(true);

    const perfBox1 = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' alt='closing gaps icon' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>{lang === 'en' ? 'Academic Achievement' : 'Logro Académico'}</p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_academic === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_academic}
                        </b>
                    ) : school.d3_academic >= 0 && school.d3_academic <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_academic}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_academic}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_academic}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}>{lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '127' ctg_achievement modal*/}
                    <ModalEntity modalId={'127'} lang={lang} />
                </div>
            </div>
        </Box>
    );
    const perfBox2 = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>{lang === 'en' ? 'Graduation Rate' : 'Porcentaje de Graduados'}</p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_grad === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_grad}
                        </b>
                    ) : school.d3_grad >= 0 && school.d3_grad <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_grad}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_grad}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_grad}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}> {lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '151' ctg_graduation modal*/}
                    <ModalEntity modalId={'151'} lang={lang} />
                </div>
            </div>
        </Box>
    );
    const perfBox3 = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>
                        {lang === 'en' ? 'English Language Proficiency' : 'Dominio del Idioma Inglés'}
                    </p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_elp === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_elp}
                        </b>
                    ) : school.d3_elp >= 0 && school.d3_elp <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_elp}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_elp}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_elp}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}> {lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '129' ctg_elp modal*/}
                    <ModalEntity modalId={'129'} lang={lang} />
                </div>
            </div>
        </Box>
    );

    const perfBox4 = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>{lang === 'en' ? 'Student Success' : 'Éxito Estudiantil'}</p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_student === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_student}
                        </b>
                    ) : school.d3_student >= 0 && school.d3_student <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_student}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_student}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_student}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}> {lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '130' ctg_sqss modal*/}
                    <ModalEntity modalId={'130'} lang={lang} />
                </div>
            </div>
        </Box>
    );

    const perfBox4a = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>{lang === 'en' ? 'Growth Status' : 'Estado de Crecimiento'}</p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_growth === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_growth}
                        </b>
                    ) : school.d3_growth >= 0 && school.d3_growth <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_growth}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_growth}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_growth}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}> {lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '128' ctg_growth modal*/}
                    <ModalEntity modalId={'128'} lang={lang} />
                </div>
            </div>
        </Box>
    );

    const perfBox5 = (title, text, navPage) => (
        <Box className={myClasses.perfBox}>
            <div className={myClasses.perfWrapper}>
                <div className={myClasses.perfHeader}>
                    {/* <img src='./images/target_white.png' className={myClasses.perfIcon} /> */}
                    <p style={{ padding: '.5rem' }}>{lang === 'en' ? 'School Quality' : 'Calidad de la Escuela'}</p>
                </div>
                <p className={myClasses.perfSubHeader}>{title}</p>
                <div style={{ position: 'relative', backgroundColor: '#FFFFFF' }}>
                    {school.d3_quality === 100 ? (
                        <b id='grade' className={myClasses.Gradeheader1}>
                            {school.d3_quality}
                        </b>
                    ) : school.d3_quality >= 0 && school.d3_quality <= 9 ? (
                        <b id='grade' className={myClasses.Gradeheader0}>
                            {school.d3_quality}
                        </b>
                    ) : (
                        <b id='grade' className={myClasses.Gradeheader}>
                            {school.d3_quality}
                        </b>
                    )}
                    <CircularProgress className={myClasses.Graycircle} variant='determinate' value={100} size={145} />
                    <CircularProgress
                        className={myClasses.grade}
                        variant='determinate'
                        value={school.d3_quality}
                        size={145}
                    />
                </div>
                <span className={myClasses.subHeadera}>
                    <span className={myClasses.grade}> {lang === 'en' ? 'out of' : 'de'} 100</span>
                </span>

                <div className={myClasses.perfText}>{text}</div>
                <div className={myClasses.perfTell}>
                    {/* '131' ctg_sqss modal*/}
                    <ModalEntity modalId={'131'} lang={lang} />
                </div>
            </div>
        </Box>
    );

    const section = (title) => (
        <Box className={myClasses.section}>
            <p className={myClasses.grayHeader}>
                <img
                    src='./images/target_white.png'
                    alt='closing the gaps icon'
                    style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem', marginRight: '.7rem' }}
                />
                {title}
            </p>
            <div className={myClasses.sectionContentb}>
                <iframe
                    className={myClasses.frmszeb}
                    src={
                        'https://' +
                        locserver3 +
                        '/cgi/sas/broker?_service=marykay&_program=perfrept.perfmast.sas&_debug=0&ccyy=' +
                        [window.ccyy] +
                        '&id=' +
                        school.id +
                        '&lng=' +
                        lang +
                        '&prgopt=txs/ctg_summary.sas'
                    }
                />
                {/*  <iframe
                    className={myClasses.frmszeaa}
                    src={'https://' + locserver2 + '/perfreport/txschools/ctg/' + school.id + '-ctg.html'}
                /> */}
            </div>

            <Button variant='contained' color='primary' className={myClasses.sectionButton} onClick={handleClicka}>
                {lang === 'en' ? 'Dig Into The Data' : 'Profundice en los Datos'}
            </Button>
        </Box>
    );

    return (
        <>
            {!!paired ? (
                <PairedSchool />
            ) : (
                <div>
                    <div className={myClasses.blueHeader}>
                        <img
                            src='./images/icon-closing-gap-white.png'
                            alt='closing the gaps icon'
                            style={{
                                height: '2.5rem',
                                marginRight: '.5rem',
                                marginTop: '.7rem',
                                marginBottom: '-.3rem',
                            }}
                        />{' '}
                        {lang === 'en' ? 'CLOSING THE GAPS' : 'CIERRE DE DIFERENCIAS'}{' '}
                    </div>

                    {school.d3g !== 'Not Rated' ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses['Gradeheader' + school.d3g.toLowerCase()]}>
                                        {school.d3g}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses['grade' + school.d3g.toLowerCase()]}
                                        variant='determinate'
                                        value={school.d3s}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses.subHeader}>
                                    <span className={myClasses['grade' + school.d3g.toLowerCase()]}>
                                        {' '}
                                        {school.d3s} {lang === 'en' ? 'out of' : 'de'} 100
                                    </span>
                                </span>

                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Closing the Gaps tells us how well a school is ensuring that all student groups are successful.'
                                        : 'Cierre de Diferencias nos dice qué tan bien una escuela se está asegurando de que todos los grupos de estudiantes tengan éxito.'}
                                </p>
                                <div className={myClasses.perfTell}>
                                    <ModalEntity modalId={mIdclosing_the_gaps_rating} lang={lang} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'Closing the Gaps Calculation' : 'Cálculo de Cierre de Brechas'}
                                </b>
                                <br />
                                <br />
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <div>
                                    {' '}
                                    <DtCTG entity={school} />{' '}
                                </div>
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <br />
                                <div className={myClasses.perfTell}>
                                    {/* '126' closing_the_gaps_weighting modal*/}
                                    <ModalEntity modalId={'126'} lang={lang} />
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.d3g === 'Not Rated' && school.d3s !== null ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b id='gradea' className={myClasses[lngtype]}>
                                        {lang === 'en' ? 'Not' : 'No'}
                                        <br />
                                        {lang === 'en' ? 'Rated' : 'Clasificado'}
                                    </b>
                                    <CircularProgress
                                        className={myClasses.Graycircle}
                                        variant='determinate'
                                        value={100}
                                        size={165}
                                    />
                                    <CircularProgress
                                        className={myClasses.gradenr}
                                        variant='determinate'
                                        value={school.d3s}
                                        size={165}
                                    />
                                </div>
                                <span className={myClasses.subHeader}>
                                    <span className={myClasses.gradenr}>
                                        {' '}
                                        {school.d3s} {lang === 'en' ? 'out of' : 'de'} 100
                                    </span>
                                </span>
                                <p style={{ fontSize: '1.2rem' }}>
                                    <img
                                        src='./images/info_icon_navy.png'
                                        alt='not rated school icon'
                                        style={{ height: '1.5rem', marginTop: '-2rem', marginBottom: '-.3rem' }}
                                    />
                                    &nbsp;{' '}
                                    {lang === 'en'
                                        ? 'To align with Senate Bill 1365, a Not Rated label is used when the domain or overall scaled score is less than 70.'
                                        : 'Para alinearse con el Proyecto de Ley del Senado 1365, se usa una etiqueta No clasificada cuando el dominio o la puntuación general escalada es inferior a 70.'}
                                    <br />
                                </p>

                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Closing the Gaps tells us how well a school is ensuring that all student groups are successful.'
                                        : 'Cierre de Diferencias nos dice qué tan bien una escuela se está asegurando de que todos los grupos de estudiantes tengan éxito.'}
                                </p>
                                <div className={myClasses.perfTell}>
                                    <ModalEntity modalId={mIdclosing_the_gaps_rating} lang={lang} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'Closing the Gaps Calculation' : 'Cálculo de Cierre de Brechas'}
                                </b>
                                <br />
                                <br />
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <div>
                                    {' '}
                                    <DtCTG entity={school} />{' '}
                                </div>
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <br />
                                <div className={myClasses.perfTell}>
                                    {/* '126' student_achievement_weighting modal*/}
                                    <ModalEntity modalId={'126'} lang={lang} />
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    {school.d3g === 'Not Rated' && school.d3s === null ? (
                        <Grid container>
                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>{lang === 'en' ? 'Rating' : 'Clasificación'}</b>
                                <br />
                                <br />
                                <div
                                    style={{
                                        position: 'relative',
                                        backgroundColor: '#FFFFFF',
                                        backgroundposition: 'center',
                                    }}
                                >
                                    <b className={myClasses.subHeadernr}>
                                        <center>{lang === 'en' ? 'Not Rated' : 'No Clasificado'}</center>
                                    </b>
                                </div>
                                <br />
                                <br />
                                <br />
                                <br />
                                <br />
                                <p style={{ fontSize: '1rem' }}>
                                    {lang === 'en'
                                        ? 'Closing the Gaps tells us how well a school is ensuring that all student groups are successful.'
                                        : 'Cierre de Diferencias nos dice qué tan bien una escuela se está asegurando de que todos los grupos de estudiantes tengan éxito.'}
                                </p>
                                <div className={myClasses.perfTell}>
                                    <ModalEntity modalId={mIdclosing_the_gaps_rating} lang={lang} />
                                </div>
                            </Grid>

                            <Grid item xs={12} md={6} className={myClasses.gridItem}>
                                <br />
                                <b className={myClasses.subHeader}>
                                    {lang === 'en' ? 'Closing the Gaps Calculation' : 'Cálculo de Cierre de Brechas'}
                                </b>
                                <br />
                                <br />
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <div>
                                    {' '}
                                    <DtCTG entity={school} />{' '}
                                </div>
                                {/* insert of CloseTheGap table, comment out sas -----------*/}
                                <br />
                                <div className={myClasses.perfTell}>
                                    {/* '126' closing_the_gaps_weighting modal*/}
                                    <ModalEntity modalId={'126'} lang={lang} />
                                </div>
                            </Grid>
                        </Grid>
                    ) : null}

                    <br />

                    <div className={myClasses.grayHeader}>
                        <img
                            src='./images/students_profile_white.png'
                            alt='student profile icon'
                            style={{ height: '1.75rem', marginLeft: '1rem', marginTop: '.4rem', marginRight: '.7rem' }}
                        />
                        {lang === 'en' ? 'Closing the Gaps Summary' : 'Resumen de Desempeño de Cerrando las Brechas'}
                    </div>

                    <Grid container>
                        {!!school.gaps &&
                            school.gaps.map((gapKey) => {
                                switch (gapKey) {
                                    case 'aa':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox1(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? 'Academic Achievement measures STAAR performance at the Meets Grade Level or above standard in reading and math for all student groups.'
                                                        : 'El logro académico mide el desempeño de STAAR en el nivel de grado de Cumplimiento o por encima del estándar en lectura y matemáticas para todos los grupos de estudiantes.'
                                                )}
                                            </Grid>
                                        );
                                    case 'gr':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox2(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? 'Graduation Rate measures the percentage of students who graduate in 4 years for all student groups.'
                                                        : 'El Porcentaje de Graduados mide el porcentaje de estudiantes que se gradúan en 4 años para todos los grupos de estudiantes.'
                                                )}
                                            </Grid>
                                        );
                                    case 'el':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox3(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? "English Language Proficiency measures Emergent Bilingual (EB)/English learners' (ELs) progress towards achieving English language proficiency."
                                                        : 'El dominio del idioma inglés mide el progreso de los estudiantes bilingües emergentes (EB) / estudiantes de inglés (EL) hacia el logro del dominio del idioma inglés.'
                                                )}
                                            </Grid>
                                        );
                                    case 'ss':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox4(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? 'Student Success measures how well students perform on STAAR across all student groups.'
                                                        : 'El éxito estudiantil mide qué tan bien se desempeñan los estudiantes en STAAR en todos los grupos de estudiantes.'
                                                )}
                                            </Grid>
                                        );
                                    case 'gw':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox4a(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? 'Growth Status shows the amount of growth all student groups make from year to year.'
                                                        : 'El estado de crecimiento muestra la cantidad de crecimiento que todos los grupos de estudiantes hacen de un año a otro.'
                                                )}
                                            </Grid>
                                        );
                                    case 'sq':
                                        return (
                                            <Grid
                                                item
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={school.gaps.length < 4 ? 4 : 3}
                                                className={myClasses.perfGridItem}
                                            >
                                                {perfBox5(
                                                    lang === 'en' ? '% of Targets Met' : '% de los Objetivos Cumplidos',
                                                    lang === 'en'
                                                        ? 'School Quality measures readiness for college, the workforce, or the military across all student groups.'
                                                        : 'La Calidad de la Escuela mide la preparación para la universidad, la fuerza laboral o el ejército en todos los grupos de estudiantes.'
                                                )}
                                            </Grid>
                                        );
                                }
                            })}
                    </Grid>

                    {section('Closing the Gaps Details')}
                </div>
            )}
        </>
    );
};
